import React, {useContext, useMemo, useState, useCallback} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {useTranslation} from 'react-i18next'
import pageWrapImg from '../../assets/images/market-bg.png'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import List from './List'
import {useActiveWeb3React} from 'hooks/index'
import {NavLink, useParams, useHistory} from 'react-router-dom'
import {shortenAddress} from 'utils'
import users from 'pages/Publish/users'
import {AppState} from 'state'
import {useSelector} from 'react-redux'

export enum ContentTab {
  sale, collection, auction
}

// export default function Profile ({type = 'onsale'}: ProfileProps) {
export default function Profile () {
  const {t, i18n} = useTranslation()
  const theme = useContext(ThemeContext)
  const {account} = useActiveWeb3React()
  const {user = '', type}: {user: string | undefined, type: string} = useParams()
  const history = useHistory()
  const AUCTION_AVALIABLE = useSelector((state: AppState) => state.auctions.auction_avaliable)

  const isAuth = account && users.includes(account.toLowerCase())

  let contentTab = ContentTab.sale
  switch (type) {
    case 'onslae':
      contentTab = ContentTab.sale
      break
    case 'collectible':
      contentTab = ContentTab.collection
      break
    case 'auctions':
      contentTab = ContentTab.auction
      break
  }

  return (
    <>
      <PageWrapper>
        <StyledBgBox>
          <PageWrapperImg src={pageWrapImg} alt="" />
        </StyledBgBox>
        <StyledSection style={{padding: '0'}}>
          <StyledHeader>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={8}>
                <StyledInfoBox>
                  <StyledAvatarBox>
                    <Avatar style={{width: '100%', height: '100%'}}  />
                  </StyledAvatarBox>
                  <Grid spacing={2} container justify="space-between">
                    <Grid item xs={12}>
                      <StyledAccount className="pc">{account}</StyledAccount>
                      <StyledAccount className="mobile" style={{textAlign: 'center'}}>{account && shortenAddress(account)}</StyledAccount>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledSwitchBtn onClick={() => {history.push(`/profile/onsale/${user}`)}} className={contentTab === ContentTab.sale ? 'active' : ''}>{t('Profile.onsale')}</StyledSwitchBtn>
                      <StyledSwitchBtn onClick={() => {history.push(`/profile/collectible/${user}`)}} className={contentTab === ContentTab.collection ? 'active' : ''}>{t('Profile.collectible')}</StyledSwitchBtn>
                      {
                        AUCTION_AVALIABLE &&
                        <StyledSwitchBtn onClick={() => {history.push(`/profile/auctions/${user}`)}} className={contentTab === ContentTab.auction ? 'active' : ''}>{t('Profile.auctions')}</StyledSwitchBtn>
                      }
                    </Grid>
                  </Grid>
                </StyledInfoBox>
              </Grid>
              {isAuth &&
                <StyledCreateBox item xs={12} sm={4}>
                  <StyledLink to={'/publish'} style={{width: '200px'}}>{t('Btn.create')}</StyledLink>
                </StyledCreateBox>
              }
            </Grid>
          </StyledHeader>

          <StyledSection style={{margin: '0'}}>
            <div>
              {/* 解决tab切换时候，因数据更新不及时而出现错误的问题 */}
              {contentTab === ContentTab.sale && <List type={contentTab} />}
              {contentTab === ContentTab.collection && <List type={contentTab} />}
              {contentTab === ContentTab.auction && <List type={contentTab} />}
            </div>
          </StyledSection>
        </StyledSection>
      </PageWrapper>
    </>
  )
}


const PageWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-top: 130px;
  padding-bottom: 130px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 40px;
  background-color: #212121;
  -o-object-fit: cover;
  object-fit: cover;
  @media screen and (max-width: 991px){
      margin-right: 0px;
      padding-left: 0px;
      border-radius: 0px;
  }
  @media screen and (max-width: 767px){
      overflow: hidden;
      min-height: auto;
      margin-right: 0px;
      padding-top: 150px;
      padding-right: 0px;
      padding-bottom: 150px;
  }
  @media screen and (max-width: 479px){
  overflow: hidden;
  padding-right: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -o-object-fit: cover;
  object-fit: cover;
}
`
const PageWrapperImg = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  display: block;
  max-height: 100%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -o-object-fit: cover;
  object-fit: cover;
`
const StyledBgBox = styled.div`
  position: fixed;
  inset: 0% 50px;
  z-index: 0;
  display: flex;
  overflow: hidden;
  width: auto;
  min-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 33, 33);
  object-fit: cover;
  border-radius: 40px;

  @media screen and (max-width: 991px){
    inset: 0;
    border-radius: 0;
  }
`
const StyledSection = styled.section`
  background: rgba(0,0,0,0.3);
  padding: 2rem;
  border-radius: 1.5rem;
  margin: 2rem auto;
  max-width: 1200px;
  width: 100%;
  position: relative;
`
const StyledHeader = styled.div`
  margin: 2rem;
  ${({theme}) => theme.mediaWidth.upToSmall`margin: 1rem;`};

  .pc {
    ${({theme}) => theme.mediaWidth.upToSmall`display: none;`};
  }
  .mobile {
    display: none;
    ${({theme}) => theme.mediaWidth.upToSmall`display: block;`};
  }
`

const ButtonOutline = styled.button`
  display: flex;
  margin-right: 5px;
  margin-left: 0px;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: #305cfa;
  transition: all 200ms ease;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
  border: none;
  box-shadow: none;
  cursor: pointer;
  :hover {
    transform: translate(0px, -10px);
  }
`

const StyledInfoBox = styled.div`
  display: flex;
  align-items: stretch;

  ${({theme}) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
  `};

`
const StyledAvatarBox = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 1rem;
  flex-shrink: 0;
`
const StyledAccount = styled.span`
  display: block;
  color: #fff;
  font-size: 1.25rem;
`
const StyledSwitchBtn = styled.button`
  border-radius: 100rem;
  font-size: 0.875rem;
  background: rgb(33, 33, 33);
  color: #fff;
  padding: 4px 8px;
  text-align: center;
  border: none;
  box-shadow: none;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: all 0.1s;

  &.active {
    background: #2172E5;
  }
`
const StyledLink = styled(NavLink)`
  display: flex;
  margin-right: 5px;
  margin-left: 0px;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: #305cfa;
  transition: all 200ms ease;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
  border: none;
  box-shadow: none;
  cursor: pointer;
  :hover {
    transform: translate(0px, -10px);
  }
`
const StyledCreateBox = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`
