// ONGOING 表示正在拍卖  INVALID 表示流拍
export enum OrderStatus {
  SELL = 'ListPrice',
  RESELL = 'OrderRemoved',// 这里不知道
  SELLOUT = 'OrderCompleted',
  AUCTION = 'ONGOING',
  FINISHED = 'FINISHED', // 拍卖完成
  INVALID = 'INVALID' // 流拍
}

export type NFTItemType = {
  'NFTContract': string,
  '__typename': string,
  'status'?: OrderStatus,
  'currentState': OrderStatus,
  'id': string,  // auction id
  'orderId': string,
  'price': string,
  'seller': string,
  'targetToken': string,
  'tokenId': string,
  'resource': ResourceType,
  'avatar': string,
  'nickname': string,
  expiration: string
}

export type ResourceType = {
  'name': 'Dantalian',
  'image': 'https://ipfs.fugu.finance/ipfs/QmP9ACZtRENhWawuF3ZPg8xn4iR56XCRRTi53D2Rb6nH8g',
  'animation_url': 'https://ipfsmulan.dpay.fi/ipfs/QmX4Pcqzpg7m9o66d6CrUDqcDGsJ3hxpPWpaqbHyQ6sX8v',
  'description': 'Dantalian description',
  'jaName': 'Dantalian JP',
  'zhName': 'Dantalian JP',
  'jaDescription': 'Dantalian description JP',
  'zhDescription': 'Dantalian description JP',
  'category': 'Dantalian description JP',
  'scale': '0.7062978222483814',
  'value': '0.3'
}

export type EventType = {
  title: '3 event 3',
  subtitle: '3 subtitle 3',
  image_url: string,
  link: '#'
}

export type NftmarketHistories = {
  'NFTContract': '0x8efec191975c0c6523dab4494537c6a364d249be',
  '__typename': 'NFTMarketHistory',
  'action': NftmarketHistoriesAction,
  'buyer': string,
  'orderId': '24',
  'price': '1000000000000000',
  'seller': '0xb809ca3d508994249de416d8c36b2f0482bf9cc6',
  'targetToken': '0x0000000000000000000000000000000000000000',
  'timeStamp': '1627718061',
  'tokenId': '20'
}

export enum NftmarketHistoriesAction {
  'Buy' = 'Buy',
  'Sell' = 'Sell'
}

export type bidsResponse = {
  "auctionId": number,
  "bidIndex": number,
  "bider": string,
  "targetToken": string,
  "price": string,
  "timeStamp": string // "2021-07-27T14:26:30.171Z",
  "signature": string,
  "status": "PENDING"
  "avatar": string // undefined
}

export type UserDepositsAPIType = {
  "amount": string,
  "owner": string,
  "targetToken": string
  used: string,
  usable: string
}

const UserBids = {
  "auctionId": 18,
  "bidIndex": 1,
  "bider": "0xdbed9cd7ab2e54d8f2b6024b2769f42f01bc80b3",
  "targetToken": "0x96e9DFc214160ba0c0cBF830C3c94B995Ae9c5FA",
  "price": "4000000",
  "timeStamp": "2021-08-30T08:16:34.849Z",
  "signature": "0xd4815dc7adf4ccb70efbd26d99dd485c5c0f9a7c97f8f574a91866278ca7f5671879fec0c739db1f9569f17ed15747c011861f7862ae91a3d1a97249c1e1a5651b",
  "status": "ACCEPTED",
  "auction": {
    "NFTContract": "0x3A4C11681092d5459805b8F0e28Ba14e08477558",
    "tokenId": "26"
  }
}

export type UserBidsType = typeof UserBids

export type MediumPublications = {
  id: string
  title: string
  subtitle: string
  firstPublishedAt: number
  mediumUrl: string
}
