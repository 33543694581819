import React from 'react'
import styled from 'styled-components'
import dfplogo from 'assets/gif/defi.gif'
import arrow_yellow from 'assets/images/arrow_yellow.png'
import arrow_yellow_2 from 'assets/images/arrow_yellow_2.png'

type DfplogoProps = {
  style?: Object
}

export default function Dfplogo ({style}: DfplogoProps) {
  return (
    <DownloadLink style={style} href="https://defipulse.com/" target="_blonk">
      <StyledDepLogo src={dfplogo} alt="dfplogo" />
      {/* <StyledArrow src={arrow_yellow} alt="arrow_yellow" /> */}
      {/* <StyledArrow2 src={arrow_yellow_2} alt="arrow_yellow" /> */}
    </DownloadLink>
  )
}

const DownloadLink = styled.a`
  position: relative;
  z-index: 1;
  display: inline-flex;
  width: 100px;
  height: 50px;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  @media screen and (max-width: 479px){
    height: 42px;
    margin-top: 1rem;
}
`

const StyledDepLogo = styled.img`
  height: 150%;
  /* margin-right: 10px; */
  display: block;
  position: absolute;
  right: 0;
`

const StyledArrow = styled.img`
  position: absolute;
  z-index: -1;
  top: -170%;
  left: 85%;
  height: 200%;

  @media screen and (max-width: 479px){
    display: none;
  }
`
const StyledArrow2 = styled.img`
  position: absolute;
  z-index: -1;
  top: -170%;
  right: 90%;
  height: 200%;
  display: none;
  @media screen and (max-width: 479px){
    display: block;
  }
`
