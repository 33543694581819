import React, {useContext, useMemo, useState, useEffect} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {useTranslation} from 'react-i18next'
import SectionTitle from 'components/SectionTitle'
import swapBackground from '../../assets/images/Swap-background.jpg'

export default function NftMarket () {
  const {t, i18n} = useTranslation()

  const theme = useContext(ThemeContext)

  return (
    <>
      <PageWrapper>
        <StyledBgBox>
          <PageWrapperImg src={swapBackground} alt="" />
        </StyledBgBox>
        <StyledSection>
          <StyledHeader>
            <SectionTitle title="Privacy" />
          </StyledHeader>
          <div>
            input text here
          </div>
        </StyledSection>
      </PageWrapper>
    </>
  )
}

const PageWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-top: 130px;
  padding-bottom: 130px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: #212121;
  object-fit: cover;
  @media screen and (max-width: 991px){
    margin-right: 0px;
    padding-left: 0px;
    border-radius: 0px;
  }
  @media screen and (max-width: 767px){
      overflow: hidden;
      min-height: auto;
      margin-right: 0px;
      padding-top: 150px;
      padding-right: 0px;
      padding-bottom: 150px;
  }
  @media screen and (max-width: 479px){
    overflow: hidden;
    padding-right: 0px;
    flex-direction: column;
    justify-content: flex-end;
    object-fit: cover;
  }
`
const PageWrapperImg = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  display: block;
  max-height: 100%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`
const StyledBgBox = styled.div`
  position: fixed;
  inset: 0% 50px;
  z-index: 0;
  display: flex;
  overflow: hidden;
  width: auto;
  min-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 33, 33);
  object-fit: cover;
  border-radius: 40px;

  @media screen and (max-width: 991px){
    inset: 0;
    border-radius: 0;
  }
`
const StyledSection = styled.section`
  background: rgba(0,0,0,0.3);
  padding: 2rem;
  border-radius: 1.5rem;
  margin: 2rem auto;
  max-width: 1500px;
  width: 100%;
  position: relative;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
