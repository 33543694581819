import {useCallback, useState, useEffect} from 'react'
import {useActiveWeb3React} from 'hooks/index'
import {acceptOffer721} from '../logicUtils'
import {useAuctionContract} from 'hooks/useContract'
import {TransactionResponse} from '@ethersproject/providers'
import {useIsTransactionPending} from 'state/transactions/hooks'
import {useTransactionAdder} from 'state/transactions/hooks'
import {useTranslation} from 'react-i18next'

function useAcceptAuction () {
  const {account, library} = useActiveWeb3React()
  const {t} = useTranslation()
  const auctionContract = useAuctionContract()
  const [hash, setHash] = useState<string | undefined>()
  const isPending = useIsTransactionPending(hash ?? '')
  const confirmed = Boolean(hash) && !isPending
  const addTransaction = useTransactionAdder()

  const cb = useCallback(async (auctionId: string, NFTContract: string, tokenId: string, buyer: string, targetToken: string, price: string, expiration: number, signature: string) => {
    if (account && library && auctionContract) {
      const signer = library.getSigner(account)
      const tx: TransactionResponse = await acceptOffer721(signer, auctionContract, auctionId, NFTContract, tokenId, buyer, targetToken, price, expiration, signature)
      addTransaction(tx, {
        summary: `${t('Btn.Accept')} #${tokenId}`
      })
      setHash(tx.hash)
      return tx
    } else {
      return Promise.reject(t('Missing account'))
    }
  }, [account, library, auctionContract])

  return {acceptAuction: cb, hash, isPending, confirmed}
}

export default useAcceptAuction
