import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { MenuItem, TextField, InputAdornment } from '@material-ui/core'
import { useEffect } from 'react'
import {MUSD} from '../../constants'

type TokenInputProps = {
  onChange: (value: string, tokenkey: string) => void
  placeholder?: string
  value: string
  inputTitle?: string,
  tokenkey: string
}

const tokenList = [{"key": MUSD.address, "name": "MUSD"}]

function TokenInput({
  tokenkey,
  onChange,
  value,
  inputTitle,
}: TokenInputProps) {
  const { t } = useTranslation()
  const [selectTokenName, setSelectTokenName] = useState(tokenList[0].key)

  useEffect(() => {
    // set default key
    if (tokenkey === '') {
      onChange(value, tokenList[0].key)
    } else {
      tokenList.forEach(el => {
        if (el.key === tokenkey) {
          setSelectTokenName(el.name)
        }
      })
    }
  }, [tokenkey])

  function handleSelectToken (e: any) {
    tokenList.forEach(el => {
      if (el.key === e.target.value) {
        setSelectTokenName(el.name)
      }
    })
    onChange(value, e.target.value)
  }

  function onInput (e: any) {
    console.log('=====')
    console.log(e.currentTarget.validity.valid)
    console.log(e.currentTarget.value)


    if (e.currentTarget.validity.valid) {
      const val = e.currentTarget.value.replace(/,/g, '.')
      onChange(val, tokenkey)
    }
    if (e.currentTarget.value === '') {
      onChange('', tokenkey)
    }
  }

  return (
    <StyledInputBox>
      <StyledInput>
        <TextField
          autoFocus
          required
          variant="outlined"
          margin="dense"
          label={inputTitle}
          fullWidth
          value={value}
          onChange={onInput}
          placeholder="0"
          InputProps={{
            startAdornment: <InputAdornment position="start">{selectTokenName}</InputAdornment>,
          }}
          inputProps={{
            step: "any",
            min: "0",
            inputMode: "decimal",
            pattern: "^[0-9]*[.,]?[0-9]*$"
          }}
        />
      </StyledInput>
      {/* <StyledSelect>
        <TextField
          id="standard-select-currency"
          margin="dense"
          variant="outlined"
          select
          value={tokenkey}
          onChange={handleSelectToken}
        >
          {tokenList.map((token) =>
            <MenuItem key={token.key} value={token.key}>{token.name}</MenuItem>
          )}
        </TextField>
      </StyledSelect> */}
    </StyledInputBox>
  )
}

const StyledInputBox = styled.div`
  display: flex;
  align-items: flex-end;
`
const StyledInput = styled.div`
  flex-grow: 1;
`
const StyledSelect = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 0.5rem;
`

export default TokenInput
