import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

type LoadingPage = {
  className?: string
}

export default function LoadingPage ({className}: LoadingPage) {
  return (
    <StyledContent className={className}>
      <CircularProgress />
    </StyledContent>
  )
}

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
`
