import React, {useEffect, useState} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {formatBalanceToDisplay} from 'utils/parseBignumber'
import {NFTItemType} from 'types'
import Skeleton from '@material-ui/lab/Skeleton'
import {auctionAll} from 'apis'
import {shortenAddress, mapToken, feedbackText, getLanguage} from 'utils'
import {useTranslation} from 'react-i18next'
import {NavLink, useHistory} from 'react-router-dom'

export default function Auctions () {
  const {t, i18n} = useTranslation()
  const [language, setLanguage] = useState(getLanguage((i18n as any).language))
  const [data, setData] = useState<NFTItemType[]>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    auctionAll().then((res: NFTItemType[]) => {
      // XXX 暂时显示前20个
      setData(res.slice(0, 20))
      setLoading(false)
    }, err => {
      console.error(err)
      setLoading(false)
    })
  }, [])

  return (
    <StyledScroll>
      <StyledBox>
        {loading && (<SkeletonContent />)}
        {data.map((el: any) => {
          const token = mapToken(el.targetToken)
          return (
            <StyledItem key={el.tokenId}>
              <StyledImgBox>
                <NavLink to={`/auction/${el.id}`}>
                  <StyledImg src={el.resource.image} alt={el.tokenId} />
                </NavLink>
              </StyledImgBox>
              <StyledName to={`/auction/${el.id}`}>
                {feedbackText(language, {ja: el.resource.jaName, zh: el.resource.zhName}, el.resource.name)}
              </StyledName>
              <NavLink to={`/profile/onsale/${el.seller}`} style={{textDecoration: 'none'}}>
                <StyledOwnerBox>
                  <StyledAvatar src={el.avatar} alt="avatar" />
                  <StyledOwner>{el.nickname ? el.nickname : shortenAddress(el.seller)}</StyledOwner>
                </StyledOwnerBox>
              </NavLink>
              <StyledOwnerBox>
                <StyledPriceTag>{t("Publish.Price")}</StyledPriceTag>
                <span>{formatBalanceToDisplay(el.price, token.decimals)} {token.symbol}</span>
              </StyledOwnerBox>
            </StyledItem>
          )
        })}
      </StyledBox>
    </StyledScroll>
  )
}

function SkeletonContent () {
  return (
    <>
      {[1,2,3,4].map(el => {
        return (
          <StyledItem key={el}>
            <StyledImgBox>
              <Skeleton variant="rect" width="100%" height="100%" />
            </StyledImgBox>
          </StyledItem>
        )
      })}
    </>
  )
}

const StyledScroll = styled.div`
  width: 100%;
  overflow-x: auto;
`

const StyledBox = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem 0;
  list-style: none;
`
const StyledItem = styled.li`
  margin-right: 1rem;
  position: relative;
  flex-shrink: 0;
`
const StyledImgBox = styled.div`
  width: 240px;
  height: 240px;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.2s;

  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`
const StyledImg = styled.img`
  display: inline-block;
  object-fit: contain;
  width: 100%;
  height: 100%;
`
const StyledName = styled(NavLink)`
  display: block;
  color: #fff;
  font-size: 20px;
  margin-top: 0.5rem;
  text-decoration: none;
`
const StyledOwnerBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
`
const StyledAvatar = styled.img`
  display: block;
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 50%;
`
const StyledOwner = styled.span`
  display: block;
  color: #eee;
  text-decoration: none;
`
const StyledPriceTag = styled.span`
  display: block;
  border-radius: 4px;
  color: #fff;
  background: rgb(113, 213, 117);
  padding: 1px 4px;
  font-weight: bold;
  font-size: 14px;
  margin-right: 0.5rem;
`
