import React from 'react'
import styled from 'styled-components'
import {useTranslation, Trans, Translation} from 'react-i18next'

interface CategoryTagProps {
  name: string
  className?: string
}
const CategoryTag = ({
  name,
  className = ''
}: CategoryTagProps) => {
  let {t, i18n} = useTranslation()
  return (
    <StyledContainer className={`${className}`}>
      <StyledContext>
        {name}
      </StyledContext>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
    margin-right: 7px;
    border-radius: 4px;
    color: white;
    height: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    width: fit-content;
    justify-content: center;
    font-weight: bold;
    @media only screen and (max-width: 1440px) {
        padding: 0px 8px;
    }
    background-color: rgb(126, 198, 10) !important;
    padding: 0 4px;
`

const StyledContext = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
`

export default CategoryTag
