import React, {useState, useEffect} from 'react'
import {NftmarketHistories, NftmarketHistoriesAction} from 'types'
import {mapToken, shortenAddress} from 'utils'
import Avatar from '@material-ui/core/Avatar'
import {NavLink} from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import {formatBalanceToDisplay} from 'utils/parseBignumber'

type HistoryProps = {
  fetchData: () => Promise<any>
}

export default function History ({fetchData}: HistoryProps) {

  const [data, setData] = useState<NftmarketHistories[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchData().then((res: NftmarketHistories[]) => {

      res.sort((a: NftmarketHistories, b: NftmarketHistories) => {
        return Number(b.timeStamp) - Number(a.timeStamp)
      })
      setData(res)
      setLoading(false)
    }, err => {
      setLoading(false)
    })
  }, [fetchData])

  return (
    <List>
      {
        data.map((item, i) => {
          const token = mapToken(item.targetToken)
          return (
            <ListItem alignItems="flex-start" key={i}>
              <ListItemAvatar>
                <Avatar alt="" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span>{formatBalanceToDisplay(item.price, token.decimals)} {token.symbol}</span>
                }
                secondary={
                  <span>
                    <span style={{
                      color: '#999',
                      marginRight: '6px'
                    }}>{item.action == NftmarketHistoriesAction.Buy ? 'Buy by ' : 'Listed by '}</span>
                    {item.action == NftmarketHistoriesAction.Buy && <NavLink to={'/profile/onsale/' + item.buyer}>{shortenAddress(item.buyer)}</NavLink>}
                    {item.action == NftmarketHistoriesAction.Sell && <NavLink to={'/profile/onsale/' + item.seller}>{shortenAddress(item.seller)}</NavLink>}
                  </span>
                }
              />
            </ListItem>
          )
        })
      }
    </List>
  )
}
