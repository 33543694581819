import {useCallback, useState} from 'react'
import {useActiveWeb3React} from 'hooks/index'
import {ERC721_SELL_MARKET_TAXED_ADDRESS, AUCTION_DEPOSIT_ADDRESS} from '../constants'
import {approveToken, approveNFT} from '../logicUtils'
import {useNftContract, useTokenContract} from 'hooks/useContract'
import {useTransactionAdder} from 'state/transactions/hooks'
import {TransactionResponse} from '@ethersproject/providers'
import {useIsTransactionPending} from 'state/transactions/hooks'
import {useTranslation} from 'react-i18next'
import {Token} from '@uniswap/sdk'

const useApprove = (token: Token) => {
  const {t} = useTranslation()
  const {account, library} = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const isPending = useIsTransactionPending(hash ?? '')
  const confirmed = Boolean(hash) && !isPending

  const tokenContract = useTokenContract(token?.address)
  const musdApproveToMarket = useCallback(async () => {
    if (account && library && tokenContract) {
      const signer = library.getSigner()
      const response: TransactionResponse = await approveToken(tokenContract, ERC721_SELL_MARKET_TAXED_ADDRESS, "115792089237316195423570985008687907853269984665640564039457584007913129639935", signer)
      addTransaction(response, {
        summary: t('Approve Market')
      })
      setHash(response.hash)
      return response
    } else {
      return Promise.reject(t('Missing account'))
    }
  }, [account, library, tokenContract])

  // =================
  const nftContract = useNftContract()
  const nftApproveToMarket = useCallback(async () => {
    if (account && library && nftContract) {
      const signer = library.getSigner(account)
      const tx: TransactionResponse = await approveNFT(nftContract, ERC721_SELL_MARKET_TAXED_ADDRESS, signer)
      addTransaction(tx, {
        summary: t('Approve Market')
      })
      setHash(tx.hash)
      return tx
    } else {
      return Promise.reject(t('Missing account'))
    }
  }, [account, library, nftContract])

  // =============================
  const nftApproveToAuction = useCallback(async () => {
    if (account && library && nftContract) {
      const signer = library.getSigner(account)
      const tx: TransactionResponse = await approveNFT(nftContract, AUCTION_DEPOSIT_ADDRESS, signer)
      addTransaction(tx, {
        summary: t('Approve Auction')
      })
      setHash(tx.hash)
      return tx
    } else {
      return Promise.reject(t('Missing account'))
    }
  }, [account, library, nftContract])

  // =====================
  const musdApproveToAuction = useCallback(async () => {
    if (account && library && tokenContract) {
      const signer = library.getSigner(account)
      const tx: TransactionResponse = await approveToken(tokenContract, AUCTION_DEPOSIT_ADDRESS, "115792089237316195423570985008687907853269984665640564039457584007913129639935", signer)
      addTransaction(tx, {
        summary: t('Approve Auction')
      })
      setHash(tx.hash)
      return tx
    } else {
      return Promise.reject(t('Missing account'))
    }
  }, [account, library, tokenContract])

  return {musdApproveToMarket, nftApproveToMarket, nftApproveToAuction, musdApproveToAuction, hash, isPending, confirmed}
}

export default useApprove
