import { AbstractConnector } from '@web3-react/abstract-connector'
import React from 'react'
import styled from 'styled-components'
import Option from './Option'
import { SUPPORTED_WALLETS } from '../../constants'
import { injected } from '../../connectors'
import { darken } from 'polished'
import Loader from '../Loader'
import { useTranslation } from 'react-i18next'

const PendingSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  width: 100%;
  & > * {
    width: 100%;
  }
`

const StyledLoader = styled(Loader)`
  margin-right: 1rem;
`

const LoadingMessage = styled.div<{ error?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap};
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  // color: ${({ theme, error }) => (error ? theme.red1 : 'inherit')};
  // border: 1px solid ${({ theme, error }) => (error ? theme.red1 : theme.text4)};

`

const ErrorGroup = styled.div`
margin-top: 0px;
margin-bottom: 0px;
color: red;
font-size: 14px;
line-height: 14px;
font-weight: 400;
text-align: left;
display: flex;
justify-content: space-between;
width: 100%;
align-items: center;
`

const ErrorButton = styled.div`
border-radius: 6px;
font-size: 12px;
color: #FFFFFF;
background-color: rgba(0,0,0,0.5);
margin-left: 1rem;
padding: 0.7rem;
font-weight: 500;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
`

const LoadingWrapper = styled.div`
  // ${({ theme }) => theme.flexRowNoWrap};
  // align-items: center;
  // justify-content: center;
`

export default function PendingView({
  connector,
  error = false,
  setPendingError,
  tryActivation
}: {
  connector?: AbstractConnector
  error?: boolean
  setPendingError: (error: boolean) => void
  tryActivation: (connector: AbstractConnector) => void
}) {
  const isMetamask = window?.ethereum?.isMetaMask
   const { t } = useTranslation()
 
  return (
    <PendingSection id="nbko">
      <LoadingMessage error={error}>
        {/* <LoadingWrapper> */}
          {error ? (
            <ErrorGroup>
              <div>{t('Error connecting')}</div>
              <ErrorButton
                onClick={() => {
                  setPendingError(false)
                  connector && tryActivation(connector)
                }}
              >
                {t('Try Again')}
              </ErrorButton>
            </ErrorGroup>
          ) : (
            <>
              <StyledLoader />
              {t('Initializing')}
            </>
          )}
        {/* </LoadingWrapper> */}
      </LoadingMessage>
      {Object.keys(SUPPORTED_WALLETS).map(key => {
        const option = SUPPORTED_WALLETS[key]
        if (option.connector === connector) {
          if (option.connector === injected) {
            if (isMetamask && option.name !== 'MetaMask') {
              return null
            }
            if (!isMetamask && option.name === 'MetaMask') {
              return null
            }
          }
          return (
            <Option
              id={`connect-${key}`}
              key={key}
              clickable={false}
              color={option.color}
              header={option.name}
              subheader={option.description}
              icon={require('../../assets/images/' + option.iconName)}
            />
          )
        }
        return null
      })}
    </PendingSection>
  )
}
