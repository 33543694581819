import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import Banner from './Banner'
import img1 from 'assets/images/about-part-1.jpg'
import img2 from 'assets/images/about-part-2.jpg'
import img3 from 'assets/images/about-part-3.jpg'
import img4 from 'assets/images/about-part-4.jpg'
import img5 from 'assets/images/about-part-5.jpg'
import img6 from 'assets/images/about-part-6.jpg'
import img7 from 'assets/images/about-part-7.jpg'
import eg1 from 'assets/images/about-eg-1.jpg'
import eg2 from 'assets/images/about-eg-2.jpg'


const Home = () => {
    const {t} = useTranslation()
    return (
        <>
            <Banner />
        </>
    )
}

export default Home
