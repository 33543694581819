import React, { useContext, useState, useCallback } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Card, { LightCard, PinkCard } from 'components/Card'
import { Field } from '../../state/swap/actions'
import CurrencyInputPanel from '../../components/CurrencyInputPanel/index2'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Hidden from '@material-ui/core/Hidden'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Container from '@material-ui/core/Container'
import { useMasterChefContract, useContract } from '../../hooks/useContract'
import { Contract } from 'ethers'
import { useActiveWeb3React } from '../../hooks'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import { JsonRpcSigner } from '@ethersproject/providers/lib/json-rpc-provider'
import { ethers } from 'ethers'
import { MULAN_MASTERCHEF, MULANLP, MULANV2, MULAN, USDT} from '../../constants/index'
import mulanImg from '../../assets/images/product.png'
import usdtImg from '../../assets/images/usdt.png'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useTotalSupply } from '../../data/TotalSupply'
import { useDerivedStakeInfo } from '../../state/stake/hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { ButtonConfirmed, ButtonError } from 'components/Button'
import { TransactionResponse } from '@ethersproject/providers'
import { useCountUp } from 'react-countup';
import { NavLink } from 'react-router-dom'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { TokenAmount, JSBI, Token } from '@uniswap/sdk'
import { useMuLanPrice } from '../../state/mulanstake/hooks'
import { MULANV2_ADDRESS } from 'constants/index'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import Dfplogo from 'components/Dfplogo'

import pageWrapImg from '../../assets/img/farmBG.jpg'
import Pro_img from '../../assets/img/shell.png'
import circle1 from "../../assets/img/circle-green.svg"
import downloadImg from "../../assets/img/audit-by-certik.svg"

const masterAddress = MULAN_MASTERCHEF

const Accordion = withStyles({
  root: {
    // border: '3px solid rgba(0, 0, 0, .125)',
    // backgroundColor: '#1d1f2c',
     backgroundColor: 'transparent',
     margin: '0px !important',
    // borderRadius: '10px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    minHeight: 'unset',
    margin: '0px',
    display: 'unset',
    padding: '0px',
    '&$expanded': {
      minHeight: 'unset',
      margin: '0px',
    display: 'unset'
    }
  },
  content: {
    '&$expanded': {
      minHeight: 'unset',
    margin: '0px',
    display: 'unset'
    }
  },
  expanded: {
    minHeight: 'unset',
    margin: '0px',
    display: 'unset'
  }
})(MuiAccordionSummary)



const AccordionDetails = withStyles({
  root: {
    padding: '0px',
    width:'100%',
  }
})(MuiAccordionDetails)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  column: {
    flexBasis: '20%'
  },
  tools: {
    size: '14px',
    color: '#ffffff'
  },
  margin: {
    margin: '1px'
  },
  withoutLabel: {
    marginTop: '3px'
  },
  textField: {
    width: '92%',
    fontSize: '24px',
    backgroundColor: '#2f3346',
    borderRadius: '10px',
    marginLeft: '4%'
  }
}))

// eslint-disable-next-line @typescript-eslint/class-name-casing
interface messageObj {
  text: string
  type: string
}

export default function Farm() {
  const { t } = useTranslation()

  const { library, account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, MULANLP)

  const userMulanV2Balance = useTokenBalance(account ?? undefined, MULANV2)

  const mulanLpUSDTBalance = useTokenBalance(MULANLP.address, USDT)

  //TODO fix stake mulan lp
  const mulanPrice = useMuLanPrice()
  console.log('mulanPricexxxxxx', mulanPrice)
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, MULANLP, userLiquidityUnstaked)
  const [approval, approveCallback] = useApproveCallback(parsedAmount, masterAddress)
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()


  const classes = useStyles()
  const toggleWalletModal = useWalletModalToggle()
  // 消息条
  const [open, setOpen] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState<messageObj>({ text: '', type: 'error' })
  const handleClick = (messageObj: messageObj) => {
    setAlertMessage(messageObj)
    setOpen(true)
  }
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false)
  }

  const [values, setValues] = React.useState({
    depositeAmount: '',
    withdrawAmount: ''
  })

  // 追加流动性的跳转地址
  const [addLiquidityUrl, setAddLiquidityUrl] = React.useState('/add/ETH')
  const [removeLiquidityUrl, setRemoveLiquidityUrl] = React.useState('/pool')

  const chef = useMasterChefContract(masterAddress)

  // 质押最大数量
  const [stakeNumber, setStakeNumber] = React.useState(0)
  // 解质押最大数量
  const [withDrawNumber, setWithDrawNumber] = React.useState(0)

  const [isLoadSelectedCurrencyBalance2End, setIsLoadSelectedCurrencyBalance2End] = React.useState(false)
  function loadCheckDeposit(){
    if( chef && account ){
        console.log('loadCheckDeposit')
        // TODO 质押的pool id暂时固定0，因为我也不知道怎么动态获取!!!
        const promise = checkDeposit(chef, 0, account);
        promise.then((data)=>{
            console.log('loadCheckDeposit end', data)
            setSelectedCurrencyBalance2(Number(data))
            setIsLoadSelectedCurrencyBalance2End(true)
        }).catch((data)=>{
            console.error(data)
        })
    }
  }

  // 质押
  async function onStake() {
      if(!account) {
        toggleWalletModal()
        return
      }
      if(chef && parsedAmount) {
        let pool_number = 0 // TODO 质押的pool id暂时固定0，因为我也不知道怎么动态获取!!!
        let amount = String(stakeNumber)

        if (!(chef && account && library)) {
            // handleClick({ text: 'Missing chef or account or library', type: 'error' })
            return
        }
        const signer = library.getSigner(account)

        const result = await deposit(chef, pool_number, `0x${parsedAmount.raw.toString(16)}`, signer)
        .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `Add LP Token `
            })
            setHash(response.hash)
            // reset value
            setTypedValue('')
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
    }
  }
  // 解质押
  async function withDraw() {
    // typedValue2就是用户的输入
    // console.log('withDrawxxxxxxx', parsedAmount2)
    if(!account) {
      toggleWalletModal()
      return
    }
    if(chef && parsedAmount2) {
      // console.log('withDraw')
      let pool_number = 0 // TODO 解质押的pool id暂时固定0，因为我也不知道怎么动态获取!!!
      // let amount = String(withDrawNumber) // 10^18
      // console.log(chef, pool_number, amount, account)

      if (!(chef && account && library)) {
        // handleClick({ text: 'Missing chef or account or library', type: 'error' })
        return
      }
      const signer = library.getSigner(account)

      const result = await withdraw(chef, pool_number, `0x${parsedAmount2.raw.toString(16)}`, signer).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Remove LP Token `
        })
        setHash(response.hash)
        // reset value
        setTypedValue2('')
      })
      .catch((error: any) => {
        setAttempting(false)
        console.log(error)
      })
    }
  }

  const decimals = 5
  // 获取奖励
  const [pending, setPending] = React.useState(0)

  const { countUp, start, pauseResume, reset, update } = useCountUp({
      end: Number(pending.toFixed(decimals)),
      decimals: decimals
    })

  async function checkPending() {
      if (!(chef && account && library)) {
        // handleClick({ text: 'Missing chef or account or library', type: 'error' })
        return
      }

      let pool_number = 0 // TODO 解质押的pool id暂时固定0，因为我也不知道怎么动态获取!!!
      let user = account
      let newPending = await pendingMulanV2(chef, pool_number, user)
      newPending = parseFloat(ethers.utils.formatUnits(newPending, 18))
      setPending(newPending)
      update(newPending.toFixed(decimals))
      setTimeout(checkPending, 4 * 1000)
  }

  React.useEffect(() => {
      checkPending()
  }, [setPending])

  // harvest
  async function harvestEvent() {
      if(!account) {
        toggleWalletModal()
        return
      }
      let pool_number = 0 // TODO 质押的pool id暂时固定0，因为我也不知道怎么动态获取!!!
      let amount = 0

      if (!(chef && account && library)) {
        return
      }
      const signer = library.getSigner(account)

      const result = await deposit(chef, pool_number, amount, signer).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Harvest `
        })
        setHash(response.hash)
      })
      .catch((error: any) => {
        setAttempting(false)
        console.log(error)
      })
  }

  // 变量
  // TVL的值
//   const [tvlValue, setTvlValue] = React.useState<string | number>(0)
//   setTvlValue(162911.610)
  // APY
  const [apyValue, setApyValue] = React.useState<string | number>(0)
  // setApyValue(15.20)
  // 流动性池奖励下方的值
  const [daysValue, setDaysValue] = React.useState<string>('0')
  // 流动性池奖励上方的值
  const [daysPrice, setDaysPrice] = React.useState<string>('0')
  // setDaysValue('48 X 10')
  // 质押上方的$mulan v2值
  const [mulanValue, setMulanValue] = React.useState<string | number>(0)
  // setMulanValue(0.0001)
  // 解质押上方的$mulan v2值
  const [mulanValue2, setMulanValue2] = React.useState<string | number>(0)
  // setMulanValue2(0.0001)

  const matches = useMediaQuery('(min-width:600px)')
  const [countUpClassName, setCountUpClassName] = React.useState('count-up')

  // 左侧的输入框的利用可能的数字
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, MULANLP ?? undefined)
  // 右侧的输入框的利用可能的数字
  const [selectedCurrencyBalance2, setSelectedCurrencyBalance2] = React.useState(0)

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  // 直接复制一份
  const [typedValue2, setTypedValue2] = useState('')
  const { parsedAmount: parsedAmount2, error: error2 } = useDerivedStakeInfo(
    typedValue2,
    MULANLP,
    new TokenAmount(MULANLP, JSBI.BigInt(ethers.utils.parseUnits(String(selectedCurrencyBalance2), 18))))

  // 复制一份
  const onUserInput2 = useCallback((typedValue: string) => {
    setTypedValue2(typedValue)
  }, [])

  // used for max input button
  // console.log('userLiquidityUnstaked', userLiquidityUnstaked)
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  // 复制一份
  const atMaxAmount2 = false // Boolean(selectedCurrencyBalance2 && parsedAmount2?.equalTo(String(selectedCurrencyBalance2)))
  const handleMax2 = useCallback(() => {
    selectedCurrencyBalance2 && onUserInput2(String(selectedCurrencyBalance2))
  }, [selectedCurrencyBalance2, onUserInput2])

  async function onAttemptToApprove() {
    if (!chef || !account || !library) throw new Error('missing dependencies')
    return approveCallback()
  }
  React.useEffect(()=>{
    // console.log('selectedCurrencyBalance2', chef, account)
    loadCheckDeposit()
  }, [chef, account])

  React.useEffect(()=>{
    if( chef && library){
        // TODO 质押的pool id暂时固定0，因为我也不知道怎么动态获取!!!
        const promise =  getMulanV2PerDayInPool(chef, 0, 1000, library)
        promise.then((data)=>{
            setDaysValue(String(data?.toFixed(2)))
            // console.log('getMulanV2PerDayInPool result', data)
        })
    }
  }, [chef, library])

  React.useEffect(()=>{
    if( chef && library && mulanPrice ){
        // TODO 质押的pool id暂时固定0，因为我也不知道怎么动态获取!!!
        const promise =  getMulanV2ValueInPool(chef, 0, 1000, library, Number(mulanPrice))
        promise.then((data)=>{
            setDaysPrice(String(data?.toFixed(2)))
            // console.log('getMulanV2PerDayInPool result', data)
        })
    }
  }, [chef, library, mulanPrice])

  const totalSupply = Number(useTotalSupply(MULANLP)?.toExact())
  const mulan_balance = useTokenBalance(MULANLP.address, MULAN)
  const mulanV2_balance = useTokenBalance(MULANLP.address, MULANV2)

  const chef_lp_balance = Number(useTokenBalance(chef ? chef.address : undefined, MULANLP)?.toExact())
  const tvl = chef_lp_balance * Number(mulanLpUSDTBalance?.toExact()) * 2 / totalSupply

  const [annualInterestRate, setAnnualInterestRate] = React.useState<string>('0')
  React.useEffect(()=>{
    if( library && MULANLP && chef && tvl && mulanV2_balance && totalSupply ){
        // TODO 质押的pool id暂时固定0，因为我也不知道怎么动态获取!!!
        const promise = getAPYPool(chef, 0, 1000, library, tvl, mulan_balance, totalSupply)
        promise.then((data)=>{
            if( data ){
                // TODO: need to update apy for real
                //setAnnualInterestRate(String((data * 100).toFixed(2)))
                setAnnualInterestRate(String((0.2501 * 100).toFixed(2)))
            }
        })
    }
    setAnnualInterestRate(String((0.2501 * 100).toFixed(2)))
  }, [library, MULANLP, chef, tvl, mulanV2_balance, totalSupply])

      // 标记是否有tx业务在pending中
      const [isTxPending, setIsTxPending] = React.useState(false)
      // 标记是否有tx业务成功了，这里需要从没有pending变成pending的同时，最新的一条为success的时候才是success
      const [isTxSuccess, setIsTxSuccess] = React.useState(false)
      // 下面的逻辑是怎么样判断最新的tx已经变为成功状态了
      // we want the latest one to come first, so return negative if a is after b
      function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
          return b.addedTime - a.addedTime
      }
      const allTransactions = useAllTransactions()
      React.useMemo(() => {
          if (account && Object.keys(allTransactions).length > 0) {
              const txs = Object.values(allTransactions)
              const sortedRecentTransactions = txs.filter(isTransactionRecent).sort(newTransactionsFirst)
              // 这个页面应该限定监听summary=ConfirmPair的tx
              const pending = sortedRecentTransactions.filter(tx => !tx.receipt && (tx.summary === `Add LP Token ` || tx.summary === `Remove LP Token `)).map(tx => tx.hash)
              const hasPendingTransactions = !!pending.length
              // 最新一条tx
              const tx = sortedRecentTransactions[0]
              const success = !(!tx?.receipt) && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')
              // 已经上次被设定为pending中而且这次执行是success的话，那就是success
              if (isTxPending && success) {
                  setIsTxSuccess(true)
              }
              setIsTxPending(hasPendingTransactions)
          }
      }, [allTransactions, account])
      // 监听tx是否操作成功了
      React.useEffect(() => {
          if (isTxSuccess) {
            // 状态清空还原
            setIsTxPending(false)
            setIsTxSuccess(false)

            loadCheckDeposit()
          }
      }, [isTxSuccess])

  let lightCard = (
    <MainContainer id="hello">
        <BoxContainer >
            {/* <LightCard padding="1rem" style={{backgroundColor: 'rgba(33, 36, 41, 0.35)'}}> */}


                    <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                        className={StyledContainer}>
                      <FarmHeader>
                        <FarmRowHead>
                            <FarmRowCol>
                                <FarmH1Text>{t('Top Pairs')}</FarmH1Text>
                            </FarmRowCol>
                            <Hidden xsDown>
                                <FarmRowCol><FarmH1Text>{t('v2balance')}</FarmH1Text></FarmRowCol>
                            </Hidden>
                            <FarmRowCol><FarmH1Text>{t('Stake.reward')}</FarmH1Text></FarmRowCol>
                            {/* <Hidden xsDown>
                                <FarmRowCol>{t('Farming Reward')}</FarmRowCol>
                            </Hidden> */}
                            <Hidden xsDown>
                                <FarmRowCol><FarmH1Text>{t('Home.TVL')}</FarmH1Text></FarmRowCol>
                            </Hidden>
                            {/* 年利率title的展示要动态一下 */}
                            <Hidden xsDown>
                                <FarmRowCol><FarmH1Text>{t('Stake.APY')}</FarmH1Text></FarmRowCol>
                            </Hidden>
                            <Hidden smUp>

                            {/* <FarmRowCol>{t('Home.TVL')}</FarmRowCol> */}
                            <FarmRowCol><FarmH1Text>{t('Home.TVL')} / {t('Stake.APY')}</FarmH1Text></FarmRowCol>

                            </Hidden>
                        </FarmRowHead>

                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">

                                <StyledSummary id="njnj">
                                    <FarmRowCol>
                                        <FarmH2Text>MULAN-USDT</FarmH2Text>
                                    </FarmRowCol>
                                    <Hidden xsDown>
                                    <FarmRowCol>
                                        <FarmH2Text>{userMulanV2Balance ? userMulanV2Balance.toSignificant(4) : '0.0'}
                                        </FarmH2Text>
                                    </FarmRowCol>
                                    </Hidden>
                                    <FarmRowCol>
                                        <FarmH2Text>{countUp}</FarmH2Text>
                                    </FarmRowCol>
                                    <Hidden xsDown>
                                    <FarmRowCol>
                                        <FarmH2Text>{' '}</FarmH2Text>
                                    </FarmRowCol>
                                    </Hidden>
                                    <Hidden xsDown>
                                    <FarmRowCol>
                                        <FarmH2Text>{' '}</FarmH2Text>
                                    </FarmRowCol>
                                    </Hidden>
                                    <Hidden smUp>
                                    <FarmRowCol>
                                        <FarmH2Text>${tvl?tvl.toFixed(2):'0.00'}</FarmH2Text>
                                    </FarmRowCol>
                                    </Hidden>
                                </StyledSummary>

                                <StyledSummary>
                                    <FarmRowCol>
                                      <ImgAcc>
                                      <MulanImg alt="MULAN" src={mulanImg} />
                                      <UsdtImg alt="USDT" src={usdtImg} />
                                      </ImgAcc>
                                    </FarmRowCol>

                                    <Hidden xsDown>
                                    <FarmRowCol>
                                        <FarmH2Text>$MULAN V2</FarmH2Text>
                                    </FarmRowCol>
                                    </Hidden>
                                    <FarmRowCol>
                                        <FarmH2Text>$MULAN V2</FarmH2Text>
                                    </FarmRowCol>
                                    <Hidden xsDown>
                                    <FarmRowCol>
                                        <FarmH2Text>${tvl?tvl.toFixed(2):'0.00'}</FarmH2Text>
                                    </FarmRowCol>
                                    </Hidden>
                                    {/* <Typography style={{ flex: 1 }}>
                                        <StyledTableFontGray style={{
                                            marginTop: '6px !important'
                                        }}>$MULAN V2</StyledTableFontGray>

                                    </Typography> */}
                                    <Hidden xsDown>
                                    <FarmRowCol>
                                        <FarmH2Text>{annualInterestRate}%</FarmH2Text>
                                    </FarmRowCol>
                                    </Hidden>
                                    <Hidden smUp>
                                    <FarmRowCol>
                                        <FarmH2Text>{annualInterestRate}%</FarmH2Text>
                                    </FarmRowCol>
                                    </Hidden>
                                </StyledSummary>

                        </AccordionSummary>

                      </FarmHeader>

              <FarmBody>
                <AccordionDetails className="accordianBody">
                  <StyledOperate id="one1">
                    <CurrencyInputPanel
                        value={typedValue}
                        onUserInput={onUserInput}
                        onMax={handleMax}
                        showMaxButton={!atMaxAmount}
                        currency={MULANLP}
                        // pair={dummyPair}
                        label={' '}
                        disableCurrencySelect={true}
                        customBalanceText={t('Stake.aviable')}
                        id="farming-liquidity-token"
                        selectedCurrencyBalance={selectedCurrencyBalance}
                        isLeft={true}/>

                        <Button_uI
                            onClick={onAttemptToApprove}
                            style={{
                            display: approval !== ApprovalState.NOT_APPROVED ? 'none' : 'block'}}>{t('Stake.approve')}</Button_uI>

                        <Button_uI
                            onClick={onStake}
                            style={{
                            display: approval !== ApprovalState.NOT_APPROVED ? 'block' : 'none'}}>{t('Adds')}</Button_uI>

                    </StyledOperate>

                    <StyledOperate id="one2">
                        <CurrencyInputPanel
                            value={typedValue2}
                            onUserInput={onUserInput2}
                            onMax={handleMax2}
                            showMaxButton={!atMaxAmount2}
                            currency={MULANLP}
                            // pair={dummyPair}
                            label={' '}
                            disableCurrencySelect={true}
                            customBalanceText={t('Stake.aviable')}
                            id="farming-liquidity-token"
                            isLeft={false}
                            selectedCurrencyBalance2={selectedCurrencyBalance2}
                            isLoadSelectedCurrencyBalance2End={isLoadSelectedCurrencyBalance2End}
                        />

                        <Button_uI
                            onClick={withDraw}
                            disabled={approval === ApprovalState.NOT_APPROVED}

                        >{t('Removes')}</Button_uI>

                    </StyledOperate>

                    </AccordionDetails>
                    </FarmBody>
                </Accordion>


                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} type={alertMessage.type}>
                        {alertMessage.text}
                    </Alert>
                </Snackbar>
            {/* </LightCard> */}
        </BoxContainer>

        <PageWrapperImg src={pageWrapImg} alt="" />
          <Certik>
            <Protection>
              <Protectionimg src={Pro_img} loading="lazy" alt="" />
              <CircleButtonInside src={circle1} loading="lazy" alt="" className="circle-button-inside"/>
              <CircleButtonInside2 src={circle1} loading="lazy" alt="" className="circle-button"/>
            </Protection>
            <Dfplogo style={{display: 'inline-flex', justifyContent: 'flex-end'}} />
            <DownloadLink style={{display: 'inline-flex', justifyContent: 'flex-end'}} href="https://www.certik.org/projects/mulanfinance" target="_blank">
              <DownloadImg src={downloadImg} loading="lazy" alt="" />
            </DownloadLink>
          </Certik>

        </MainContainer>
    )
    return lightCard
}

// new ui

const MainContainer = styled.div`
    position: relative;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    padding-top: 130px;
    padding-bottom: 130px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #212121;
    -o-object-fit: cover;
    object-fit: cover;
    @media screen and (max-width: 991px){
        margin-right: 0px;
        padding-left: 0px;
        border-radius: 0px;
    }
    @media screen and (max-width: 767px){
        overflow: hidden;
        min-height: auto;
        margin-right: 0px;
        padding-top: 150px;
        padding-right: 0px;
        padding-bottom: 150px;
    }
    @media screen and (max-width: 479px){
    overflow: hidden;
    padding-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -o-object-fit: cover;
    object-fit: cover;
}
`
const BoxContainer = styled.div`
position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 800px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    @media screen and (max-width: 991px){
    width: 95%;
}

`
const FarmHeader = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 30px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    @media screen and (max-width: 479px){
    overflow: visible;
}
`
const FarmRowHead = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.28);
    @media screen and (max-width: 479px){
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
`
const FarmRowCol = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    @media screen and (max-width: 479px){
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
    `
    const FarmH1Text = styled.h1`
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: futura-pt, sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: normal;
    @media screen and (max-width: 479px){
    -o-object-fit: fill;
    object-fit: fill;
}
    `
    const FarmH2Text = styled.h2`
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    white-space: normal;
    `

    const StyledSummary = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
`
const ImgAcc = styled.div`
display: flex;
`
const MulanImg = styled.img`
height: 30px;
    border-radius: 50%;
    width: 30px;
`
const UsdtImg = styled.img`
height: 30px;
    border-radius: 50%;
    width: 30px;
`
const FarmBody = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    @media screen and (max-width: 479px){
    padding: 25px 20px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
`
const Button_uI =styled.button`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 5px;
    margin-left: 0px;
    padding: 12px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #305cfa;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    border: 0;
    line-height: inherit;
    cursor:pointer;
    :hover {
      -webkit-transform: translate(0px, -10px);
      -ms-transform: translate(0px, -10px);
      transform: translate(0px, -10px);
  }
`

const PageWrapperImg = styled.img`
position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    display: block;
    max-height: 100%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -o-object-fit: cover;
    object-fit: cover;
`
const Certik = styled.div`
position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    text-align: right;
    @media screen and (max-width: 479px){
      right: 20px;
      bottom: 50px;
      display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`
const Protection = styled.div`
position: absolute;
    top: -20px;
    right: -10px;
    z-index: 3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 0px;
    perspective: 0px;
`
const Protectionimg = styled.img`
  width: 20px;
  -webkit-perspective: 0px;
  perspective: 0px;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);

`
const CircleButtonInside = styled.img`
  position: absolute;
  width: 120%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 100%;
}
`
const CircleButtonInside2 = styled.img`
  position: absolute;
  width: 140%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 120%;
}
`
const DownloadLink = styled.a`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 50px;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  @media screen and (max-width: 479px){
    height: 42px;
}
`
const DownloadImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
  max-width: none;
`


// ------------------------------------------------

const StyledTableTitle = styled.div`
    flex: 1;
    font-size: 14px;
    font-weight: bolder;
    @media screen and (min-width: 800px) {
        font-size: 18px
    }
`

const StyledTableFontGray = styled.div`
    font-size: 14px;
    color: white;
    margin-top: 3px;
    @media screen and (min-width: 800px) {
        font-size: 18px
    }
`

const StyledTableFontWhite = styled.div`
    font-size: 14px;
    color: white;
    margin-top: 6px;
    @media screen and (min-width: 800px) {
        font-size: 18px
    }
`

const StyledTableFontDDD = styled.div`
    color: white;
    font-size: 14px;
    flex: 1;
    @media screen and (min-width: 800px) {
        font-size: 18px
    }
`

interface AlertProps2 extends AlertProps {
    type: string
}

function Alert(props: AlertProps2) {
    if (props.type === 'success') {
        return <MuiAlert elevation={6} variant="filled" severity="success" {...props} />
    } else {
        return <MuiAlert elevation={6} variant="filled" severity="error" {...props} />
    }
}



const StyledContainer = styled.div`
    background: #504d4d;
    border-radius: 10px;
`

const StyledTitle = styled.div`
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-direction: row;
    color: '#333333';
    padding-bottom: 10px;
`

// const StyledSummaryContainer = styled.div`
//     display: flex;
//     justify-content: space-between;
//     width: calc(100%);
//     height: 100%;
//     flex-direction: column;
// `

// const StyledSummary = styled.div`
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
//     flex-direction: row;
// `
// const StyledSummaryTwo = styled.div`
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
//     flex-direction: row;
//     align-items: center;
// `

const StyledDetail = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
`

// const StyledHarvest = styled.div`
//     width: 800px
//     height: 35px
//     flex-direction: row-reverse
// `

const StyledOperateContainer = styled.div`
    // width: calc(100% - 1rem)
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
`

const StyledOperate = styled.div`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-right: 5px;
margin-left: 5px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-flex: 1;
-webkit-flex: 1;
-ms-flex: 1;
flex: 1;
@media screen and (max-width: 767px){
    margin-bottom: 30px;
}
`

const StyledOperateText = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;
    margin-bottom: 10px;
`
const StyledDepLogo = styled.img`
  height: 90%;
  margin-right: 10px;
  display: block;
`

async function pendingMulanV2(master_chef: Contract, pool_number: number, user_address: string) {
    return (await master_chef.pendingMulanV2(pool_number, user_address)).toString()
}
async function deposit(master_chef: Contract, pool_number: number, amount: string | number, signer: JsonRpcSigner) {
    return await master_chef.connect(signer).deposit(pool_number, amount)
}

async function withdraw(master_chef: Contract, pool_number: number, amount: string | number, signer: JsonRpcSigner) {
    return await master_chef.connect(signer).withdraw(pool_number, amount)
}

async function checkDeposit(master_chef: Contract, pool_number:number, user_address: string) {
    let result = await master_chef.userInfo(pool_number, user_address)
    return ethers.utils.formatUnits(result[0],18)
  }

async function checkPoolInfo(master_chef: Contract, pool_number: number) {
    let result = await master_chef.poolInfo(pool_number)
    return {
        lpToken: result[0],
        allocPoint: result[1].toString(),
        lastRewardBlock: result[2].toString(),
        accMulanV2PerShare: result[3].toString()
    }
}


async function checkPoolLength(master_chef:Contract) {
    return (await master_chef.poolLength()).toString()
}





async function getBlockTime(sampleSize: number, library: ethers.providers.Web3Provider | undefined) {
    if(library === undefined){
        return
    }
    let current = await library.getBlockNumber()
    let last = current - sampleSize
    let blockTime = ((await library.getBlock(current)).timestamp - (await library.getBlock(last)).timestamp) / sampleSize
    return blockTime
}

async function getMulanV2PerDay(sampleSize: number, master_chef:Contract, library: ethers.providers.Web3Provider | undefined) {
    let mulanV2PerBlock = await master_chef.mulanV2PerBlock()
    // console.log('getMulanV2PerDay mulanV2PerBlock', mulanV2PerBlock)
    // let multiplier = await master_chef.getMultiplier()
    let multiplier = await master_chef.BONUS_MULTIPLIER()
    // console.log('getMulanV2PerDay multiplier', multiplier)
    mulanV2PerBlock = mulanV2PerBlock * multiplier
    let blockTime = await getBlockTime(sampleSize, library)
    if (blockTime === undefined){
        return
    }
    let mulanV2PerDay = mulanV2PerBlock * (60 * 60 * 24 / blockTime)
    return mulanV2PerDay
}

async function getMulanV2PerDayInPool(master_chef:Contract, pool_number: number, sampleSize: number, library: ethers.providers.Web3Provider | undefined){
    let poolInfo = await checkPoolInfo(master_chef, pool_number)
    // console.log('getMulanV2PerDayInPool poolInfo', poolInfo)
    let mulanV2PerDay = await getMulanV2PerDay(sampleSize, master_chef, library)
    // console.log('getMulanV2PerDayInPool mulanV2PerDay', mulanV2PerDay)
    if(mulanV2PerDay === undefined){
        return
    }
    let allocPoint = poolInfo.allocPoint
    let totalAlloc = await master_chef.totalAllocPoint()
    // console.log('getMulanV2PerDayInPool totalAlloc', totalAlloc)
    let mulanV2PerDayInPool = mulanV2PerDay * allocPoint / totalAlloc
    // console.log('getMulanV2PerDayInPool mulanV2PerDayInPool', mulanV2PerDayInPool)
    return mulanV2PerDayInPool / 10**18
}

async function getMulanV2ValueInPool(master_chef:Contract, pool_number: number, sampleSize: number, library: ethers.providers.Web3Provider | undefined, mulanPrice:number){
    let mulanV2PerDayInPool = await  getMulanV2PerDayInPool(master_chef, pool_number, sampleSize, library)
    if(mulanV2PerDayInPool === undefined || mulanPrice === undefined){
        return
    }
    return mulanV2PerDayInPool * +mulanPrice
}


/**池子APY计算
 * 1. 获取系统参数 mulanV2perblock, mutiplier, 池子 allocPoint, 总的 totalAllocPoint
 * 2. 去获取blocktime, a day / blocktime * mulanV2perblock = mulanV2_generated_day
 * 3. 获取当前的池子，每年产生的mulanV2的价值（美元），value_per_year = allocPoint / totalAllocPoint * mulanV2_generated_day * mulanv2_value * 356
 * 4. apy = value_per_year / lp_TVL
 */

//  useTotalSupply
async function getMulanV2USDLPValue(mulanV2_balance: TokenAmount | undefined, totalSupply: number/** pool 0 is mulanV2*/) {
    // const mulanV2_balance = useTokenBalance(lp_address, mulanV2_address)
    // let totalSupply = Number(useTotalSupply(lp_address)?.toExact())
    return Number(mulanV2_balance?.toExact()) / totalSupply
}

async function getAPYPool(master_chef:Contract, pool_number:number, sampleSize: number, library: ethers.providers.Web3Provider | undefined, tvl: number, mulanV2_balance: TokenAmount | undefined, totalSupply: number) {
    // let tvl = Number(useTokenBalance(master_chef.address, lp_address)?.toExact())
    let mulanV2USDTLPValue = await getMulanV2USDLPValue(mulanV2_balance, totalSupply)
    let tvl_mulanV2 = tvl * mulanV2USDTLPValue * 2
    let MulanV2PerDayInPool = await getMulanV2PerDayInPool(master_chef, pool_number, sampleSize, library)
    if(MulanV2PerDayInPool === undefined){
        return
    }
    // let MulanV2PerDayInPoolInYear = MulanV2PerDayInPool *10**18 * 365
    let MulanV2PerDayInPoolInYear = MulanV2PerDayInPool * 365
    return MulanV2PerDayInPoolInYear / tvl_mulanV2
}
