import { ChainId, TokenAmount } from '@uniswap/sdk'
import React, { useState } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
// import {i18n} from 'i18next';
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances, useAggregateUniBalance } from '../../state/wallet/hooks'
import { CardNoise } from '../earn/styled'
import { CountUp } from 'use-count-up'
import { TYPE, ExternalLink } from '../../theme'
import { YellowCard } from '../Card'
import { Moon, Sun } from 'react-feather'
import Menu from '../Menu'
import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import ClaimModal from '../claim/ClaimModal'
import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { Dots } from '../swap/styleds'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import usePrevious from '../../hooks/usePrevious'
import logoCircle from "../../assets/img/circle-animation.svg"
import Logo from "../../assets/img/mulan-defi-logo-white.svg"

const HeaderFrame = styled.div`
position: fixed;
    left: 0px;
    top: 10px;
    right: 0px;
    z-index: 10;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: transparent;
    @media screen and (max-width: 991px){
      top:0px;
    }
`
const ContainerNav = styled.div`
  position: static;
  top: auto;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  -o-object-fit: fill;
  object-fit: fill;
`

const HeaderRow = styled.nav`
  position: static;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 45px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1px;
  padding-left: 1px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.44);
  box-shadow: 0 16px 36px 0 rgb(0 0 0 / 16%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  @media screen and (max-width: 991px){
    width: 100vw;
    height: auto;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.26);
}
`

const HeaderLinks = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 991px){
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100vh;
    padding-bottom: 10vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
}
`
const CircleButtonInside = styled.img`
  position: absolute;
  width: 120%;
  max-width: none;
`
const CircleButtonInside2 = styled.img`
  position: absolute;
  width: 140%;
  max-width: none;
`
const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};

  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};

`
const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const activeClassName = 'ACTIVE'

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: 70px;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledNavLinkHome = styled(NavLink)`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
width: 42px;
height: 42px !important;
margin-right: 15px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
-webkit-align-self: auto;
-ms-flex-item-align: auto;
align-self: auto;
-webkit-box-flex: 0;
-webkit-flex: 0 0 auto;
-ms-flex: 0 0 auto;
flex: 0 0 auto;
border: 0.5px solid transparent;
border-radius: 50px;
-webkit-transform: rotate(
0deg
);
-ms-transform: rotate(0deg);
transform: rotate(
0deg
);
-webkit-transition: all 200ms ease;
transition: all 200ms ease;
border-radius: 50px;
@media screen and (max-width: 991px){display: none;}
`

const StyledNavLink = styled(NavLink)`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 45px;
margin-right: 5px;
margin-left: 5px;
padding: 0px 20px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-flex-wrap: nowrap;
-ms-flex-wrap: nowrap;
flex-wrap: nowrap;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
-webkit-align-content: center;
-ms-flex-line-pack: center;
align-content: center;
border: 0.5px solid transparent;
border-radius: 50px;
-webkit-transition: all 200ms ease;
transition: all 200ms ease;
color: hsla(0, 0%, 100%, 0.6);
font-size: 13px;
line-height: 13px;
font-weight: 400;
white-space: nowrap;
text-decoration: none;
 :hover{
  border-style: solid;
  border-width: 0.5px;
  border-color: hsla(0, 0%, 100%, 0);
  background-color: #000;
  color: #fff;
 }
 @media screen and (max-width: 991px){
    z-index: 1;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
}
`

const MobileToggleMenu = styled.div`
display: none;
@media screen and (max-width: 991px){
    z-index:999999;
    display: block;
    position: fixed;
    top: 30px;
    right: 30px;
    padding: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}
@media screen and (max-width: 479px){
    top: 20px;
}
`
const ToggleBox = styled.div`
@media screen and (max-width: 991px){
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 55px;
    height: 55px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.45);
    box-shadow: 0 16px 36px 0 rgb(0 0 0 / 16%);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}
@media screen and (max-width: 479px){
    height: 56px;
    border-radius: 60px;
}
`
const MobileImg = styled.img`
display: none;
@media screen and (max-width: 991px){
    position: absolute;
    top: auto;
    z-index: 0;
    display: block;
    width: 65%;
    opacity: 0.08;
    -webkit-transform: rotate(-29deg);
    -ms-transform: rotate(-29deg);
    transform: rotate(-29deg);
}
@media screen and (max-width: 479px){
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}
`

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan'
}

export default function Header(){

  const [active, setActive] = useState(false);

  function addActiveClass(){
   // alert("hii")
      if(active == false) {
        setActive(true);
      } else {
        setActive(false)
     }
  }

  const { account, chainId } = useActiveWeb3React()
  const { t, i18n }: { t: any; i18n: any } = useTranslation()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()
  const [ darkMode, toggleDarkMode ] = useDarkModeManager()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()

  const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'

  return (
    <HeaderFrame data-collapse="medium" data-animation="default" data-duration="400" data-doc-height="1" data-no-scroll="1" role="banner" className="navbar w-nav">
      <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <ContainerNav id="new2">
      <HeaderRow className={`nav-menu ${active == true ? '': 'mobile-menu-visible'}`}>
        <HeaderLinks id="new4">
          <StyledNavLinkHome exact className="activeBG" id={`home-nav-link`} to={'/'}>
            {/* {t('home')} */}
            <CircleButtonInside src={logoCircle} loading="lazy" alt="" className="circle-button-inside" />
            <CircleButtonInside2 src={logoCircle} loading="lazy" alt="" className="circle-button" />
            <img src={Logo} loading="lazy" alt="" className="nav-link-img" />
          </StyledNavLinkHome>
          <StyledNavLink exact className="activeBG homeComponent" id={`home-nav-link`} to={'/'} onClick={() =>addActiveClass()}>
            {t('home')}
          </StyledNavLink>
          <StyledNavLink className="activeBG" id={`swap-nav-link`} to={'/swap'} onClick={() =>addActiveClass()}>
            {t('swap')}
          </StyledNavLink>
          <StyledNavLink className="activeBG" id={`liquidity-nav-link`} to={'/pool'} onClick={() =>addActiveClass()}>
            {t('liquidity')}
          </StyledNavLink>
          <StyledNavLink className="activeBG" id={`stake-nav-link`} to={'/stake'} onClick={() =>addActiveClass()}>
            {t('stake')}
          </StyledNavLink>
          <StyledNavLink className="activeBG"
            id={`farm-nav-link`}
            to={'/farm'} onClick={() =>addActiveClass()}>
            {t('farm')}
          </StyledNavLink>
          <StyledNavLink className="activeBG"
            id={`nftmarket-nav-link`}
            to={'/nftmarket'} onClick={() => addActiveClass()}>
            {t('NFTMarket')}
          </StyledNavLink>
            <StyledNavLink className="activeBG"
              id={`about-nav-link`}
              to={'/about'} onClick={() => addActiveClass()}>
              {t('about')}
            </StyledNavLink>
            <StyledNavLink className="activeBG"
              id={`news-nav-link`}
              to={'/news'} onClick={() => addActiveClass()}>
              {t('news')}
            </StyledNavLink>
            <StyledNavLink className="activeBG" id={`charts-nav-link`} to={'/charts'} onClick={() => addActiveClass()}>
            {t('charts')}
            </StyledNavLink>
          <MobileImg src={Logo} alt="" />
          <Web3Status/>

        </HeaderLinks>
        <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} ETH
              </BalanceText>
            ) : null}
          </AccountElement>
      </HeaderRow>
      <MobileToggleMenu onClick={() =>addActiveClass()}>
        <ToggleBox>
            <CircleButtonInside src={logoCircle} loading="lazy" alt="" className="circle-button-inside" />
            <CircleButtonInside2 src={logoCircle} loading="lazy" alt="" className="circle-button" />
            <img src={Logo} loading="lazy" alt="" className="nav-link-img" />
        </ToggleBox>
      </MobileToggleMenu>
      </ContainerNav>
      {/* <HeaderControls> */}
        {/* <HeaderElement> */}

          <HideSmall style={{display: 'none'}}>
            {chainId && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}> {NETWORK_LABELS[chainId]}</NetworkCard>
            )}
          </HideSmall>
          {/* {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? <Dots>Claiming UNI</Dots> : 'Claim UNI'}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )} */}


        {/* </HeaderElement> */}

      {/* </HeaderControls>  */}
    </HeaderFrame>
  )
}
