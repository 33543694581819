import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { TYPE, ExternalLink } from '../../theme'
import { useBlockNumber } from '../../state/application/hooks'
import { getEtherscanLink } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import mulanLogo from 'assets/img/mulan-defi-logo-white.svg'
import Pro_img from 'assets/img/shell.png'
import circle1 from "assets/img/circle-green.svg"
import downloadImg from "assets/img/audit-by-certik.svg"
import HeroBannerImg from 'assets/gif/banner.gif'
import Dfplogo from 'components/Dfplogo'
import { useTranslation } from 'react-i18next'

 const SectionFull = styled.div`
    position: relative;
    z-index: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    background-color: #212121;
    object-fit: cover;
    margin-bottom: 30px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    border-radius: 42px;
    @media screen and (max-width: 991px){
    border-radius: 0px;
}
    @media screen and (max-width: 479px)
    {
        min-height: 90vh;
    }
  `
  const SectionFullTextContainer = styled.div`
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    margin-top: 30vh;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    text-align: left;
    @media screen and (max-width: 991px){
    width: 65%;
}
@media screen and (max-width: 767px){
    width: 80%;
}
@media screen and (max-width: 479px){
    width: 100%;
    margin-top: 15vh;
    margin-bottom: auto;
    padding: 30px;
}
`

  const HeroH3 = styled.h3`
  font-family: futura-pt, sans-serif;
  color: #969595;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: 6px;
  text-transform: uppercase;
`
const HeroLogo = styled.img`
position: absolute;
left: 160px;
top: 58px;
width: 36px;
@media screen and (max-width: 479px){
    left: 186px;
    top: 87px;
}
`
const HeroH1 = styled.h1`
margin-top: 0px;
margin-bottom: 0px;
font-family: acumin-pro-extra-condensed, sans-serif;
color: #fff;
font-size: 70px;
line-height: 60px;
font-weight: 900;
`

const HeroH2 = styled.h2`
margin-top: 0px;
margin-bottom: 0px;
font-family: acumin-pro-extra-condensed, sans-serif;
color: #0099f9;
font-size: 70px;
line-height: 60px;
font-weight: 900;
`

const HeroH33 = styled.h3`
font-family: futura-pt, sans-serif;
color: #fff;
font-size: 14px;
line-height: 18px;
font-weight: 300;
letter-spacing: 6px;
text-transform: uppercase;
`
const Margin = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
`
const Button = styled.a`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 5px;
    margin-left: 0px;
    padding: 12px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #0099f9;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    :hover {
      -webkit-transform: translate(0px, -10px);
      -ms-transform: translate(0px, -10px);
      transform: translate(0px, -10px);
  }
    `

    const ButtonOutline = styled.a`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 5px;
    margin-left: 5px;
    padding: 12px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #fff;
    border-radius: 40px;
    background-color: transparent;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    backdrop-filter: blur(20px);
    :hover {
      -webkit-transform: translate(0px, -10px);
      -ms-transform: translate(0px, -10px);
      transform: translate(0px, -10px);
  }
    `
    const ButtonText = styled.div`
      font-size: 14px;
      line-height: 14px;
  `
  const ButtonTextWhite = styled.div`
  font-size: 14px;
  line-height: 14px;
  color:#fff;
`
const Certik = styled.div`
position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    text-align: right;
    @media screen and (max-width: 479px){
    right: 20px;
    bottom: 50px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}
`
const Protection = styled.div`
position: absolute;
    top: -20px;
    right: -10px;
    z-index: 3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 0px;
    perspective: 0px;
`
const Protectionimg = styled.img`
  width: 20px;
  -webkit-perspective: 0px;
  perspective: 0px;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);

`
const CircleButtonInside = styled.img`
  position: absolute;
  width: 120%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 100%;
}
`
const CircleButtonInside2 = styled.img`
  position: absolute;
  width: 140%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 120%;
    max-width: none;
}
`
const DownloadLink = styled.a`
  position: relative;
  z-index: 1;
  display: inline-flex;
  width: auto;
  height: 50px;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  @media screen and (max-width: 479px){
    height: 42px;
}
`
const DownloadImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
  max-width: none;
`

const PosterVideo =styled.div`
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(5px);
    filter: blur(5px);
    overflow: hidden;
    color: white;
`
const BannerGif = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
`

 export default function HeroBanner() {
    const { chainId } = useActiveWeb3React()
    const { t } = useTranslation()
    const blockNumber = useBlockNumber()

    const [isMounted, setIsMounted] = useState(true)

    useEffect(
      () => {
        const timer1 = setTimeout(() => setIsMounted(true), 1000)

        // this will clear Timeout when component unmount like in willComponentUnmount
        return () => {
          setIsMounted(false)
          clearTimeout(timer1)
        }
      },
      [blockNumber] //useEffect will run only one time
      //if you pass a value to array, like this [data] than clearTimeout will run every time this value changes (useEffect re-run)
    )

  return (
  <SectionFull>
    <SectionFullTextContainer>
      <HeroH3>Be a DeFi SAILOR</HeroH3>
      <HeroLogo src={mulanLogo} loading="lazy" alt="" />
      <HeroH1>MULAN</HeroH1>
      <HeroH2>FINANCE</HeroH2>
      <HeroH33>AND $ MULAN</HeroH33>
      <Margin>
        <Button href="/#/swap">
          <ButtonText>{t('swap')}</ButtonText>
        </Button>
        <Button href="/#/swap/0xdAC17F958D2ee523a2206206994597C13D831ec7/0x52a8FcbdCC29E924e86C562E4B36fE6f470bD516">
          <ButtonText>{t('swapv2')}</ButtonText>
        </Button>
        {/* <ButtonOutline href="#learnmore" className="button-outlines w-inline-block">
          <ButtonTextWhite className="button-text white">Learn more</ButtonTextWhite>
        </ButtonOutline> */}
      </Margin>
      </SectionFullTextContainer>
      <Certik>
        <Protection>
          <Protectionimg src={Pro_img} loading="lazy" alt="" />
          <CircleButtonInside src={circle1} loading="lazy" alt="" className="circle-button-inside"/>
          <CircleButtonInside2 src={circle1} loading="lazy" alt="" className="circle-button"/>
        </Protection>
        <Dfplogo />
        <DownloadLink href="https://www.certik.org/projects/mulanfinance" target="_blank">
          <DownloadImg src={downloadImg} loading="lazy" alt="" />
        </DownloadLink>
      </Certik>
      <PosterVideo>
          <BannerGif src={HeroBannerImg} alt=''/>
      </PosterVideo>
  </SectionFull>
  )
}

const StyledDepLogo = styled.img`
  height: 90%;
  margin-right: 10px;
  display: block;
`
