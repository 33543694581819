import { ChainId, TokenAmount } from '@uniswap/sdk'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Menu } from 'react-feather'
import {getLanguage} from 'utils'

const FooterWrap = styled.div`
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 991px){
      position: absolute;
      width: 50%;
      height: 45px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
  }
  @media screen and (max-width: 479px){
    width: 100%;
}
`
const LanguageLink = styled.a`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 2px solid transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: hsla(0, 0%, 100%, 0.5);
  text-decoration: none;
  box-sizing: content-box;
  font-size: 12px;
  line-height: 18px;
  :hover{
  border-right-color: #3f3f3f;
    color: #fff;
  }
  @media screen and (max-width: 991px){
    width: auto;
    height: 45px;
    padding: 0px 15px;
    border-top: 2px solid transparent;
    border-right-style: none;
    border-right-width: 0px;
}
`
// const LanguageLinkActive = styled.a`
// display: -webkit-box;
// display: -webkit-flex;
// display: -ms-flexbox;
// display: flex;
// width: 45px;
// padding-top: 15px;
// padding-bottom: 15px;
// -webkit-box-pack: center;
// -webkit-justify-content: center;
// -ms-flex-pack: center;
// justify-content: center;
// -webkit-box-align: center;
// -webkit-align-items: center;
// -ms-flex-align: center;
// align-items: center;
// border-right: 2px solid transparent;
// -webkit-transition: all 300ms ease;
// transition: all 300ms ease;
// color: hsla(0, 0%, 100%, 0.5);
// text-decoration: none;
// box-sizing: content-box;
// border-right: 2px solid #0099f9;
// color: #fff;
// font-size: 12px;
// line-height: 18px;
// :hover{
// border-right-color: #3f3f3f;
//   color: #fff;
// }
// @media screen and (max-width: 991px){
//     width: auto;
//     height: 45px;
//     padding: 0px 15px;
//     border-top: 2px solid #0099f9;
//     border-right-width: 0px;
// }
// `

const StyledMenuButton = styled.button`
  position: relative;
  width: 60px;
  height: 100%;
  display: block;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: #fff;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`
const Logo = styled.img`
  z-index: 1;
  @media (max-width: 768px) {
    width: 100px;
    margin-left: -20px;
    margin-top: -13px;
  }
  @media (min-width: 769px) {
     width: 200px;
  margin-left: -40px;
  margin-top: -23px;
  }
`
const LogoWrap = styled.div`
  position: fixed;
  z-index: 100;
  @media (max-width: 768px) {
    font-size: 12px;
    left: 100px;
    bottom: 0px;
  }
  @media (min-width: 769px) {
    left: 50px;
    bottom: 0px;
  }
`
const RightBar = styled.div`
position: fixed;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 45px;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 991px){
      display:none !important;
    }
`
const Credits = styled.div`
position: relative;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 45px;
    height: 100%;
    margin: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-perspective-origin: 50% 50%;
    perspective-origin: 50% 50%;
    -webkit-transform: rotate(
-90deg
);
    -ms-transform: rotate(-90deg);
    transform: rotate(
-90deg
);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    font-family: futura-pt, sans-serif;
    color: #646464;
    line-height: 14px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 5px;
`

export default function Footer() {
  const [show, setShouw] = useState(false)
  // const [language, setLanguage] = useState(['en'] as any);
  const { t, i18n }: { t: any; i18n: any } = useTranslation()
  const [language, setLanguage] = useState(getLanguage((i18n as any).language))

  const changeL=(lan: string) => {
    i18n.changeLanguage(lan)
    setShouw(false)
    switch (lan) {
      case 'zh-CN':
        setLanguage('zh')
        break
      case 'jap':
        setLanguage('ja')
        break
      default:
        setLanguage('en')
        break
    }
  }

return (
<>
<RightBar>
  <Credits>MULAN FINANCE</Credits>
</RightBar>
<FooterWrap className="language">
  {/* <LogoWrap>
    <div style={{color:'#fff'}}>Audited by</div>
    <Logo  src={require('../../assets/img/audit-by-certik.svg')}/>
  </LogoWrap>
  <StyledMenuButton onClick={() => setShouw(!show)}>
    <Menu />
  </StyledMenuButton>
  {show ? (
    <div>
      <StyledMenuButton onClick={() => changeL('en')}>EN</StyledMenuButton>
      <StyledMenuButton onClick={() => changeL('jap')}>JA</StyledMenuButton>
      <StyledMenuButton onClick={() => changeL('zh-CN')}>ZH</StyledMenuButton>
    </div>
  ) : null} */}

    <LanguageLink className={`${language == 'en' ? 'activeLanguage': ''}`} onClick={() => changeL('en')}>EN</LanguageLink>
    <LanguageLink className={`${language == 'zh' ? 'activeLanguage': ''}`} onClick={() => changeL('zh-CN')}>ZH</LanguageLink>
    <LanguageLink className={`${language == 'ja' ? 'activeLanguage': ''}`} onClick={() => changeL('jap')}>JA</LanguageLink>

</FooterWrap>
</>
)
}
