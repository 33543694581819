import {useCallback, useEffect, useState} from 'react'
import {useActiveWeb3React} from 'hooks/index'
import {ERC721_SELL_MARKET_TAXED_ADDRESS, AUCTION_DEPOSIT_ADDRESS} from '../constants'
import {allowToken, allowNFT} from '../logicUtils'
import {useNftContract, useTokenContract} from 'hooks/useContract'
import {Token} from '@uniswap/sdk'

function useAllowance (token: Token) {
  const {account, library} = useActiveWeb3React()

  const [allowance, setAllowance] = useState(false)
  const tokenContract = useTokenContract(token?.address)
  const tokenAllowanceOfMarket = useCallback(async (): Promise<boolean> => {
    if (allowance) {
      return allowance
    }
    if (account && library && tokenContract) {
      const result = await allowToken(tokenContract, ERC721_SELL_MARKET_TAXED_ADDRESS, account)

      if (Number(result) === 0) {
        setAllowance(false)
        return false
      }

      setAllowance(true)
      return true
    }
    return false
  }, [account, library, allowance, tokenContract])

  // ==================
  const [isNftAllowanceOfMarket, setIsNftAllowanceOfMarket] = useState(false)
  const nftContract = useNftContract()
  const nftAllowanceOfMarket = useCallback(async () : Promise<boolean> => {
    if (isNftAllowanceOfMarket) {
      return isNftAllowanceOfMarket
    }
    if (account && library && nftContract) {
      const result = await allowNFT(nftContract, ERC721_SELL_MARKET_TAXED_ADDRESS, account)

      if (Number(result) === 0) {
        setIsNftAllowanceOfMarket(false)
        return false
      }

      setIsNftAllowanceOfMarket(true)
      return true
    }
    return false
  }, [account, library, isNftAllowanceOfMarket, nftContract])

  // ====================
  const [isNftAllowanceOfAuction, setIsNftAllowanceOfAuction] = useState(false)

  const nftAllowanceOfAuction = useCallback(async (): Promise<boolean> => {
    if (isNftAllowanceOfAuction) {
      return isNftAllowanceOfAuction
    }
    if (account && library && nftContract) {
      const result = await allowNFT(nftContract, AUCTION_DEPOSIT_ADDRESS, account)

      if (Number(result) === 0) {
        setIsNftAllowanceOfAuction(false)
        return false
      }

      setIsNftAllowanceOfAuction(true)
      return true
    }
    return false
  }, [account, library, isNftAllowanceOfAuction, nftContract])

  // ===============
  const [isTokenAllowanceOfAuction, setIsTokenAllowanceOfAuction] = useState(false)
  const tokenAllowanceOfAuction = useCallback(async (): Promise<boolean> => {
    if (account && library && tokenContract) {
      const result = await allowToken(tokenContract, AUCTION_DEPOSIT_ADDRESS, account)

      if (Number(result) === 0) {
        setIsTokenAllowanceOfAuction(false)
        return false
      }

      setIsTokenAllowanceOfAuction(true)
      return true
    }
    return false
  }, [account, library, allowance, tokenContract])

  return {tokenAllowanceOfMarket, nftAllowanceOfMarket, nftAllowanceOfAuction, tokenAllowanceOfAuction}
}

export default useAllowance
