import {ethers, Contract} from 'ethers'
import {JsonRpcSigner} from '@ethersproject/providers'
// import {useActiveWeb3React} from 'hooks/index'
// const { library, account } = useActiveWeb3React()


export const mintThenAddSellOrder = async (nftcontract: Contract, signer: JsonRpcSigner, URI: string, targetTokenAddress: string, price: string) => {
  // if( library == undefined) {
  //   return;
  // }
  // const signer = library.getSigner(account)
  return await nftcontract.connect(signer).mintThenAddSellOrder(URI, targetTokenAddress, price)
}

export const mintToWallet = async (nftcontract: Contract, account: string, signer: JsonRpcSigner, URI: string) => {
  return await nftcontract.connect(signer).mintToWithURI(account, URI)
}

export const allowToken = async (tokenContract: Contract, market_address: string, account: string) => {
  try {
    const allow = await tokenContract.allowance(account, market_address)
    //console.log("get token allworance:", allow.toString())
    return allow.toString()
  } catch (e) {
    return '0'
  }
}

export const approveToken = async (tokenContract: Contract, market_address: string, amount: string, signer: JsonRpcSigner) => {
  console.log('==============approve')
  console.log(tokenContract, market_address, amount, signer)
  return tokenContract.connect(signer).approve(market_address, amount)
}

export const withdraw = async (marketContract: Contract, signer: JsonRpcSigner, orderId: string) => {
  return await marketContract.connect(signer).withdraw(orderId)
}

export const buyFromOrder = async (marketContract: Contract, signer: JsonRpcSigner, orderId: string) => {
  return await marketContract.connect(signer).buyFromOrder(orderId)
}

export const allowNFT = async (nftContract: Contract, market_address: string, account: string) => {
  try {
    const allow = await nftContract.isApprovedForAll(account, market_address)
    console.log("get allow for all:", allow)
    return allow
  } catch (e) {
    //console.log(e)
    return '0'
  }
}

export const approveNFT = async (nftContract: Contract, market_address: string, signer: JsonRpcSigner) => {
  return nftContract.connect(signer).setApprovalForAll(market_address, true)
}

export const addOrder = async (marketContract: Contract, ntf_address: string, tokenId: string, targetTokenAddress: string, price: string, signer: JsonRpcSigner) => {
  return await marketContract.connect(signer).addOrder(ntf_address, tokenId, targetTokenAddress, price)
}

export const auctionDeposit = async (auctionContract: Contract, targetToken: string, amount: string, signer: JsonRpcSigner) => {
  return await auctionContract.connect(signer).deposit(targetToken, amount)
}

export const withDrawMusd = async (auctionContract: Contract, targetToken: string, amount: string, signer: JsonRpcSigner) => {
  return await auctionContract.connect(signer).withdraw(targetToken, amount)
}

export const acceptOffer721 = async (signer: JsonRpcSigner, auctionContract: Contract, auctionId: string, NFTContract: string, tokenId: string, buyer: string, targetToken: string, price: string, expiration: number, signature: string) => {
  return await auctionContract.connect(signer).acceptOffer721(auctionId, NFTContract, tokenId, buyer, targetToken, price, expiration, signature)
}
