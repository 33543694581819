import React, {useState} from 'react'
import styled, {ThemeContext} from 'styled-components'
import Grid from '@material-ui/core/Grid';
import ItemCard from './ItemCard'
import {NFTItemType} from 'types'
import Button from '@material-ui/core/Button'
import {CircularProgress} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox, {CheckboxProps} from '@material-ui/core/Checkbox';
import {useTranslation} from 'react-i18next'
import SectionTitle from 'components/SectionTitle'
import {index} from 'apis'
import {useEffect} from 'react';
import Skeleton from '@material-ui/lab/Skeleton'
import searchIcon from '../../assets/images/magnifying-glass.svg'
import arrowRightIcon from '../../assets/images/arrow-right.svg'

interface ListItemProps {
  product: NFTItemType,
  width: boolean | 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto",
  smWidth: boolean | 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto",
}

let isFirstRender = true
const defaultPageParams = {
  offset: 0,
  limit: 10
}
export default function List () {
  const {t, i18n} = useTranslation()
  const [list, setList] = useState<ListItemProps[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [isNoMore, setIsNoMore] = useState(false)
  const [pageParams, setPageParams] = useState(defaultPageParams)
  // 筛选项目
  const [sortKeys, setSortKeys] = useState<{
    onsale: 1 | 0,
    star: 1 | 0,
    order: "price_asc" | "price_desc" | "new_order",
  }>({
    onsale: 0,
    star: 0,
    order: "new_order",
  })
  // 搜索关键词
  const [keyword, setKeyword] = useState('')

  // reset pageParams
  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false
    } else {
      setPageParams({...defaultPageParams})
    }
  }, [sortKeys, keyword])

  useEffect(() => {
    async function fetchData () {
      if (pageParams.offset === 0) {
        // 如果offset === 0，则视为加载初始数据
        setLoading(true)
      } else {
        // 如果offset > 0，则视为加载更多数据
        setLoadingMore(true)
      }

      const res = await index(pageParams.offset, pageParams.limit, sortKeys.onsale, sortKeys.star, sortKeys.order, keyword)
      const result = filterProducts(res.orders)

      if (pageParams.offset === 0) {
        // 如果offset === 0，则视为加载初始数据
        // 直接覆盖
        setList(result)
        setLoading(false)
      } else {
        // 如果offset > 0，则视为加载更多数据
        // 追加
        setList(oldList => oldList.concat(result))
        setLoadingMore(false)
      }
      if (result.length === 0) {
        setIsNoMore(true)
      } else {
        setIsNoMore(false)
      }
    }
    fetchData()
  }, [pageParams])

  // 加载更多数据事件
  function loadingMoreEvent () {
    const offset = pageParams.offset + pageParams.limit
    setPageParams({
      offset, limit: pageParams.limit
    })
  }

  function onsaleChange (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      setSortKeys(oldVal => ({...oldVal, onsale: 1}))
    } else {
      setSortKeys(oldVal => ({...oldVal, onsale: 0}))
    }
  }

  function starChange (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      setSortKeys(oldVal => ({...oldVal, star: 1}))
    } else {
      setSortKeys(oldVal => ({...oldVal, star: 0}))
    }
  }

  function handleClick (event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  const orderByList = [t("Home.Sort.Newest"), t("Home.Sort.htl"), t("Home.Sort.lth")]
  const [orderByIndex, setOrderByIndex] = useState(-1)

  useEffect(() => {
    switch (orderByIndex) {
      case 0:
        setSortKeys(oldVal => ({...oldVal, order: 'new_order'}))
        break
      case 1:
        setSortKeys(oldVal => ({...oldVal, order: 'price_desc'}))
        break
      case 2:
        setSortKeys(oldVal => ({...oldVal, order: 'price_asc'}))
        break
    }
  }, [orderByIndex])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  function handleClose () {
    setAnchorEl(null)
  }

  const [inputVal, setInputVal] = useState('')
  function handleSearchInput (e: any) {
    const val = e.target.value
    setInputVal(val)
  }
  // 确认后再进行搜索
  function submitSearch () {
    setKeyword(inputVal)
  }
  function handleKey (e: any) {
    if (e.key === 'Enter') {
      submitSearch()
    }
  }

  const [iconType, setIconType] = useState('icon-type-glass')
  function handleSearchFocus () {
    setIconType('icon-type-enter')
  }
  function handleSearchBlur () {
    if (inputVal.length === 0) {
      setIconType('icon-type-glass')
    }
    submitSearch()
  }

  return (
    <div>
      {/* 选项 start */}
      <Grid container alignItems="center" spacing={3} style={{
        marginTop: '32px',
        paddingBottom: '16px'
      }}>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <StyledLabelBox>
            <SectionTitle title={t('Home.explore')} subTitle={list.length + ` ${t('Home.items')}`} />
          </StyledLabelBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Grid container alignItems="center">
            {/* search input start */}
            <StyledSearchBox className={iconType}>
              <StyledIcon className="search-glass" src={searchIcon} alt="icon"/>
              <StyledIcon onClick={submitSearch} className="search-enter" src={arrowRightIcon} alt="icon"/>
              <StyledInput onKeyUp={handleKey} onChange={handleSearchInput} onFocus={handleSearchFocus} onBlur={handleSearchBlur} />
            </StyledSearchBox>
            {/* search input end */}
            {/* checkbox start */}
            {/* <Grid item xs={4}>
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <StyledToolBox>
                    <FormControlLabel
                      control={<Checkbox checked={sortKeys.onsale === 1} style={{color: '#fff'}} onChange={onsaleChange} name="checkedA" />}
                      label={t("Home.onSale")}
                    />
                  </StyledToolBox>
                </Grid>
              </Grid>
            </Grid> */}
            {/* checkbox end */}
            {/* selector start */}
            {/* <Grid item xs={8}>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <StyledToolBox className="left-border">
                    {t("Home.Sort")}
                  </StyledToolBox>
                </Grid>
                <Grid item xs={8}>
                  <StyledToolBox>
                    <Button aria-controls="simple-menu" style={{color: '#fff'}} aria-haspopup="true" onClick={handleClick}>
                      {
                        orderByIndex !== -1 ? orderByList[orderByIndex] : t("Home.Filter")
                      }
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => {
                        setOrderByIndex(0)
                        handleClose()
                      }}>{orderByList[0]}</MenuItem>
                      <MenuItem onClick={() => {
                        setOrderByIndex(1)
                        handleClose()
                      }}>{orderByList[1]}</MenuItem>
                      <MenuItem onClick={() => {
                        setOrderByIndex(2)
                        handleClose()
                      }}>{orderByList[2]}</MenuItem>
                    </Menu>
                  </StyledToolBox>
                </Grid>
              </Grid>
            </Grid> */}
            {/* selector end */}
          </Grid>
        </Grid>
      </Grid>
      {/* 选项 end */}
      {/* 列表 start */}
      <Grid container spacing={3}>
        {loading && <SkeletonContent />}
        {!loading &&
          (
            list.map((item, i: any) => (
              <Grid item xs={12} sm={6} md={item.smWidth} lg={item.width} key={item.product.id}>
                <ItemCard product={item.product} />
              </Grid>
            ))
          )
        }
      </Grid>
      {/* 列表 end */}
      <StyledLoadMore style={{display: (loading || isNoMore) ? 'none' : 'block'}}>
        <Button
          disabled={loadingMore}
          size="large"
          color="primary"
          onClick={loadingMoreEvent}
        >
          {loadingMore && <CircularProgress size={24} />} {t('Loading.more')}
        </Button>
      </StyledLoadMore>
      <StyledLoadMore style={{display: (!loading && list.length === 0) ? 'block' : 'none'}}>
        <span>{t('Loading.nodata')}</span>
      </StyledLoadMore>
    </div>
  )
}
/*
<StyledSearchBox>
  <StyledIcon src={searchIcon} alt="icon" />
  <StyledInput />
</StyledSearchBox>
*/
function SkeletonContent () {
  return (
    <>
      {[1, 2, 3, 4].map(el => {
        return (
          <Grid item xs={12} sm={6} md={3} key={el}>
            <Skeleton variant="rect" width="100%" height="300px" />
          </Grid>
        )
      })}
    </>
  )
}

function filterProducts (products: NFTItemType[]) {
  const tempList: any[] = []

  if (products && products.length > 0) {
    // console.log('products', products)
    const totalWidth = 12
    const defaultWidth = 2
    const smDefaultWidth = 3
    const doubleWidth = 4
    const smDoubleWidth = 6
    let count = totalWidth
    let smCount = totalWidth
    // 当前是当前行的第几个
    // let cur = 0
    // 宽高比大于scaleThreshold的话，就展示defaultWidth的长度
    const scaleThreshold = 1.2
    let curWidth = 0
    let smCurWidth = 0
    products.forEach((item, i) => {
      // cur++
      if (item.resource.scale && Number(item.resource.scale) > scaleThreshold && count >= doubleWidth) {
        curWidth = doubleWidth
        count -= doubleWidth
      } else {
        curWidth = defaultWidth
        count -= defaultWidth
      }
      if (item.resource.scale && Number(item.resource.scale) > scaleThreshold && smCount >= smDoubleWidth) {
        smCurWidth = smDoubleWidth
        smCount -= smDoubleWidth
      } else {
        smCurWidth = smDefaultWidth
        smCount -= smDefaultWidth
      }
      tempList.push({
        product: item,
        width: curWidth,
        smWidth: smCurWidth
      })
      // reset
      if (count == 0) {
        count = totalWidth
        // cur = 0
      }
      if (smCount == 0) {
        smCount = totalWidth
        // cur = 0
      }
    })
  }
  return tempList
}

const StyledCards = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  &.hidden {
    overflow-y: hidden;
  }
`

const StyledLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  box-sizing: border-box;
  // padding-top: 32px;
`

const StyledLabelBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const StyledLabel = styled.div`
  @media (min-width: 981px) {
    font-size: 30px;
  }
  @media (min-width: 768px) {
    font-size: 27px;
  }
  font-size: 24px;
  font-weight: 900;
  color: #040405;
`

const StyledCountBox = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-left: 20px;
  margin-bottom: 3px;
`

const StyledCountB = styled.div`
  font-weight: bolder;
`

const StyledCountUnit = styled.div`

`

const StyledTypeList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
  flex-flow: row nowrap;
`

const StyledTypeItemBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  padding-left: 22px;
  padding-right: 22px;
  user-select: none;
  cursor: pointer;
  background: white;
  border: 1px solid rgba(4, 4, 5, 0.1);
  border-radius: 40px;
  text-align: center;
  width: auto;
  margin-right: 12px;
  margin-bottom: 12px;
  transition: all 0.12s ease-in-out 0s;
  &.type_item--active {
    background: rgb(4, 4, 5);
    border: 1px solid rgb(4, 4, 5);
    box-shadow: rgb(0 0 0 / 8%) 1px 2px 10px;
  }
  &:hover {
    border: 1px solid rgba(4, 4, 5, 0.3);
  }
`

const StyledTypeItem = styled.div`
  color: rgb(4, 4, 5);
  font-size: 14px;
  font-weight: 900;
  width: max-content;
  &.type_item--active {
    color: white;
  }
`

const StyledTypeListContainer = styled.div`
  overflow-x: scroll;
  width: 100%;
  padding-bottom: 20px;
`

const StyledTypeListContainerOuter = styled.div`
  overflow: hidden;
`

const StyledLoadMoreBtnBox = styled.div`
  margin-top: 32px;
`



const StyledToolBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  &.left-border {
    border-left: 1px solid rgb(222, 222, 222);
  }
  &.center {
    justify-content: center;
  }
  @media (max-width: 768px) {
    justify-content: flex-start !important;
  }
`

const StyledNoMoreData = styled.div`
  font-size: 15px;
  width: 100%;
  text-align: center;
  color: gray;
  height: 48px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const StyledLoadMore = styled.div`
  padding: 1rem;
  text-align: center;
`
const StyledSearchBox = styled.div`
  background: rgb(52, 75, 85);
  border-radius: 100rem;
  padding: 4px 1.5rem;
  height: 40px;
  display: flex;
  align-items: center;
  min-width: 100px;
  max-width: 500px;
  width: 100%;

  &.icon-type-glass .search-glass {
    display: block;
  }
  &.icon-type-enter .search-enter {
    display: block;
  }
`
const StyledIcon = styled.img`
  display: block;
  height: 100%;
  padding: 2px;
  object-fit: contain;
  flex-shrink: 0;
  margin-right: 0.5rem;
  transition: all 0.2s;

  &.search-glass {
    display: none;
  }
  &.search-enter {
    display: none;
    cursor: pointer;
    filter: brightness(10);
    &:hover {
      filter: brightness(2);
    }
  }
`
const StyledInput = styled.input`
  width: 100%;
  padding: 0 1rem;
  color: #fff;
  height: 100%;
  border: none;
  background: transparent;
  font-size: 1rem;
  :focus {
    border: none;
  }
  :focus-visible {
    border: none;
  }
`
