const list = [
  'Richard Mille',
  'Roger Dubuis',
  'Breguet',
  'Patek Philippe',
  'IWC',
  'Cartire',
  'Audemars Pigeut',
  'Rolex',
  'Vacheron Constantin',
  'Piaget',
  'OMEGA',
  'Jaeger-Le Coulter',
  'Yamazaki',
]

export default list
