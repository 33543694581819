import BigNumber from 'bignumber.js'

/**
 * String(value / 10^18)
 */
export function formatBalanceToDisplay (value: string | BigNumber, decimals = 18): string {
  return new BigNumber(value).div(new BigNumber(10).pow(decimals)).toString()
}

/**
 * String(value * 10^18)
 */
export function formatToMiniUnit (value: string | BigNumber, decimals = 18): string {
  return multipliedAndToBigNumber(value, decimals).toString()
}

/**
 * BigNumber(value * 10^18)
 */
export function multipliedAndToBigNumber (value: string | BigNumber, decimals = 18): BigNumber {
  return new BigNumber(value).multipliedBy(new BigNumber(10).pow(decimals))
}
