import React, {useContext, useMemo, useState} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Token} from '@uniswap/sdk'
import pageWrapImg from '../../assets/images/market-bg.png'
import Grid from '@material-ui/core/Grid'
import {getContract, mapToken, refreshPageAfter1s} from 'utils'
import {CircularProgress, TextField, FormControl, InputLabel, MenuItem, Select, Button} from '@material-ui/core'
import {ERC721_ADMIN_CREATE_ADDRESS, SUPPORT_TOKEN_LIST} from '../../constants'
import ERC721_ADMIN_CREATE_ABI from 'constants/abis/erc721-admin-create.json'
import {formatBalanceToDisplay, formatToMiniUnit} from 'utils/parseBignumber'
import SectionTitle from 'components/SectionTitle'
import {useActiveWeb3React} from 'hooks/index'
import notify from 'components/Toast'
import {getData, uploadNft} from 'apis'
import {mintToWallet, mintThenAddSellOrder} from 'logicUtils'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import typeList from './category'
import users from './users'
import {useHistory} from 'react-router-dom'

type StateType = {
  fileFile: File | string, // File
  fileSrc: string,
  fileName: string,

  posterFile: File | string,
  posterSrc: string,
  posterName: string,
  needPoster: boolean

  title: string,
  description: string,
  category: string,
  zhName: string,
  zhDescription: string,
  jaName: string,
  jaDescription: string
  token: string
  price: string
}

export default function Publish () {
  const {t, i18n} = useTranslation()
  const {account, chainId, library} = useActiveWeb3React()
  const history = useHistory()

  const isAuth = account && users.includes(account.toLowerCase())
  if (!isAuth) {
    history.push('/nftmarket')
  }

  // 支持的后缀
  const supportImgExtends = ['gif', 'ico', 'png', 'svg', 'webp', 'jpg', 'jpeg', 'bmp']
  const supportVideoExtends = ['webm', 'mp4', 'ogg']
  const supportMusicExtends = ['mp3', 'ogg', 'wav']

  // 图片的长宽和比例
  const [scale, setScale] = React.useState(1)

  const [showTokenName, setShowTokenName] = React.useState(SUPPORT_TOKEN_LIST[0].symbol)
  const [selectToken, setSelectToken] = React.useState(SUPPORT_TOKEN_LIST[0].address);

  const defaultValue: StateType = {
    fileFile: '', // File
    fileSrc: '',
    fileName: '',

    posterFile: '',
    posterSrc: '',
    posterName: '',
    needPoster: false,

    title: '',
    description: '',
    category: '',
    zhName: '',
    zhDescription: '',
    jaName: '',
    jaDescription: '',
    token: SUPPORT_TOKEN_LIST[0].address,
    price: ''
  }

  const [state, setState] = useState<StateType>(defaultValue)
  // 上传成功之后的预览对象
  const [previewObj, setPreviewObj] = React.useState({
    title: '',
    description: '',
    category: '',
    jaName: '',
    jaDescription: '',
    zhName: '',
    zhDescription: '',
    fileSrc: '',
    token: '',
    posterSrc: ''
  })
	// upload之后拿到的数据的url
  const [previewUri, setPreviewUri] = React.useState('')

  async function handleChange (e: any) {
    if (e.target.name === 'fileFile') {
      let file = e.target.files[0];
      const supportExtends = supportImgExtends.concat(supportVideoExtends, supportMusicExtends)
      const result = await fileHandle(file, supportExtends)

      if (!result.isSupport) {
        notify(t(`only support pictures in ${supportExtends.join(',')} format`));
        return
      }

      setState({...state, 'fileSrc': result.src, 'fileFile': result.file, fileName: result.name, needPoster: !result.isImage})
      return
    }

    if (e.target.name === 'posterFile') {
      let file = e.target.files[0];
      const result = await fileHandle(file, supportImgExtends)

      if (!result.isSupport) {
        notify(t(`only support pictures in ${supportImgExtends.join(',')} format`));
        return
      }

      setState({...state, 'posterSrc': result.src, 'posterFile': result.file, posterName: result.name})
      return
    }

    if (e.target.name == 'token') {
      setSelectToken(e.target.value);
      setState({...state, [e.target.name]: e.target.value});
      return
    }

    setState({...state, [e.target.name]: e.target.value})
  }

  const [confirmLoading, setConfirmLoading] = useState(false)
  // 提交数据
  async function handleConfirm () {
    if (!account) {
      throw Error(t('Missing account'))
    }

    // reqpired input
    const keyArray: Array<keyof StateType> = ['title', 'description', 'category', 'price']
    const hasEmptyValue = keyArray.some(key => {
      return state[key] === '' || state[key] === undefined
    })
    if (hasEmptyValue) {
      notify(t('please fill the text'))
      return
    }

    // 判断是否有上传文件
    if (!state['fileFile']) {
      notify(t('please upload your file'))
      return
    }
    // 如果主文件不是图片，则判断是否有上传poster
    if (state['needPoster'] && !state['posterFile']) {
      notify(t('please upload your poster'))
      return
    }

    // check image size
    const maxsize = 50
    if (state.fileFile && (state.fileFile as File).size > 1024 * 1024 * maxsize) {
      notify(t('file is too large, less then TT').replace('TT', `${maxsize}M`))
      return
    }
    if (state.posterFile && (state.posterFile as File).size > 1024 * 1024 * maxsize) {
      notify(t('file is too large, less then TT').replace('TT', `${maxsize}M`))
      return
    }

    // 设置loading状态
    setConfirmLoading(true)
    const params:{[key: string]: string|File} = {}
    const paramsKyes = keyArray.concat(['jaDescription', 'jaName', 'zhDescription', 'zhName'])

    paramsKyes.forEach(key => {
      params[key] = (state[key] as string)
    })
    params['desc'] = (state['description'] as string)

    // 默认本体文件放image
    params['image'] = state['fileFile']
    params['animation'] = '' // 默认为空，主要为了重置
    // 如果上传的本体文件不是图片
    if (state['needPoster']) {
      // 把poster添加到image
      params['image'] = state['posterFile']
      // 本体文件添加到animation
      params['animation'] = state['fileFile']
    }

    params['scale'] = String(scale)
    params['token'] = state['token']
    params['value'] = state['price']

    console.log(params)
    // 上传成功后返回的数据
    const result = await uploadNft(params)

    if (result.data) {
      setPreviewUri(result.data)
      getData(result.data).then(data => {
        setPreviewObj({
          title: data.name,
          description: data.description,
          category: data.category,
          jaName: data.jaName,
          jaDescription: data.jaDescription,
          zhName: data.zhName,
          zhDescription: data.zhDescription,
          fileSrc: data.animation_url,
          token: data.token,
          posterSrc: data.image
        })
        setOpen(true);
        setConfirmLoading(false)

      })

    }


    setConfirmLoading(false)

    // notify(t('Submit success'))

    // refresh page
    // setTimeout(() => {
    //   window.location.href = window.location.origin;
    // }, 1000)

    // if (!eventId) {
    //   // reset page value
    //   setState(defaultValue)
    //   setIndexOfExtensionsArray([])
    // }
    // 预览结果
    setPreviewObj(state)
    setOpen(true)
  }

  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitLoadingOfWallet, setSubmitLoadingOfWallet] = useState(false)
  async function handleSubmit (mintYype: string) {
    if (library && account) {
      // TODO
      const token = mapToken(state.token)
      const priceBigString = formatToMiniUnit(state.price, token.decimals)
      const uri = previewUri
      const contract = getContract(ERC721_ADMIN_CREATE_ADDRESS, ERC721_ADMIN_CREATE_ABI, library, account)
      const signer = library.getSigner(account)

      if (mintYype === 'wallet') {
        await mintToWallet(contract, account, signer, uri)
      } else {
        await mintThenAddSellOrder(contract, signer, uri, token.address, priceBigString)
      }

      notify(t('Submit success'))
      setOpen(false)
      refreshPageAfter1s()
    } else {
      notify(t('Missing account'))
    }
  }

  const [open, setOpen] = useState(false)

  function handleClose () {
    setOpen(false)
  }


  return (
    <>
      <PageWrapper>
        <StyledBgBox>
          <PageWrapperImg src={pageWrapImg} alt="" />
        </StyledBgBox>
        <StyledSection style={{padding: '0'}}>
          <StyledHeader>
            <SectionTitle title={t('Btn.create')} subTitle={t('Publish.CreateCollectible')} />
          </StyledHeader>

          <StyledSection style={{margin: '0'}}>
            <div style={{flexDirection: 'row', display: 'flex'}}>
              <StyledUpload style={{borderColor: 'rgba(255, 255, 255, 0.23)', color: 'rgba(255, 255, 255, 0.7)'}}>
                <input style={{width: '100%', height: '100%', opacity: '0', cursor: 'pointer'}} type="file" name="fileFile" onChange={handleChange} />
                <StyledUploadText>
                  <StyledUploadTextInner>
                    {state.fileName ? state.fileName : t('please upload your file')}
                  </StyledUploadTextInner>
                </StyledUploadText>
              </StyledUpload>
              <div style={{width: '30%', paddingLeft: '20px'}}>
                <FormControl style={{
                  width: '100%'
                }}>
                  <InputLabel id="select-type-outlined-label" style={{
                    width: '100%'
                  }}>{t("Publish.Category")}</InputLabel>
                  <Select required value={state.category} labelId="select-type-outlined-label" name="category" onChange={handleChange}>
                    {typeList.map((item: any) =>
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <StyledTips style={{display: state.fileSrc ? 'none' : 'block'}}>
              <div>{t('Supported TT format').replace('TT', t('Publish.image'))}: <StyledSupportImgExtends>{supportImgExtends.join(',')}</StyledSupportImgExtends></div>
              <div>{t('Supported TT format').replace('TT', t('Publish.video'))}: <StyledSupportImgExtends>{supportVideoExtends.join(',')}</StyledSupportImgExtends></div>
              {/* <div>{t('Supported TT format').replace('TT', t('Publish.audio'))}: <StyledSupportImgExtends>{supportMusicExtends.join(',')}</StyledSupportImgExtends></div> */}
            </StyledTips>
            <div style={{
              display: state.fileSrc ? 'block' : 'none'
            }}>
              <img id="publish_preview_img" src={state.fileSrc} style={{width: '100px', height: 'auto'}} onLoad={(event) => {
                console.log('scale', event.currentTarget.naturalWidth / event.currentTarget.naturalHeight)
                setScale(event.currentTarget.naturalWidth / event.currentTarget.naturalHeight)
              }} />
            </div>
            {/* poster start */}
            {state.needPoster && (
              <>
                <div style={{flexDirection: 'row', display: 'flex'}}>
                  <StyledUpload style={{borderColor: 'rgba(255, 255, 255, 0.23)', color: 'rgba(255, 255, 255, 0.7)'}}>
                    <input style={{width: '100%', height: '100%', opacity: '0', cursor: 'pointer'}} type="file" name="posterFile" onChange={handleChange} />
                    <StyledUploadText>
                      <StyledUploadTextInner>
                        {state.posterName ? state.posterName : t('please upload your file')}
                      </StyledUploadTextInner>
                    </StyledUploadText>
                  </StyledUpload>
                </div>
                <StyledTips style={{display: state.posterSrc ? 'none' : 'block'}}>
                  <div>{t('Video and audio file need a poster')}</div>
                  <div>{t('Supported TT format').replace('TT', t('Publish.image'))}: <StyledSupportImgExtends>{supportImgExtends.join(',')}</StyledSupportImgExtends></div>
                </StyledTips>
                <div style={{
                  display: state.posterSrc ? 'block' : 'none'
                }}>
                  <img id="publish_preview_img" src={state.posterSrc} style={{width: '100px', height: 'auto'}} onLoad={(event) => {
                    console.log('scale', event.currentTarget.naturalWidth / event.currentTarget.naturalHeight)
                    setScale(event.currentTarget.naturalWidth / event.currentTarget.naturalHeight)
                  }} />
                </div>
              </>
            )
            }
            {/* poster end */}
            <TextField
            required
            margin="dense"
            name="title"
            label={t("Publish.Title")}
            fullWidth
            variant="outlined"
            helperText={t("Publish.TitleTip")}
            onChange={handleChange}
            />
            <TextField
            margin="dense"
            name="jaName"
            label={t("Publish.JATitle")}
            fullWidth
            variant="outlined"
            helperText={t("Publish.JATitleTip")}
            onChange={handleChange}
            />
            <TextField
            margin="dense"
            name="zhName"
            label={t("Publish.ZHTitle")}
            fullWidth
            variant="outlined"
            helperText={t("Publish.ZHTitleTip")}
            onChange={handleChange}
            />
            <TextField
            required
            margin="dense"
            name="description"
            label={t("Publish.Description")}
            fullWidth
            variant="outlined"
            helperText={t("Publish.DescriptionTip")}
            multiline
            rows={5}
            onChange={handleChange}
            />
            <TextField
            margin="dense"
            name="jaDescription"
            label={t("Publish.JADescription")}
            fullWidth
            variant="outlined"
            helperText={t("Publish.JADescriptionTip")}
            multiline
            rows={5}
            onChange={handleChange}
            />
            <TextField
            margin="dense"
            name="zhDescription"
            label={t("Publish.ZHDescription")}
            fullWidth
            variant="outlined"
            helperText={t("Publish.ZHDescriptionTip")}
            multiline
            rows={5}
            onChange={handleChange}
            />
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
              <TextField required margin="dense" name="price" type="number" label={t("Publish.Price")} variant="outlined" style={{width: 100, marginRight: 20}} onChange={handleChange} />
              <FormControl style={{
                width: 100
              }}>
                <InputLabel id="select-outlined-label" style={{
                  width: 100
                }}>{t("Publish.Token")}</InputLabel>
                <Select value={selectToken} labelId="select-outlined-label" name="token" onChange={handleChange}>
                  {SUPPORT_TOKEN_LIST.map((token) =>
                    <MenuItem key={token.address} value={token.address}>{token.symbol}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              handleConfirm().catch(err => {
                console.error(err)
                setConfirmLoading(false)
                notify(err.toString ? err.toString() : err)
              })
            }}
            style={{borderRadius: '100rem'}}
            disabled={confirmLoading}
            >
              {confirmLoading && <CircularProgress size={24} />}
              {t('Publish.Confirm')}
            </Button>
          </StyledSection>
        </StyledSection>

        {/* 预览 start */}
        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{t("Publish.ConfirmYourInput")}</DialogTitle>
          <DialogContent style={{
            minWidth: '300px'
          }}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                {previewObj.fileSrc ? (
                  <DialogContentText>{t("Publish.posterImage")}:</DialogContentText>
                ) : (
                  <DialogContentText>{t("Publish.UploadedImage")}:</DialogContentText>
                )}
              </Grid>
              <Grid item xs={12} sm={8}>
                <DialogContentText>
                  <div>
                    <img src={previewObj.posterSrc} style={{width: '250px', height: 'auto'}} />
                  </div>
                </DialogContentText>
              </Grid>
              {
                previewObj.fileSrc ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <DialogContentText>{t("Publish.video")}:</DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <DialogContentText>
                        <StyledVideo poster={previewObj.posterSrc} loop autoPlay width="100%" height="100%" muted>
                          <source src={previewObj.fileSrc} type="video/mp4" />
                          <source src={previewObj.fileSrc} type="video/ogg" />
                          <source src={previewObj.fileSrc} type="video/webm" />
                        </StyledVideo>
                      </DialogContentText>
                    </Grid>
                  </>
                ) : null
              }
              <Grid item xs={12} sm={4}>
                <DialogContentText>{t("Publish.Title")}:</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DialogContentText>{previewObj.title}</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={4}>
                <DialogContentText>{t("Publish.JATitle")}:</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DialogContentText>{previewObj.jaName}</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={4}>
                <DialogContentText>{t("Publish.ZHTitle")}:</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DialogContentText>{previewObj.zhName}</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={4}>
                <DialogContentText>{t("Publish.Description")}:</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DialogContentText><span style={{
                  whiteSpace: 'pre-wrap'
                }}>{previewObj.description}</span></DialogContentText>
              </Grid>
              <Grid item xs={12} sm={4}>
                <DialogContentText>{t("Publish.JADescription")}:</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DialogContentText><span style={{
                  whiteSpace: 'pre-wrap'
                }}>{previewObj.jaDescription}</span></DialogContentText>
              </Grid>
              <Grid item xs={12} sm={4}>
                <DialogContentText>{t("Publish.ZHDescription")}:</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DialogContentText><span style={{
                  whiteSpace: 'pre-wrap'
                }}>{previewObj.zhDescription}</span></DialogContentText>
              </Grid>
              <Grid item xs={12} sm={4}>
                <DialogContentText>{t("Publish.Price")}:</DialogContentText>
              </Grid>
              <Grid item xs={12} sm={8}>
                <DialogContentText><span style={{
                  whiteSpace: 'pre-wrap'
                }}>{state.price} {mapToken(previewObj.token)?.symbol}</span></DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleClose()
            }} color="primary" >
              {t("Publish.Close")}
            </Button>
            <div style={{position: 'relative'}}>
              <Button onClick={async () => {
                setSubmitLoading(true)
                await handleSubmit('market').catch(err => err)
                setSubmitLoading(false)
              }} color="primary" variant="contained" disabled={submitLoading || submitLoadingOfWallet}>
                {submitLoading && <CircularProgress size={24} />}
                {t("Publish.mintToMarket")}
              </Button>
            </div>
            <div style={{position: 'relative'}}>
              <Button
                onClick={async () => {
                  setSubmitLoadingOfWallet(true)
                  await handleSubmit('wallet').catch(err => err)
                  setSubmitLoadingOfWallet(false)
                }}
                color="primary"
                variant="contained"
                disabled={submitLoading || submitLoadingOfWallet}
              >
                {submitLoadingOfWallet && <CircularProgress size={24} />}
                {t("Publish.mintToWallet")}
              </Button>

            </div>
          </DialogActions>
        </Dialog>
        {/* 预览 end */}
      </PageWrapper>
    </>
  )
}

type fileHandleType = {
  isSupport: false
} | {
  isSupport: true,
  isImage: boolean,
  file: File,
  name: string,
  src: string,
}

function fileHandle (file: File, supportExtends: string[]): Promise<fileHandleType> {
  return new Promise((resolve, reject) => {

    // 这里判断文件后缀
    const extension = file.name.split('.')
    // console.log('后缀', extension[extension.length - 1])
    let isSupport = false
    supportExtends.forEach((item, i) => {
      if (item === extension[extension.length - 1]) {
        isSupport = true
      }
    })
    if (isSupport === false) {
      resolve({
        isSupport: false
      })
      return
    }

    const isImage = /^image\/.*/.test(file.type)

    const fileName = `File '${file.name}' was selected`

    if (isImage) {
      let fr = new FileReader()
      fr.onloadend = function (e) {
        resolve({
          isSupport: true,
          isImage,
          file,
          name: fileName,
          src: (e.target?.result as string),
        })
      }
      fr.readAsDataURL(file)
    } else {
      resolve({
        isSupport: true,
        isImage,
        file,
        name: fileName,
        src: '',
      })
    }
  })
}

const PageWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-top: 130px;
  padding-bottom: 130px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 40px;
  background-color: #212121;
  -o-object-fit: cover;
  object-fit: cover;
  @media screen and (max-width: 991px){
      margin-right: 0px;
      padding-left: 0px;
      border-radius: 0px;
  }
  @media screen and (max-width: 767px){
      overflow: hidden;
      min-height: auto;
      margin-right: 0px;
      padding-top: 150px;
      padding-right: 0px;
      padding-bottom: 150px;
  }
  @media screen and (max-width: 479px){
  overflow: hidden;
  padding-right: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -o-object-fit: cover;
  object-fit: cover;
}
`
const PageWrapperImg = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  display: block;
  max-height: 100%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -o-object-fit: cover;
  object-fit: cover;
`
const StyledBgBox = styled.div`
  position: fixed;
  inset: 0% 50px;
  z-index: 0;
  display: flex;
  overflow: hidden;
  width: auto;
  min-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 33, 33);
  object-fit: cover;
  border-radius: 40px;

  @media screen and (max-width: 991px){
    inset: 0;
    border-radius: 0;
  }
`
const StyledSection = styled.section`
  background: rgba(0,0,0,0.3);
  padding: 2rem;
  border-radius: 1.5rem;
  margin: 2rem auto;
  max-width: 800px;
  width: 100%;
  position: relative;
`

const StyledHeader = styled.div`
  margin: 2rem;
`

const StyledUpload = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.87);
  margin: 10px 0 10px 0;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
`

const StyledUploadText = styled.div`
  position: absolute;
  pointer-events: none;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-right: 14px;
  cursor: pointer;
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
`

const StyledUploadTextInner = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
`

const StyledTips = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
`
const StyledSupportImgExtends = styled.span`
  font-weight: 600;
`
const StyledVideo = styled.video``
