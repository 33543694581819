import {useCallback} from 'react'
import {useActiveWeb3React} from 'hooks/index'
import {auctionDeposit} from '../logicUtils'
import {useAuctionContract} from 'hooks/useContract'


function useAuctionDeposit () {
  const {account, library} = useActiveWeb3React()
  const auctionContract = useAuctionContract()

  const cb = useCallback(async (targetToken: string, amount: string) => {
    if (account && library && auctionContract) {
      const signer = library.getSigner(account)
      const tx = await auctionDeposit(auctionContract, targetToken, amount, signer)
      return tx
    }
  }, [account, library, auctionContract])

  return cb
}

export default useAuctionDeposit
