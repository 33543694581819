import React, {useRef} from 'react'
import styled from 'styled-components'
import {NFTItemType} from '../../types'

type NftMediaProps = {
    nft: NFTItemType
    width?: number
    height?: number
}

export default function NftMedia ({nft, width, height}: NftMediaProps) {
    const vidRef = useRef(null)
    const media = nft.resource.animation_url
    const image = nft.resource.image

    if (media) {
        return (
            <div>
                <StyledVideo ref={vidRef} poster={image} loop autoPlay width="100%" height="100%" muted>
                    <source src={media} type="video/mp4" />
                    <source src={media} type="video/ogg" />
                    <source src={media} type="video/webm" />
                </StyledVideo>
            </div>
        )
    }
    return (
        <StyledImg src={nft.resource.image} alt={nft.resource.name} />
    )
}

const StyledVideo = styled.video`
    max-height: 600px;
`
const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: contain;
`
