import React, {useContext, useMemo, useState, useEffect, useCallback} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {NavLink, useParams, useLocation, useHistory} from 'react-router-dom'
import pageWrapImg from '../../assets/images/market-bg.png'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import {feedbackText, shortenAddress, mapToken, refreshPageAfter1s, getLanguage} from 'utils'
import {formatBalanceToDisplay} from 'utils/parseBignumber'
import {bidsResponse, NFTItemType} from 'types'
import History from './History'
import Bids from './Bids'
import Trade from './Trade'
import LoadingPage from 'components/LoadingPage'
import {detail, auctionAPI, bidsAPI} from 'apis'
import notify from 'components/Toast'
import BidModal from 'components/BidModal'
import AcceptModal from 'components/AcceptModal'
import useModal from 'hooks/useModal'
import {ql_get_nft_history} from 'thegraph/index'
import StatusTag from 'components/StatusTag'
import CategoryTag from 'pages/Profile/CategoryTag'
import Countdown from 'components/Countdown'
import BigNumber from 'bignumber.js'
import arrowback from 'assets/images/arrow-right.svg'
import NftMedia from 'components/NftMedia'

enum InfoTab {
  history, bids
}

const PAGE_TYPE_AUCTION = 'auction'

export default function NftMarket () {
  const {t, i18n} = useTranslation()
  const [language, setLanguage] = React.useState(getLanguage((i18n as any).language))

  const {nftaddress, tokenid, auctionId}: {nftaddress: string, tokenid: string, auctionId: string} = useParams()
  const location = useLocation()
  const routerHistory = useHistory()
  // 获取当前是哪个页面  auction / product
  const pageType = location.pathname.split('/')[1]
  const theme = useContext(ThemeContext)
  const [data, setData] = useState<NFTItemType>(({resource: {}} as NFTItemType))
  const [loading, setLoading] = useState(true)

  // 加载auction商品数据
  function loadAuction (paramId: string) {
    setLoading(true)
    auctionAPI(paramId).then(data => {
      console.log('loadData detail', data)
      setData(data)
      setLoading(false)
      // 自动加载bids数据，为了获取到最后一次竞价
      loadBids()

    }).catch(() => {
      setLoading(false)
    })
  }

  const [infotab, setInfotab] = useState(InfoTab.history)

  // bid 相关内容
  const [bidList, setBidList] = useState<bidsResponse[]>([])
  const [isBidsLoading, setIsBidsLoading] = useState(false)

  const token = mapToken(data.targetToken)

  function loadBids () {
    if (!auctionId) {
      return
    }
    setIsBidsLoading(true)
    bidsAPI(auctionId).then((res: bidsResponse[]) => {
      res.forEach((el: bidsResponse) => {
        el.timeStamp = String(new Date(el.timeStamp).getTime()).substring(0, 10)
      })
      setBidList(res)
      setIsBidsLoading(false)

      if (res.length > 0) {
        // 自动显示bid列表
        setInfotab(InfoTab.bids)
      }
      return res
    })
  }

  useEffect(() => {
    // 如果是auction页面，则调用auction接口
    if (pageType === PAGE_TYPE_AUCTION) {
      loadAuction(auctionId)
    } else {
      detail(nftaddress, tokenid).then((res) => {
        setData(res)
        setLoading(false)
      }, err => {
        console.error(err)
        setLoading(false)
      })
    }
  }, [nftaddress, tokenid, auctionId])

  // 获取nft交易历史
  const getHistory = useCallback(() => {
    return ql_get_nft_history(nftaddress, tokenid)
  }, [nftaddress, tokenid])

  function goBack () {
    const len = routerHistory.length
    if (len > 0) {
      routerHistory.goBack()
    } else {
      routerHistory.push('/nftmarket')
    }
  }


  const [openAcceptModal] = useModal(<AcceptModal modalTitle={t('Btn.Accept')} onConfirm={() => {routerHistory.push('/nftmarket')}} item={data} bidList={bidList} />)
  const [openBidModal] = useModal(<BidModal modalTitle={t('Btn.Bid')} onConfirm={() => {loadBids()}} item={data} bidList={bidList} />)

  return (
    <>
      <PageWrapper>
        <StyledBgBox>
          <PageWrapperImg src={pageWrapImg} alt="" />
        </StyledBgBox>
        <StyledSection>
          {loading && <LoadingPage />}
          {!loading && <StyledProductBox>
            <Grid container alignItems="stretch" spacing={3}>
              {/* 图片 start */}
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <div>
                  <StyledBackText onClick={goBack}>
                    <StyledBack src={arrowback} alt="back" />
                    <span>{t('Btn.Back')}</span>
                  </StyledBackText>
                </div>
                <StyledImgBox>
                  <NftMedia nft={data} />
                </StyledImgBox>
              </Grid>
              {/* 图片 end */}
              {/* 信息 start */}
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <StyeldInfoBox>
                  <StyledTitle>
                    {feedbackText(language, {ja: data.resource.jaName, zh: data.resource.zhName}, data.resource.name)}
                  </StyledTitle>
                  <StyledExtendsInfo>
                    <StatusTag status={data.currentState}></StatusTag>
                    <CategoryTag name={data.resource.category} />
                  </StyledExtendsInfo>
                  <StyledContract>
                    {data.NFTContract}
                    <span style={{fontWeight: 'bolder', marginLeft: '0.5rem'}}>#{data.tokenId}</span>
                  </StyledContract>
                  {/* price start */}
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <StyledPrice>{formatBalanceToDisplay(data.price, token.decimals)} {token.symbol}</StyledPrice>
                    </Grid>
                    <Grid item xs={6}>
                      <StyledOwner to={`/profile/onsale/${data.seller}`}>{shortenAddress(data.seller)}</StyledOwner>
                    </Grid>
                  </Grid>
                  {/* price end */}
                  {/* information start */}
                  <StyledInfoTitle>{t('Product.information')}</StyledInfoTitle>
                  <StyledInfoText>
                    <pre style={{whiteSpace: 'pre-wrap'}}>
                      {feedbackText(language, {ja: data.resource.jaDescription, zh: data.resource.zhDescription}, data.resource.description)}
                    </pre>
                  </StyledInfoText>
                  {/* information end */}
                  <StyledInfoTitle>
                    <StyledTitleTab onClick={() => {setInfotab(InfoTab.history)}} className={infotab === InfoTab.history ? 'active' : ''}>{t('Product.history')}</StyledTitleTab>
                    <StyledTitleTab onClick={() => {setInfotab(InfoTab.bids)}} className={infotab === InfoTab.bids ? 'active' : ''}>{t('Product.bids')}</StyledTitleTab>
                  </StyledInfoTitle>
                  {/* history start */}
                  <StyledHistoryBox className={infotab === InfoTab.history ? 'active' : ''}>
                    <History fetchData={getHistory} />
                  </StyledHistoryBox>
                  {/* history end */}
                  {/* bids start */}
                  <StyledHistoryBox className={infotab === InfoTab.bids ? 'active' : ''}>
                    <Bids loading={isBidsLoading} data={bidList} />
                  </StyledHistoryBox>
                  {/* bids end */}
                  {
                    /* 正在拍卖的商品，才显示时间 */
                    data.currentState === 'ONGOING' && (
                      <StyledBidinfo>
                        <StyledBidinfoItem>
                          <span style={{color: '#ccc'}}>{t('Product.AuctionEnds')}</span>
                          <Countdown timestamp={data.expiration} />
                        </StyledBidinfoItem>
                        <StyledBidinfoItem>
                          <span style={{color: '#ccc'}}>{t('Product.MinimumPriceUp')}</span>
                          <span>{formatBalanceToDisplay(new BigNumber(data.price).multipliedBy(0.01), token.decimals)} {token.symbol}</span>
                        </StyledBidinfoItem>
                      </StyledBidinfo>
                    )
                  }
                  {/* btn start */}
                  <StyledBtnBox>
                    <Trade
                      item={data}
                      status={data.currentState}
                      targetToken={data.targetToken}
                      price={data.price}
                      owner={data.seller}
                      acceptEvent={openAcceptModal}
                      bidEvent={openBidModal}
                    />
                  </StyledBtnBox>
                  {/* btn end */}
                </StyeldInfoBox>
              </Grid>
              {/* 信息 end */}
            </Grid>
          </StyledProductBox>}
        </StyledSection>
      </PageWrapper>
    </>
  )
}

const PageWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-top: 130px;
  padding-bottom: 130px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 40px;
  background-color: #212121;
  -o-object-fit: cover;
  object-fit: cover;
  @media screen and (max-width: 991px){
      margin-right: 0px;
      padding-left: 0px;
      border-radius: 0px;
  }
  @media screen and (max-width: 767px){
      overflow: hidden;
      min-height: auto;
      margin-right: 0px;
      padding-top: 150px;
      padding-right: 0px;
      padding-bottom: 150px;
  }
  @media screen and (max-width: 479px){
  overflow: hidden;
  padding-right: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -o-object-fit: cover;
  object-fit: cover;
}
`
const PageWrapperImg = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  display: block;
  max-height: 100%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -o-object-fit: cover;
  object-fit: cover;
`
const StyledBgBox = styled.div`
  position: fixed;
  inset: 0% 50px;
  z-index: 0;
  display: flex;
  overflow: hidden;
  width: auto;
  min-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 33, 33);
  object-fit: cover;
  border-radius: 40px;

  @media screen and (max-width: 991px){
    inset: 0;
    border-radius: 0;
  }
`

const StyledSection = styled.section`
  background: rgba(0,0,0,0.3);
  padding: 2rem;
  border-radius: 1.5rem;
  margin: 2rem auto;
  max-width: 1500px;
  width: 100%;
  position: relative;

  ${({theme}) => theme.mediaWidth.upToSmall`
    padding: 0rem;
  `}
`
const StyledProductBox = styled.div`
`

const StyledImgBox = styled.div`
  overflow: hidden;
  padding: 1rem;
`

const StyeldInfoBox = styled.div`
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.2);
  padding: 1rem 1rem 50px 1rem;
  height: 100%;
  position: relative;
`
const StyledBtnBox = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
`
const StyledTitle = styled.h3`
  color: #fff;
  font-weight: bold;
  font-size: 24px;
`
const StyledContract = styled.div`
  color: #ddd;
  font-size: 16px;
  word-wrap: break-word;
  padding: 0.5rem 0;
`
const StyledPrice = styled.span`
  color: rgb(48, 156, 255);
  font-size: 20px;
  font-weight: bold;
`
const StyledOwner = styled(NavLink)`
  border-radius: 100rem;
  color: #fff;
  background: rgb(48, 156, 255);
  padding: 0.25rem 1rem;
  text-decoration: none;

  ${({theme}) => theme.mediaWidth.upToSmall`
    font-size: 0.8rem;
  `}
`
const StyledInfoTitle = styled.h4`
  color: #fff;
  font-size: 20px;
  margin: 1rem 0;
`
const StyledInfoText = styled.p`
  color: #ddd;
  font-size: 16px;
`
const StyledHistoryBox = styled.div`
  height: 300px;
  overflow: hidden auto;
  transition: all 0.1s;
  display: none;

  &.active {
    display: block;
  }
`
const StyledTitleTab = styled.span`
  border-bottom: 2px solid transparent;
  margin-right: 1rem;
  padding-bottom: 4px;
  transition: all 0.2s;
  cursor: pointer;

  &.active {
    border-bottom: 2px solid rgb(48, 156, 255);
  }
`
const StyledExtendsInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
`

const StyledBidinfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
`
const StyledBidinfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50%;
`
const StyledBackText = styled.span`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  line-height: 1;
  cursor: pointer;

  ${({theme}) => theme.mediaWidth.upToSmall`
    margin: 1rem 1rem 0 1rem;
  `}
`
const StyledBack = styled.img`
  filter: brightness(1000);
  width: 1rem;
  transform: rotate(180deg);
  margin-right: 0.5rem;
`
