import React from 'react'
import styled from 'styled-components'
import {useTranslation, Trans, Translation} from 'react-i18next'
import {OrderStatus} from 'types'

interface StatusProps {
  status: OrderStatus
  className?: string
}
const Status = ({
  status,
  className = ''
}: StatusProps) => {
  let {t, i18n} = useTranslation()
  let statusTxt = null
  switch (status) {
    case OrderStatus.SELL:
      statusTxt = t("Status.sell")
      break
    case OrderStatus.AUCTION:
      statusTxt = t("Status.auction")
      break
    case OrderStatus.RESELL:
      statusTxt = t("Status.resell")
      break
    case OrderStatus.SELLOUT:
      statusTxt = t("Status.sellout")
      break
    case OrderStatus.FINISHED:
      statusTxt = t("Status.finished")
      break
    case OrderStatus.INVALID:
      statusTxt = t("Status.invalid")
      break
  }

  return (
    <>
    {statusTxt &&
      <StyledContainer className={
        ` ${className}
          ${status == OrderStatus.SELL ? "sell" : ""}
          ${status == OrderStatus.RESELL ? "resell" : ""}
          ${status == OrderStatus.SELLOUT ? "sellout" : ""}
          ${status == OrderStatus.AUCTION ? "sell" : ""}
          ${status == OrderStatus.FINISHED ? "sellout" : ""}
          ${status == OrderStatus.INVALID ? "invalid" : ""}
        `
      }>
        <StyledContext>
          {statusTxt}
        </StyledContext>
      </StyledContainer>
    }
    </>
  )
}

const StyledContainer = styled.div`
    margin-right: 7px;
    border-radius: 4px;
    color: white;
    height: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    width: fit-content;
    justify-content: center;
    font-weight: bold;
    min-width: 70px;
    padding: 0px 4px;
    &.resell {
        background-color: rgb(27, 139, 235) !important;
    }
    &.sell {
        background-color: rgb(114, 213, 118) !important;
    }
    &.sellout {
        background-color: rgb(219, 29, 49) !important;
    }
    &.invalid {
        background-color: rgb(170, 170, 170) !important;
    }
`

const StyledContext = styled.div`
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
`

export default Status
