import React, { useCallback, useState, useEffect } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'

import { RouteComponentProps } from 'react-router-dom'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise } from '../../components/earn/styled'
import { ButtonPrimary } from '../../components/Button'
import StakingModal from '../../components/earn/StakingModal'
import UnstakingModal from '../../components/earn/UnstakingModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useMuLanPrice } from '../../state/mulanstake/hooks'
import { useAllPlanData, Plan, useStakedList } from '../../state/mulanstake/hooks'
import productImg from '../../assets/images/product.png'
import { MULAN } from '../../constants/index'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import Locksvg from '../../assets/svg/locked.svg'
import Pro_img from 'assets/svg/shell.svg'
import circle1 from "assets/svg/circle-green.svg"
import downloadImg from "assets/img/audit-by-certik.svg"
import swapBackground from '../../assets/images/Stake-background.jpg'
import circleImg from '../../assets/svg/circle-animation.svg'
import blueMulanImg from '../../assets/svg/blue-mulan.svg'
import mulandefiLogo from '../../assets/images/mulan-defi-logo-white.svg'
import calender from '../../assets/svg/calendar.svg'
import mulan_circle from '../../assets/svg/mulan-circle.svg'
import usdt1 from '../../assets/svg/usdt_1.svg'
import Dfplogo from 'components/Dfplogo'


const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PoolData = styled(DataCard)`
  background: #000;
  opacity: 0.8;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`
const StyledCardsWrapper = styled.div`
  display: flex;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
  }
`
const VoteCard = styled(DataCard)`
  overflow: hidden;
  margin-right: 5px;
  margin-bottom: 5px;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

const StyledTableFix = styled.div`
  width: 100%;
  text-align: center;
  background: #000;
  opacity: 0.8;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  table td {
    border: 1px solid #bbbdbe;
  }

  @media screen and (max-width: 600px) {
    table {
      border: 0;
      width: 100%;
    }

    table thead {
      display: block;
    }

    table tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid #ddd;
    }

    table td {
      display: block;
      text-align: right;
      font-size: 14px;
      border-bottom: 1px dotted #ccc;
    }

    table td:last-child {
      border-bottom: 0;
    }

  }
`

const StyledLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`
// stake designs :

const StakeContainer = styled.div`
  position: relative;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    padding-top: 130px;
    padding-bottom: 130px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #212121;
    -o-object-fit: cover;
    object-fit: cover;
    @media screen and (max-width: 991px){
    margin-right: 10px;
    padding-left: 10px;
    border-radius: 0px;
}
@media screen and (max-width: 767px){
    overflow: hidden;
    min-height: auto;
    margin-right: 0px;
    padding-top: 150px;
    padding-right: 10px;
    padding-bottom: 150px;
}
@media screen and (max-width: 479px){
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -o-object-fit: cover;
    object-fit: cover;
}
  `
  const StackPriceSec = styled.div`
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 0 16px 36px -5px rgb(0 0 0 / 30%);
    webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    @media screen and (max-width: 991px){
      margin-bottom: 60px;
    }
  `
  const PriceRow = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    @media screen and (max-width: 479px){
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    }
    `
    const PriceColumn = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    `
    const PriceColumnRow = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
  const LockIcon = styled.img`
  width: 20px;
  `
  const PriceColumnRowVerticle = styled.div`
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    `
    const PriceH1 = styled.h1`
    margin-top: 0px;
    font-family: futura-pt, sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 21px;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
    `
    const PriceH2 = styled.h2`
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: futura-pt, sans-serif;
    color: #fff;
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    `
    const PriceH2Sup = styled.span`
    font-size: 12px;
    `
    const MulanPrice = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    @media screen and (max-width: 479px){
      padding-top: 20px;
      padding-bottom: 20px;
    }
    `
    const MulanPriceRow = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
    const MulanPriceCol = styled.div`
    `
    const MulanPriceColPadding = styled.div`
    padding-right: 20px;
    padding-left: 20px;
    `
    const SwapBg = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    display: block;
    max-height: 100%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -o-object-fit: cover;
    object-fit: cover;
    opacity: 1;
    @media screen and (max-width: 991px){
      -webkit-filter: blur(20px);
      filter: blur(20px);
    }
    @media screen and (max-width: 479px){
      -webkit-filter: hue-rotate(180deg) blur(20px);
      filter: hue-rotate(180deg) blur(20px);
    }
    `
    const Certik = styled.div`
position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    text-align: right;
    @media screen and (max-width: 479px){
    right: 20px;
    bottom: 50px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}
`
const Protection = styled.div`
position: absolute;
    top: -20px;
    right: -10px;
    z-index: 3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 0px;
    perspective: 0px;
`
const Protectionimg = styled.img`
  width: 20px;
  -webkit-perspective: 0px;
  perspective: 0px;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);

`
const CircleButtonInside = styled.img`
  position: absolute;
  width: 120%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 100%;
}
`
const CircleButtonInside2 = styled.img`
  position: absolute;
  width: 140%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 120%;
    max-width: none;
}
`
const DownloadLink = styled.a`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 50px;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  @media screen and (max-width: 479px){
    height: 42px;
}
`
const DownloadImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
  max-width: none;
`
const StackRowSection = styled.div`
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
`
const StackCol = styled.div`
    position: relative;
    margin: 20px 5px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    @media screen and (max-width: 991px){
    margin-bottom: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 60px;
    }
    `
    const StackBox = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-style: solid;
    border-width: 3px;
    border-color: transparent;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 0 16px 36px -10px rgb(0 0 0 / 40%);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    :hover {
      border-color: #305cfa;
    }
    `
    const StackHeader = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 60px 20px 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    `
    const SwapMulanContainer = styled.div`
    position: absolute;
    left: 0px;
    top: -35px;
    right: 0px;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    width: 70px;
    height: 70px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.3);
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    `
    const CircleContainer = styled.div`
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
    const CircleButtn = styled.img`
    position: absolute;
    z-index: 3;
    width: 140%;
    max-width: none;
    @media screen and (max-width: 479px){
    position: absolute;
    width: 120%;
    max-width: none;
    }
    `
    const CircleButtn2 = styled.img`
    position: absolute;
    width: 120%;
    max-width: none;
    @media screen and (max-width: 479px){
      position: absolute;
      width: 100%;
      max-width: none;
    }
    `
    const BlueMulanImg = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    display: block;
    max-height: 50%;
    max-width: none;
    min-height: 50%;
    min-width: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -o-object-fit: cover;
    object-fit: cover;
    `
    const StackNameLogo = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
    const StakeH1 = styled.h1`
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: acumin-pro-extra-condensed, sans-serif;
    color: #fff;
    font-size: 30px;
    line-height: 30px;
    font-weight: 900;
    `
    const StakeLogo = styled.img`
    position: relative;
    width: auto;
    height: 23px;
    margin-top: 8px;
    margin-left: 5px;
    `
    const StakeBody = styled.div`
    display: block;
    width: auto;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    `
    const StakeBodyRow =styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 15px;
    margin-bottom: 25px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    `
    const StakeBodyCol = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    `
    const StakeBodyColRight = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    `
    const CalenderIcn = styled.img`
    position: relative;
    display: block;
    width: 33px;
    height: 33px;
    `
    const StakeBodyH1 = styled.h1`
    margin-top: 0px;
    margin-bottom: 5px;
    color: #fff;
    font-size: 18px;
    line-height: 18px;
    white-space: nowrap;
    `
    const StakeBodyH2 = styled.h2`
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    white-space: nowrap;
    `
    const StakeFooter = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    `
    const StakeButton = styled.a`
    padding: 12px 40px;
    border-radius: 10px;
    background-color: #305cfa;
    box-shadow: 11px 11px 36px -5px rgb(0 0 0 / 30%);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-weight: 700;
    border: 0;
    color: #fff;
    text-decoration: none;
    :hover {
      -webkit-transform: translate(0px, -8px);
      -ms-transform: translate(0px, -8px);
      transform: translate(0px, -8px);
    }
    `



export default function Stake({
  match: {
    params: { currencyIdA, currencyIdB }
  }
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const mulanPrice = useMuLanPrice()

  const allPlans: Plan[] = useAllPlanData()

  const [totalTvl, setTotalTvl] = useState(0)

  useEffect(() => {
    if (allPlans.length > 0) {
      let total = 0
      for (let i = 0; i < allPlans.length; i++) {
        total = total + parseInt(allPlans[i].tvl)
        setTotalTvl(total)
      }
    }
  }, [allPlans, setTotalTvl])

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, MULAN)

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [selectId, setSelectId] = useState('0')
  const [unstakeId, setUnStakeId] = useState('0')
  const [staked, setStaked] = useState('0')
  const [claim, setClaim] = useState('0')
  // fade cards if nothing staked or nothing earned yet

  // get WETH value of staked LP tokens

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(
    (id: string) => {
      setSelectId(id)
      if (account) {
        setShowStakingModal(true)
      } else {
        toggleWalletModal()
      }
    },
    [account, toggleWalletModal, setSelectId]
  )

  const handleWithdrawClick = useCallback(
    (id: string, stake: string, reward: string) => {
      setUnStakeId(id)
      setStaked(stake)
      setClaim(reward)
      if (account) {
        setShowUnstakingModal(true)
      } else {
        toggleWalletModal()
      }
    },
    [account, toggleWalletModal, setUnStakeId]
  )

  const userStakes = useStakedList(account)
  // check is imtoken then retry

  const nowMs = Date.now()
  return(
    <StakeContainer>
      {/* <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>{t('Home.Stat')}</TYPE.mediumHeader>
      </RowBetween> */}

    <StackPriceSec>
      <PriceRow>
        <PriceColumn>
          <PriceColumnRow>
            <LockIcon src={Locksvg} alt="" />
            </PriceColumnRow>
          <PriceColumnRowVerticle>
            <PriceH1>Total Value Locked</PriceH1>
            <PriceH2>${totalTvl} <PriceH2Sup>USDT</PriceH2Sup></PriceH2>
          </PriceColumnRowVerticle>
        </PriceColumn>
        <PriceColumn>
          <MulanPrice>
            <MulanPriceRow>
            <PriceH1>$MULAN Price</PriceH1>
            </MulanPriceRow>
            <MulanPriceRow>
              <MulanPriceCol>
                <PriceH2>1 <PriceH2Sup>MULAN</PriceH2Sup></PriceH2>
              </MulanPriceCol>
              <MulanPriceColPadding>
                <PriceH2> = </PriceH2>
              </MulanPriceColPadding>
              <MulanPriceCol>
                <PriceH2>{parseFloat(mulanPrice)?.toFixed(2)}<PriceH2Sup>USDT</PriceH2Sup></PriceH2>
              </MulanPriceCol>
            </MulanPriceRow>
          </MulanPrice>
        </PriceColumn>
      </PriceRow>
    </StackPriceSec>

    <StackRowSection>


      {allPlans?.map((p: Plan, i) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <StackCol>
              <StackBox>
                <StackHeader>
                  <SwapMulanContainer className="img-container-mulan">
                    <CircleContainer className="circle-container">
                      <CircleButtn src={circleImg} loading="lazy" alt="" className="circle-button" />
                      <BlueMulanImg src={blueMulanImg} loading="lazy" alt="" className="img-absolute-mulan-blue" />
                      <CircleButtn2 src={circleImg} loading="lazy" alt="" className="circle-button-inside" />
                    </CircleContainer>
                  </SwapMulanContainer>
                  <StackNameLogo>
                      <StakeH1>MULAN</StakeH1>
                      <StakeLogo src={mulandefiLogo} loading="lazy" alt="" />
                  </StackNameLogo>
                </StackHeader>
                <StakeBody>
                    <StakeBodyRow>
                      <StakeBodyCol>
                        <CalenderIcn src={calender} loading="lazy" alt="" />
                      </StakeBodyCol>
                      <StakeBodyColRight>
                        <StakeBodyH1>{p.days} {t('Product.days')}</StakeBodyH1>
                        <StakeBodyH2>{t('Stake.stake' + i).replace('TT', p.apy)}</StakeBodyH2>
                      </StakeBodyColRight>
                    </StakeBodyRow>
                    <StakeBodyRow>
                      <StakeBodyCol>
                        <CalenderIcn src={mulan_circle} loading="lazy" alt="" />
                      </StakeBodyCol>
                      <StakeBodyColRight>
                        <StakeBodyH1>{t('Product.locked')}</StakeBodyH1>
                        <StakeBodyH2>{p.locked}  $MULAN</StakeBodyH2>
                      </StakeBodyColRight>
                    </StakeBodyRow>
                    <StakeBodyRow>
                      <StakeBodyCol>
                        <CalenderIcn src={usdt1} loading="lazy" alt="" />
                      </StakeBodyCol>
                      <StakeBodyColRight>
                        <StakeBodyH1>{t('Product.tvl')}</StakeBodyH1>
                        <StakeBodyH2>{p.tvl} USDT</StakeBodyH2>
                      </StakeBodyColRight>
                    </StakeBodyRow>
                </StakeBody>
                <StakeFooter>
                  <StakeButton onClick={() => handleDepositClick(p.id)}>{t('Stake.stake')}</StakeButton>
                </StakeFooter>
              </StackBox>
            </StackCol>
          )
        })}

      {account && (
        <StyledTableFix>
          <table style={{ color: '#fff' }}>
            <thead style={{ display: isMobile ? 'none': ''}}>
              <tr>
                <th scope="col">{t('Stake.StakeAsset')}</th> <th scope="col">{t('Stake.APY')}</th>{' '}
                <th scope="col">{t('Stake.LockAt')}</th> <th scope="col">{t('Stake.Unlock')}</th>{' '}
                <th scope="col">{t('Stake.reward')}</th> <th scope="col">{t('Stake.action')}</th>
              </tr>
            </thead>
            <tbody>
            {userStakes !== null && userStakes !== undefined ? userStakes.map((stakeRow: any, i: string) => (
            <tr><td style={{ fontWeight: 'bold'}}><span style={{float: 'left', display: isMobile ? 'block' : 'none'}}>{t('Stake.StakeAsset')}</span>{stakeRow.locked}</td><td><span style={{float: 'left', display: isMobile ? 'block' : 'none'}}>{t('Stake.APY')}</span>{stakeRow.APY}</td><td><span style={{float: 'left', display: isMobile ? 'block' : 'none'}}>{t('Stake.LockAt')}</span> {moment(stakeRow.startTime).local().format("YYYY/MM/DD HH:mm")}</td><td ><span style={{float: 'left', display: isMobile ? 'block' : 'none'}}>{t('Stake.Unlock')}</span>{moment(stakeRow.releaseTime).local().format("YYYY/MM/DD HH:mm")}</td><td style={{ fontWeight: 'bold'}}><span style={{float: 'left', display: isMobile ? 'block' : 'none'}}>{t('Stake.reward')}</span>{stakeRow.reward}</td><td><span style={{float: 'left', display: isMobile ? 'block' : 'none'}}>{t('Stake.action')}</span> { nowMs > stakeRow.releaseTime && stakeRow.released === false ? (<ButtonPrimary onClick={() => handleWithdrawClick(stakeRow.index, stakeRow.locked, stakeRow.reward)}>{t('Stake.btnwithdraw')}</ButtonPrimary>):( <span>{stakeRow.released ? '❌ ' : '✅'}</span>)}</td></tr>)) : (<StyledLoadingWrapper>{t('Stake.loading')}</StyledLoadingWrapper>)}
            </tbody>
          </table>
        </StyledTableFix>
      )}
    </StackRowSection>

      <>
        <StakingModal
          isOpen={showStakingModal}
          onDismiss={() => setShowStakingModal(false)}
          userLiquidityUnstaked={userLiquidityUnstaked}
          selectId={selectId}
        />
        <UnstakingModal
          isOpen={showUnstakingModal}
          onDismiss={() => setShowUnstakingModal(false)}
          unstakeId={unstakeId}
          staked={staked}
          claim={claim}
        />
      </>


    <SwapBg src={swapBackground} alt=""></SwapBg>
    <Certik>
        <Protection>
          <Protectionimg src={Pro_img} loading="lazy" alt="" />
          <CircleButtonInside src={circle1} loading="lazy" alt="" className="circle-button-inside"/>
          <CircleButtonInside2 src={circle1} loading="lazy" alt="" className="circle-button"/>
        </Protection>
        <Dfplogo style={{display: 'inline-flex', justifyContent: 'flex-end'}} />
        <DownloadLink style={{display: 'inline-flex', justifyContent: 'flex-end'}} href="https://www.certik.org/projects/mulanfinance" target="_blank">
          <DownloadImg src={downloadImg} loading="lazy" alt="" />
        </DownloadLink>
      </Certik>

    </StakeContainer>
  )
}


const StyledDepLogo = styled.img`
  height: 90%;
  margin-right: 10px;
  display: block;
`
