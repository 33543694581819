import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

interface ProductTagProps {
  type: "new" | "rare"
}

const ProductTag = ({
  type
}: ProductTagProps) => {
  const {t, i18n} = useTranslation()
  return (
    <StyledContainer className={type}>
      {
        type == "new" ? t("Status.new") : t("Status.rare")
      }
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
    padding: 3px 13px;
    font-size: 11px;
    border-radius: 5px 0;
    box-sizing: border-box;
    width: fit-content;
    margin-right: 4px;
    font-weight: bold;
    &.new {
        background-color: rgb(203, 240, 205);
        color: rgb(3, 173, 10);
    }
    &.rare {
        background: linear-gradient(
            149deg
            , rgb(255, 203, 0) 0%, rgb(255, 237, 169) 43%, rgb(255, 214, 81) 72%, rgb(255, 234, 152) 100%);
    }
`

export default ProductTag
