import {TokenAmount} from '@uniswap/sdk'
import {useActiveWeb3React} from 'hooks/index'
import {useTokenBalance} from 'state/wallet/hooks'
import {MUSD} from '../constants'

export function useMusdBalance (): TokenAmount | undefined {
  const {account} = useActiveWeb3React()

  return useTokenBalance((account as string), MUSD)
}
