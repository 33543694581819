import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type CountdownProps = {
  format?: string
  timestamp: number | string
}

function Countdown ({ timestamp, format } : CountdownProps) {
  const { t } = useTranslation()
  const [count, setCount] = useState(0)
  const [displayTimes, setDisplayTimes] = useState('00:00:00')

  useEffect(() => {
    setCount(Number(timestamp) - Date.now())
  }, [timestamp])

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        const end = moment(Number(timestamp))
        const c = moment(new Date())
        const diff = end.diff(c)
        const diffDuration = moment.duration(diff)

        const days = diffDuration.days()
        const hours = String(diffDuration.hours()).padStart(2, '0')
        const minutes = String(diffDuration.minutes()).padStart(2, '0')
        const seconds = String(diffDuration.seconds()).padStart(2, '0')

        let result = `${hours}:${minutes}:${seconds}`
        if (days > 0) {
          result = `${days} ${t('Product.days')} ${result}`
        }
        setCount(count => count - 1)
        setDisplayTimes(result)
      }, 1000)
    }
  }, [count])

  return (
    <StyledTimes>
      {displayTimes}
    </StyledTimes>
  )
}

const StyledTimes = styled.span`
  // position: absolute;
  // left: 0;
  // bottom: 0;
  // border-radius: 100rem;
  // border-size: 1px;
  // border-style: solid;
  // border-color: blue;
  // font-size: 14px;
  // color: #fff;
  // padding: 0.2rem 0.4rem;
  // background: rgba(0, 0, 0, 0.4);
`

export default Countdown
