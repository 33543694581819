import { createClient } from "urql";

const APIURL = "https://api.studio.thegraph.com/query/9162/mulan-market-mainnet/0.0.1";

const client = createClient({
  url: APIURL,
});

//skip； 跳过多少条记录
//first: 接着取得多少条记录
//orderBy: "price" / "id"
//orderDirection: "asc" / "desc"

//获得所有订单，包括撤销和完成的
export const ql_get_all_orders = async (skip, first) => {
  const q = `
    query {
        orderInfos(skip:${skip}, first:${first}){
            id
            seller
            NFTContract
            tokenId
            currentState
            targetToken
            price
        }
    }
    `;
  let result = await client.query(q, {}, {requestPolicy: "network-only"}).toPromise();
  return result.data.orderInfos;
};

//获得所有在售的订单
export const ql_get_all_orders_valid = async (skip, first, orderBy, orderDirection) => {
  const q = `
      query {
          orderInfos(skip:${skip}, first:${first}, where: {currentState: "ListPrice"}, orderBy:${orderBy}, orderDirection:${orderDirection}){
              id
              seller
              NFTContract
              tokenId
              currentState
              targetToken
              price
          }
      }
      `;
  let result = await client.query(q, {}, {requestPolicy: "network-only"}).toPromise();
  return result.data.orderInfos;
};

//获得所有用户的订单
export const ql_get_user_all = async (skip, first, user) => {
  const q = `
      query {
          orderInfos(skip:${skip}, first:${first}, where: {seller: "${user}"}{
              id
              seller
              NFTContract
              tokenId
              currentState
              targetToken
              price
          }
      }
      `;
  let result = await client.query(q, {}, {requestPolicy: "network-only"}).toPromise();
  return result.data.orderInfos;
};

//获得所有用户在售的订单
export const ql_get_user_all_valid = async (skip, first, user, orderBy, orderDirection) => {
  const q = `
      query {i
          orderInfos(skip:${skip}, first:${first}, where: {seller: "${user}", currentState: "ListPrice"},  orderBy:${orderBy}, orderDirection:${orderDirection}){
              id
              seller
              NFTContract
              tokenId
              currentState
              targetToken
              price
          }
      }
      `;
  let result = await client.query(q, {}, {requestPolicy: "network-only"}).toPromise();
  return result.data.orderInfos;
};

//获得所有交易的历史记录
export const ql_get_nft_history = async (nftContract, tokenId) => {
  const q = `
      query {
        nftmarketHistories(where:{NFTContract:"${nftContract}", tokenId:${tokenId}}){
            orderId
            NFTContract
            tokenId
            action
            seller
            buyer
            timeStamp
            targetToken
            price
          }
      }
      `;
  let result = await client.query(q, {}, {requestPolicy: "network-only"}).toPromise();
  return result.data.nftmarketHistories;
};

//获得所有用户购买的订单
export const ql_get_nft_purchased = async (buyer) => {
  const q = `
      query {
        nftmarketHistories(where:{buyer:"${buyer}"}){
          orderId
          NFTContract
          tokenId
          action
          seller
          buyer
          timeStamp
          targetToken
          price
        }
      }
      `;
  let result = await client.query(q, {}, {requestPolicy: "network-only"}).toPromise();
  return result.data.nftmarketHistories;
};
