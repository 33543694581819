import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../../theme'

const InfoCard = styled.button<{ active?: boolean }>`
  // background-color: ${({ theme, active }) => (active ? theme.bg3 : theme.bg2)};
  // padding: 1rem;
  // outline: none;
  // border: 1px solid;
  // border-radius: 12px;
  // width: 100% !important;
  // &:focus {
  //   box-shadow: 0 0 0 1px ${({ theme }) => theme.primary1};
  // }
  // border-color: ${({ theme, active }) => (active ? 'transparent' : theme.bg3)};
  border:0;
  background: transparent;
`

const OptionCard = styled(InfoCard as any)`
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  // margin-top: 0rem;
  // padding: 10px 20px;
  // height: 63px;
  // width: 100%;
  // cursor: pointer;
  // :hover {
  //   background-color: rgba(0, 0, 0, 0.4);
  // }
`

const OptionCardLeft = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  justify-content: center;
  height: 100%;
`

const OptionCardClickable = styled(OptionCard as any)<{ clickable?: boolean }>`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
width: 100%;
margin-bottom: 5px;
padding: 10px 20px;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
-webkit-transition: all 200ms ease;
transition: all 200ms ease;
cursor: pointer;
:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
`
const GreenCircle = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: center;
  align-items: center;

  &:first-child {
    height: 8px;
    width: 8px;
    margin-right: 8px;
    background-color: ${({ theme }) => theme.green1};
    border-radius: 50%;
  }
`

const CircleWrapper = styled.div`
  color: ${({ theme }) => theme.green1};
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderText = styled.div`
margin-top: 5px;
margin-bottom: 0px;
color: #fff;
font-size: 14px;
line-height: 14px;
font-weight: 400;
text-align: left;

`

const SubHeader = styled.div`
margin-top: 5px;
margin-bottom: 0px;
color: #fff;
font-size: 12px;
line-height: 14px;
font-weight: 400;
width: auto !important;
`

const IconWrapper = styled.div<{ size?: number | null }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '24px')};
    width: ${({ size }) => (size ? size + 'px' : '24px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`
const LinkRow = styled.a`
// display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     width: 100%;
//     margin-bottom: 5px;
//     padding: 10px 20px;
//     -webkit-box-pack: start;
//     -webkit-justify-content: flex-start;
//     -ms-flex-pack: start;
//     justify-content: flex-start;
//     -webkit-box-align: center;
//     -webkit-align-items: center;
//     -ms-flex-align: center;
//     align-items: center;
//     -webkit-transition: all 200ms ease;
//     transition: all 200ms ease;
     cursor: pointer;
padding: 0;
outline: 0;
    text-decoration: none;
`
const TokenImgCustom = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 44px;
    height: 44px;
    margin-right: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
`
const TokenImg = styled.img`
width: 90%;
`

export default function Option({
  link = null,
  clickable = true,
  size,
  onClick = null,
  color,
  header,
  subheader = null,
  icon,
  active = false,
  id
}: {
  link?: string | null
  clickable?: boolean
  size?: number | null
  onClick?: null | (() => void)
  color: string
  header: React.ReactNode
  subheader: React.ReactNode | null
  icon: string
  active?: boolean
  id: string
}) {
  const content = (
    <>
    <OptionCardClickable id={id} onClick={onClick} clickable={clickable && !active} active={active}>
        <TokenImgCustom>
          <TokenImg src={icon} alt={'Icon'} />
        </TokenImgCustom>
        <HeaderText id="head">
          {active ? (
            <CircleWrapper>
              <GreenCircle>
                <div />
              </GreenCircle>
            </CircleWrapper>
          ) : (
            ''
          )}
          {header}
        {subheader && <SubHeader>{subheader}</SubHeader>}
        </HeaderText>
    </OptionCardClickable> 
    </>)
  if (link) {
    return <LinkRow id="linkrow" href={link}>{content}</LinkRow>
  }

  return content
}
