import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair, JSBI } from '@uniswap/sdk'
import { Link } from 'react-router-dom'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { useTranslation } from 'react-i18next'

import FullPositionCard from '../../components/PositionCard'
import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { StyledInternalLinkImport, ExternalLink, TYPE, HideSmall } from '../../theme'
import { Text } from 'rebass'
import Card from '../../components/Card'
import { RowBetween, RowFixed } from '../../components/Row'
import { ButtonPrimary, ButtonSecondary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { Dots } from '../../components/swap/styleds'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
import { useStakingInfo } from '../../state/stake/hooks'
import { BIG_INT_ZERO } from '../../constants'
import pageWrapImg from '../../assets/images/Liquidity-background.jpg'
import Pro_img from '../../assets/img/shell.png'
import circle1 from "../../assets/img/circle-green.svg"
import downloadImg from "../../assets/img/audit-by-certik.svg"
import Dfplogo from 'components/Dfplogo'

const PageWrapper = styled.div`
position: relative;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    padding-top: 130px;
    padding-bottom: 130px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #212121;
    -o-object-fit: cover;
    object-fit: cover;
    @media screen and (max-width: 991px){
        margin-right: 0px;
        padding-left: 0px;
        border-radius: 0px;
    }
    @media screen and (max-width: 767px){
        overflow: hidden;
        min-height: auto;
        margin-right: 0px;
        padding-top: 150px;
        padding-right: 0px;
        padding-bottom: 150px;
    }
    @media screen and (max-width: 479px){
    overflow: hidden;
    padding-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -o-object-fit: cover;
    object-fit: cover;
}
`
const PageWrapperImg = styled.img`
position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    display: block;
    max-height: 100%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -o-object-fit: cover;
    object-fit: cover;
`

const VoteCard = styled.div`
position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    text-align: left;
    @media screen and (max-width: 991px){
        width: auto;
        margin-top: 0px;
        margin-right: 30px;
        margin-left: 30px;
    }
    @media screen and (max-width: 767px){
    width: auto;
    padding-right: 0px;
}
@media screen and (max-width: 479px){
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding: 20px 30px 30px 20px;
}
`
const LiquidityRow = styled.div`
min-width: 800px;
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.3);
    webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    @media screen and (max-width: 991px){
    width: 100%;
    min-width: auto;
}
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const ButtonRow = styled(RowFixed)`
position: absolute;
left: auto;
top: auto;
right: 5%;
bottom: 12%;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-top: 0px;
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-right: 5px;
margin-left: 0px;
padding: 12px 30px;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
border-radius: 40px;
background-color: #0099f9;
-webkit-transition: all 200ms ease;
transition: all 200ms ease;
color: #fff;
font-weight: 700;
text-decoration: none;
`
const ButtonList = styled.div`
position: -webkit-sticky;
    position: sticky;
    left: auto;
    top: auto;
    right: 0%;
    bottom: 12%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: left;
    @media screen and (max-width: 767px){
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
`
const ButtonLinks = styled.a`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 5px;
    margin-left: 5px;
    padding: 12px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #fff;
    border-radius: 40px;
    background-color: transparent;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
    :hover {
      -webkit-transform: translate(0px, -10px);
      -ms-transform: translate(0px, -10px);
      transform: translate(0px, -10px);
  }
`
const ButtonOutline = styled.a`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 5px;
    margin-left: 0px;
    padding: 12px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #305cfa;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
    :hover {
      -webkit-transform: translate(0px, -10px);
      -ms-transform: translate(0px, -10px);
      transform: translate(0px, -10px);
  }
    `
const ButtonCreate = styled.div`
font-size: 14px;
    line-height: 14px;
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TokenCode = styled.h1`
margin-top: 0px;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
`
const TokenPera = styled.p`
color: #fff;
    line-height: 16px;
    margin-bottom: 10px;
    font-size: 14px;
    margin-top: 0px;
`
const PoolText = styled.p`
position: relative;
    z-index: 1;
    margin-top: 40px;
    color: #fff;
    font-size: 13px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 10px;
`
const ImportLink = styled.a`
color: #fff;
`
const TypeConnect = styled.p`
color: #fff;
font-size: 13px;
text-align: center;
`
const Certik = styled.div`
position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    text-align: right;
    @media screen and (max-width: 479px){
      right: 20px;
      bottom: 50px;
      display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`
const Protection = styled.div`
position: absolute;
    top: -20px;
    right: -10px;
    z-index: 3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 0px;
    perspective: 0px;
`
const Protectionimg = styled.img`
  width: 20px;
  -webkit-perspective: 0px;
  perspective: 0px;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);

`
const CircleButtonInside = styled.img`
  position: absolute;
  width: 120%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 100%;
}
`
const CircleButtonInside2 = styled.img`
  position: absolute;
  width: 140%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 120%;
}
`
const DownloadLink = styled.a`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 50px;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  @media screen and (max-width: 479px){
    height: 42px;
}
`
const DownloadImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
  max-width: none;
`

export default function Pool() {
  const {t} = useTranslation()
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const hasV1Liquidity = useUserHasLiquidityInAllTokens()

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter(pool => JSBI.greaterThan(pool.stakedAmount.raw, BIG_INT_ZERO))
  const stakingPairs = usePairs(stakingInfosWithBalance?.map(stakingInfo => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter(v2Pair => {
    return (
      stakingPairs
        ?.map(stakingPair => stakingPair[1])
        .filter(stakingPair => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  return (
    <>
      <PageWrapper>
       <SwapPoolTabs active={'pool'} />
        <VoteCard id="one">
          {/* <CardBGImage />
          <CardNoise /> */}
          <LiquidityRow id="one2">
            {/* <AutoColumn id="one3">
              <RowBetween id="one4"> */}
                <TokenCode>{t('Liquidity provider')}</TokenCode>
              {/* </RowBetween>
              <RowBetween> */}
                <TokenPera>{t('liq')}</TokenPera>
              {/* </RowBetween>
            </AutoColumn> */}
          </LiquidityRow>

          <LiquidityRow>

                <TokenCode>
                  {t('Your liquidity')}
                </TokenCode>
              <ButtonList id="jijcd">
                <ButtonLinks as={Link} to="/create/">
                  {t('Create a pair')}
                </ButtonLinks>
                <ButtonOutline
                  id="join-pool-button"
                  as={Link}
                  to="/add/"
                >

                  {t('Add Liquidity')}

                </ButtonOutline>
                <ButtonOutline
                  id="join-pool-buttonv2"
                  as={Link}
                  to="/add/0x52a8FcbdCC29E924e86C562E4B36fE6f470bD516/0xdAC17F958D2ee523a2206206994597C13D831ec7"
                >

                  {t('Add Liquidity V2')}

                </ButtonOutline>
              </ButtonList>

          </LiquidityRow>

          <>
            {!account ? (
                <TypeConnect>{t('Connect')}</TypeConnect>
            ) : v2IsLoading ? (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  <Dots>{t('Loading')}</Dots>
                </TYPE.body>
              </EmptyProposals>
            ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
              <>

                {v2PairsWithoutStakedAmount.map(v2Pair => (
                  <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                ))}
                {stakingPairs.map(
                  (stakingPair, i) =>
                    stakingPair[1] && ( // skip pairs that arent loaded
                      <FullPositionCard
                        key={stakingInfosWithBalance[i].stakingRewardAddress}
                        pair={stakingPair[1]}
                        stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                      />
                    )
                )}
              </>
            ) : (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  {t('No liquidity found')}
                </TYPE.body>
              </EmptyProposals>
            )}
          </>
        </VoteCard>
        <PoolText>
            {hasV1Liquidity ? 'Uniswap V1 liquidity found!' : t("Don't see a pool you joined?")}{' '}
            <ImportLink id="import-pool-link" as={Link} to={hasV1Liquidity ? '/migrate/v1' : '/find'}>
              {hasV1Liquidity ? 'Migrate now.' : t('Import it')}
            </ImportLink>
          </PoolText>

          <PageWrapperImg src={pageWrapImg} alt="" />
          <Certik>
            <Protection>
              <Protectionimg src={Pro_img} loading="lazy" alt="" />
              <CircleButtonInside src={circle1} loading="lazy" alt="" className="circle-button-inside"/>
              <CircleButtonInside2 src={circle1} loading="lazy" alt="" className="circle-button"/>
            </Protection>
            <Dfplogo style={{display: 'inline-flex', justifyContent: 'flex-end'}} />
            <DownloadLink style={{display: 'inline-flex', justifyContent: 'flex-end'}} href="https://www.certik.org/projects/mulanfinance" target="_blank">
              <DownloadImg src={downloadImg} loading="lazy" alt="" />
            </DownloadLink>
          </Certik>
      </PageWrapper>
    </>
  )
}

const StyledDepLogo = styled.img`
  height: 90%;
  margin-right: 10px;
  display: block;
`
