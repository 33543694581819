import { USDT_ADDRESS, MULAN_ADDRESS } from '../../constants/index'
// import { TokenAmount } from '@uniswap/sdk'
import { useUniRouterContract, useMulanStakeContract, useMulanTimelocksContract } from '../../hooks/useContract'
import { useSingleCallResult, useSingleContractMultipleData } from '../multicall/hooks'
import { ethers } from 'ethers'
import { useActiveWeb3React } from '../../hooks'
import { useState, useEffect } from 'react'
import { abi as TimeLockABI } from '../../constants/abis/mulan_timelocks.json'
import { values } from 'lodash'

//, useCallback

export interface Plan {
  id: string
  name: string
  locked: number
  days: number
  tvl: string
  sort: number
  apy: string
  active: boolean
}

// get count of all proposals made
export function usePlanCount(): number | undefined {
  const mulanstake = useMulanStakeContract()
  const res = useSingleCallResult(mulanstake, 'getProductCount')
  if (res.result && !res.loading) {
    return parseInt(res.result[0])
  }
  return 0
}

// get price of mulan
export function useMuLanPrice() {
  const uniContract = useUniRouterContract()
  // console.log('useMuLanPrice', uniContract)
  // console.log(ethers.utils.parseEther('1'))
  const inputs = ['1000000000000000000', [MULAN_ADDRESS, USDT_ADDRESS]]
  const res = useSingleCallResult(uniContract, 'getAmountsOut', inputs)
  // console.log('get ret', res)
  if (res.result && !res.loading) {
    // console.log('getxxxprice:', res.result)
    const mulanPrice = ethers.utils.formatUnits(res.result.amounts[1], 6)
    const mulanPrice1 = parseFloat(mulanPrice) + 0.17
    return mulanPrice1.toFixed(3)
  }
  return '56.3'
}

// export function useProducts(i: number) {
//   const stakeContract = useMulanStakeContract()
//   const inputs = [i]
//   const res = useSingleCallResult(stakeContract, 'products', inputs)
//   // console.log('get ret', res)
//   if (res.result && !res.loading) {
//     return res.result
//   }
//   return res.result
// }

// export function useSales(i: number) {
//   const stakeContract = useMulanStakeContract()
//   const inputs = [i]
//   const res = useSingleCallResult(stakeContract, 'sales', inputs)
//   // console.log('get ret', res)
//   if (res.result && !res.loading) {
//     return res.result
//   }
//   return res.result
// }

// get data for all past and active proposals
export function useAllPlanData() {
  const planCount = usePlanCount()
  //console.log('planCount aaaaaa', planCount)
  const stakeContract = useMulanStakeContract()
  const proposalIndexes = []
  if (planCount !== undefined) {
    for (let i = 0; i < planCount; i++) {
      proposalIndexes.push([i])
    }
  }

  const price = useMuLanPrice()
  //console.log('price xxxxxxxxxxxxx', price)

  // get all proposal entities
  const allProposals = useSingleContractMultipleData(stakeContract, 'products', proposalIndexes)
  const allProposalStates = useSingleContractMultipleData(stakeContract, 'sales', proposalIndexes)

  // TODO: fix it
  // return []
  if (allProposals) {
    return allProposals.map((p, i) => {
      //console.log('allProposals data xxxxaa:', allProposals[i]?.result?.lockDays, allProposalStates[i]?.result?.lockedTotal)
      let locked = 0
      let days = 0
      let baseAPY = 0
      if (allProposals[i]?.result?.lockDays !== undefined) {
        days = parseInt(ethers.utils.formatUnits(allProposals[i]?.result?.lockDays, 0))
      }
      if (allProposals[i]?.result?.basedAPY !== undefined) {
        baseAPY = parseInt(ethers.utils.formatUnits(allProposals[i]?.result?.basedAPY, 0))
      }
      if (allProposalStates[i]?.result?.lockedTotal !== undefined) {
        locked = parseInt(ethers.utils.formatUnits(allProposalStates[i]?.result?.lockedTotal, 18))
      }

      const formattedPlan: Plan = {
        id: i.toString(),
        name: 'MULAN',
        locked: locked,
        days: days,
        tvl: String((parseFloat(price) * locked).toFixed(2)),
        sort: i,
        apy: String(baseAPY / 100),
        active: allProposals[i]?.result?.onSale
      }
      return formattedPlan
    })
  } else {
    return []
  }
}

export function usePlanData(id: string | undefined): Plan | undefined {
  const allPlanData = useAllPlanData()
  return allPlanData?.find(p => p.id === id)
}

export function useStakedList(account: any) {
  const { library } = useActiveWeb3React()
  const [formattedEvents, setFormattedEvents] = useState<any>()

  const timeLockContract = useMulanTimelocksContract()
  const filter = {
    ...timeLockContract?.filters?.['LockCreated'](account),
    fromBlock: 0,
    toBlock: 'latest'
  }

  const filterRelease = {
    ...timeLockContract?.filters?.['Released'](account),
    fromBlock: 0,
    toBlock: 'latest'
  }
  const eventParser = new ethers.utils.Interface(TimeLockABI)

  useEffect(() => {
    async function fetchData() {
      const pastEvents = await library?.getLogs(filter)
      const pastEventsRelease = await library?.getLogs(filterRelease)
      const formattedEventData = pastEvents
        ?.map((event, i) => {
          const values = eventParser.parseLog(event).args
          const YEAR_IN_SECONDS = 365 * 24 * 60 * 60
          const row = {
            index: i,
            locked: (values.value / 10 ** 18).toFixed(4),
            reward: (values.reward / 10 ** 18).toFixed(4),
            startTime: values.startTime * 1000,
            releaseTime: values.releaseTime * 1000,
            APY:
              (Math.round(
                (values.reward / values.value) * (YEAR_IN_SECONDS / (values.releaseTime - values.startTime)) * 10000
              ) /
                10000) *
                100 +
              '%',
            released: false
          }
          return row
        })
        .reverse()

      const releaseEventData = pastEventsRelease
        ?.map(event => {
          const values = eventParser.parseLog(event).args
          return values.lockNumber.toNumber()
        })
        .reverse()

      for (const i in formattedEventData) {
        const row = formattedEventData[parseInt(i)]
        if (releaseEventData !== undefined && releaseEventData.includes(row.index)) {
          // console.log('getiisreleaes', row.index)
          formattedEventData[parseInt(i)].released = true
        }
      }
      // console.log('fetchData', JSON.stringify(formattedEventData))
      setFormattedEvents(formattedEventData)
    }
    // !formattedEvents &&
    if (!formattedEvents && account) {
      try {
        const ua = navigator.userAgent
        if (ua.includes('imToken')) {
          fetch('https://mulandefi.finance/api/event?account=' + account)
          .then(response => response.json())
          .then(data =>  setFormattedEvents(data.data))
        } else {
          fetchData()
        }
      } catch (error) {
        alert(error)
      }
    }
  }, [eventParser, filter, library, formattedEvents, filterRelease, account])

  return formattedEvents
}
