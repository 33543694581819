import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import HeroBanner from 'components/Header/HeroBanner'
import img1 from 'assets/gif/img1.gif'
import img2 from 'assets/images/Para-2.jpg' 
import img3 from 'assets/images/Para-3.jpg' 
import img4 from 'assets/images/Para-4.jpg' 
import img5 from 'assets/images/Para-5.jpg' 
import img6 from 'assets/images/Para-6.jpg' 
import defiBG from 'assets/images/Defi-background.jpg'
import defiPara1 from 'assets/images/Defi-para-1.jpg'
import defiPara2 from 'assets/images/Defi-para-2.jpg'
import defiPara3 from 'assets/images/Defi-para-3.jpg'
// import footerImg from 'assets/images/Footer1.jpg'
// import footerLogo from 'assets/images/mulan-defi-logo-white.svg'
// import walletCircle from 'assets/img/Connect-to-a-wallet.svg'
// import walletIcon from 'assets/img/circle-green.svg'

const SecondHalf = styled.div`
position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-height: 45vw;
    margin-bottom: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: transparent;
    @media screen and (max-width: 991px){
        min-height: auto;
        padding-right: 10px;
        padding-left: 10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
`
const BoxHalf = styled.div`
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 49.5vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -o-object-fit: cover;
    object-fit: cover;
    @media screen and (max-width: 991px){
    width: 100%;
    height: 80vw;
}
    @media screen and (max-width: 479px){
    width: 100%;
    height: auto;
    min-height: 100vw;
    margin-bottom: 10px;
}
`
const BoxHalf2 = styled.div`
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 49.5vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 42px;
    @media screen and (max-width: 991px){
      width: 100%;
      height: 80vw;
  }
    @media screen and (max-width: 479px){
      width: 100%;
      height: 100vw;
      min-height: 100vw;
      margin-bottom: 10px;
  }
`
const TextLeft = styled.div`
padding: 50px;
@media screen and (max-width: 479px){
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}
`
const Header = styled.h1`
position: relative;
z-index: 1;
margin-top: 0px;
margin-bottom: 0px;
font-family: acumin-pro-extra-condensed, sans-serif;
color: #fff;
font-size: 36px;
line-height: 50px;
font-weight: 900;
@media screen and (max-width: 479px){
    color: #fff;
    font-size: 30px;
    line-height: 40px;
}
`
const Header2 = styled.h2`
position: relative;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: 40px;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #fff;
    font-size: 18px;
    line-height: 18px;
    font-weight: 900;
`
const Text = styled.p`
position: relative;
z-index: 1;
margin-top: 40px;
color: #969595;
font-size: 13px;
line-height: 30px;
font-weight: 400;
@media screen and (max-width: 479px){
    color: hsla(0, 0%, 100%, 0.63);
    line-height: 30px;
    letter-spacing: 1.1px;
}
`
const Images = styled.img`  
width: 100%;
height: 100%;
`

const HeroSection = styled.div`
position: relative;
z-index: 0;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
overflow: hidden;
width: 100%;
min-height: 100vh;
padding-top: 0px;
padding-bottom: 0px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
background-color: #212121;
-o-object-fit: cover;
object-fit: cover;
margin-bottom: 30px;
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
border-radius: 42px;
`
const HeroImg = styled.img`
position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    display: block;
    max-height: 100%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -o-object-fit: cover;
    object-fit: cover;
    opacity:1;
`
const BoxContainer = styled.div`
position: absolute;
    left: 0%;
    top: 30%;
    right: 0%;
    bottom: 30%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    backdrop-filter: blur(20px);
`
const GeneralH1 = styled.h1`
position: relative;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: acumin-pro-extra-condensed, sans-serif;
    color: #fff;
    font-size: 36px;
    line-height: 50px;
    font-weight: 900;
    color: #0099f9;
    text-align: center;
    text-transform: uppercase;
`

// const Footer = styled.div`
// position: relative;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     height: 500px;
//     min-height: auto;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -webkit-flex-direction: column;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     -webkit-box-pack: center;
//     -webkit-justify-content: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -webkit-align-items: center;
//     -ms-flex-align: center;
//     align-items: center;
//     border-radius: 42px;
//     background: url(${footerImg}) no-repeat;
//     background-position: 50% 50%;
//     background-size: cover;
//     background-clip: border-box;
//     width:100%;
// `

// const  FooterContainer = styled.div`
// display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     width: 900px;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -webkit-flex-direction: column;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     -webkit-box-pack: center;
//     -webkit-justify-content: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -webkit-align-items: center;
//     -ms-flex-align: center;
//     align-items: center;
// `
// const  FooterLogo = styled.div`
// position: relative;
//     right: auto;
//     width: 200px;
//     margin-bottom: 20px;
// `
// const  FooterImg = styled.img`
// position: absolute;
//     left: 160px;
//     top: 10px;
//     width: 36px;
// `
// const FooterH1 = styled.h1`
// margin-top: 0px;
//     margin-bottom: 0px;
//     font-family: acumin-pro-extra-condensed, sans-serif;
//     color: #fff;
//     font-size: 70px;
//     line-height: 60px;
//     font-weight: 900;
// `
// const FooterH2 = styled.h2`
// margin-top: 0px;
//     margin-bottom: 0px;
//     font-family: acumin-pro-extra-condensed, sans-serif;
//     color: #0099f9;
//     font-size: 70px;
//     line-height: 60px;
//     font-weight: 900;
// `
// const FooterBoard = styled.div`
// display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     width: 100%;
//     padding-top: 20px;
//     padding-bottom: 20px;
//     -webkit-box-orient: horizontal;
//     -webkit-box-direction: normal;
//     -webkit-flex-direction: row;
//     -ms-flex-direction: row;
//     flex-direction: row;
//     -webkit-box-pack: center;
//     -webkit-justify-content: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -webkit-align-items: center;
//     -ms-flex-align: center;
//     align-items: center;
//     border-bottom: 1px solid #000;
// margin-bottom: 20px;
//     border-top: 0.5px solid #646464;
//     border-bottom-width: 0.5px;
//     border-bottom-color: #646464;
// `
// const FooterLinks = styled.a`
// margin-right: 20px;
//     margin-left: 20px;
//     color: #fff;
//     text-decoration: none;
// `
// const Web3StatusConnect = styled.div`
// position: relative;
// display: -webkit-box;
// display: -webkit-flex;
// display: -ms-flexbox;
// display: flex;
// height: 40px;
// margin-right: 0px;
// margin-left: 5px;
// padding-right: 20px;
// padding-left: 50px;
// -webkit-box-pack: center;
// -webkit-justify-content: center;
// -ms-flex-pack: center;
// justify-content: center;
// -webkit-box-align: center;
// -webkit-align-items: center;
// -ms-flex-align: center;
// align-items: center;
// border-radius: 50px;
// background-color: #77a50f;
// text-decoration: none;
// background-color: #77a50f;
// border: 1px solid #77a50f;
// color: #fff;
// `
// const WalletIcon = styled.img`
//   position: absolute;
//   left: 9px;
//   width: 25px;
//   margin-right: 0px;
//   opacity: 1;
// `
// const WalletIconCircle = styled.img`
// position: absolute;
// left: -2px;
// width: 45px;
// max-width: none;
// `
// const TextConnect = styled.p`
// display: -webkit-box;
// display: -webkit-flex;
// display: -ms-flexbox;
// display: flex;
// height: 45px;
// -webkit-box-orient: vertical;
// -webkit-box-direction: normal;
// -webkit-flex-direction: column;
// -ms-flex-direction: column;
// flex-direction: column;
// -webkit-box-pack: center;
// -webkit-justify-content: center;
// -ms-flex-pack: center;
// justify-content: center;
// -webkit-box-align: center;
// -webkit-align-items: center;
// -ms-flex-align: center;
// align-items: center;
// color: #fff;
// font-size: 13px;
// line-height: 13px;
// text-decoration: none;
// cursor:pointer;
// `

const Home = () => {
  const { t } = useTranslation()
  return (
    <>
    <HeroBanner/>
      <SecondHalf id="learnmoreh">
        <BoxHalf>
          <TextLeft>
            <Header>{t('h1')}</Header>
            <Text>{t('d1')}</Text>
          </TextLeft>
        </BoxHalf>
        <BoxHalf2>
          <Images src={img1} alt="" />
        </BoxHalf2>
      </SecondHalf>

      <SecondHalf id="learnmore" className="oposite">
        <BoxHalf2>
          <Images src={img2} alt="" />
        </BoxHalf2>
        <BoxHalf>
          <TextLeft>
            <Header>{t('h2')}</Header>
            <Text>
              {t('mulan1a')} <br />
              {t('mulan1b')} <br />
              {t('mulan1c')} <br />
              {t('mulan1d')} <br />
            </Text>
          </TextLeft>
        </BoxHalf>
      </SecondHalf>

      <SecondHalf id="learnmore">
        <BoxHalf>
          <TextLeft>
            <Header>{t('h3')}</Header>
            <Text>
              <b>{t('mulan2')}</b>
              {t('mulan2a')}
              <br />
              <b>{t('mulan2b')}</b>
              {t('mulan21')}
              <br />
              <b>{t('mulan22')}</b>
              {t('mulan3')}
              <br />
              <b>{t('mulan4')}</b>
              {t('mulan5')}
              <br />
              <b>{t('mulan6')}</b>
              {t('mulan7')}
              <br />
            </Text>
          </TextLeft>
        </BoxHalf>
        <BoxHalf2>
          <Images src={img3} alt="" />
        </BoxHalf2>
      </SecondHalf>

      <SecondHalf id="learnmore" className="oposite">
        <BoxHalf2>
          <Images src={img4} alt="" />
        </BoxHalf2>
        <BoxHalf>
          <TextLeft>
            <Header>{t('h4')}</Header>
            <Text>{t('mulan8')}</Text>
            <b>{t('mulansa')}</b> <br />
            {t('mulansb')}
            <br />
            <br />
            <b>{t('mulansc')}</b> <br />
            {t('mulansd')}
            <br />
            <br />
            <b>{t('mulanse')}</b> <br />
            {t('mulansf')}
            <br />
            <br />
            <b>{t('mulansg')}</b> <br />
            {t('mulansh')}
            <br />
            <br />
          </TextLeft>
        </BoxHalf>
      </SecondHalf>

      <SecondHalf id="learnmore2">
        <BoxHalf>
          <TextLeft>
            <Header>{t('h5')}</Header>
            <Text>{t('mulan9')}</Text>
            <Text>{t('mulan9a')}</Text>
            <Text>{t('mulan9b')}</Text>
            <Text>{t('mulan9c')}</Text>
            <Text>{t('mulan9d')}</Text>
            <Text>{t('mulan9e')}</Text>
          </TextLeft>
        </BoxHalf>
        <BoxHalf2>
          <Images src={img5} alt="" />
        </BoxHalf2>
      </SecondHalf>

      {/* <SecondHalf id="learnmore" className="oposite">
        <BoxHalf2>
          <Images src={img6} alt="" />
        </BoxHalf2>
        <BoxHalf>
          <TextLeft>
            <Header>{t('h6')}</Header>
            <Text>{t('mulan10')}</Text>
          </TextLeft>
        </BoxHalf>
      </SecondHalf> */}

      <HeroSection id="hero">
        <HeroImg src={defiBG} loading="lazy" sizes="100vw"  alt=""/>
        <BoxContainer>
          <GeneralH1>{t('h7')}</GeneralH1>
        </BoxContainer>
      </HeroSection>

      <SecondHalf id="learnmore3">
        <BoxHalf>
          <TextLeft>
            <Header>{t('h8')}</Header>
            <Header2>{t('h28')}</Header2>
            <Text>{t('mulan11')}</Text>
          </TextLeft>
        </BoxHalf>
        <BoxHalf2>
          <Images src={defiPara1} alt=""/>
        </BoxHalf2>
      </SecondHalf>

      <SecondHalf id="learnmore4" className="oposite">
        <BoxHalf2>
          <Images src={defiPara2} alt=""/>
        </BoxHalf2>
        <BoxHalf>
          <TextLeft>
            <Header>{t('h9')}</Header>
            <Header2>{t('h29')}</Header2>
            <Text>{t('mulan12')}</Text>
          </TextLeft>
        </BoxHalf>
      </SecondHalf>

      <SecondHalf id="learnmore5">
        <BoxHalf>
          <TextLeft>
            <Header>{t('h10')}</Header>
            <Header2>{t('h210')}</Header2>
            <Text>{t('mulan13')}</Text>
          </TextLeft>
        </BoxHalf>
        <BoxHalf2>
          <Images src={defiPara3} alt=""/>
        </BoxHalf2>
      </SecondHalf>

      {/* <Footer>
      <FooterContainer>
          <FooterLogo>
            <FooterImg src={footerLogo} loading="lazy" alt="" />
            <FooterH1 >MULAN</FooterH1>
            <FooterH2 >FINANCE</FooterH2>
          </FooterLogo>
          <FooterBoard>
            <FooterLinks href="index.html" >Home</FooterLinks>
            <FooterLinks href="swap.html">交換</FooterLinks>
            <FooterLinks href="liquidity.html">プール</FooterLinks>
            <FooterLinks href="stake.html">ステーキング</FooterLinks>
            <FooterLinks href="farm.html">ファーミング</FooterLinks>
            <FooterLinks href="chart.html">チャート</FooterLinks>
          </FooterBoard>
          <Web3StatusConnect>
            <WalletIcon src={walletCircle} loading="lazy" alt="" className="nav-link-icon" />
            <WalletIconCircle src={walletIcon} loading="lazy" alt="" className="circle-button-connect" />
            <TextConnect>Connect to a wallet</TextConnect>
          </Web3StatusConnect>
      </FooterContainer>
      </Footer> */}

    </>
  )
}

export default Home
