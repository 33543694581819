import React, {useMemo} from 'react'
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'

export default function MuiThemeProvider ({children}: {children: React.ReactNode}) {

  const theme = useMemo(() => {
    return createMuiTheme({
      palette: {
        primary: {
          // main: '#305cfa',
          main: '#4D8FEA'
        },
        type: 'dark'
      },
    })
  }, [])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
