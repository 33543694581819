import React, {useState, useEffect} from 'react'
import {mapToken, shortenAddress} from 'utils'
import Avatar from '@material-ui/core/Avatar'
import {bidsResponse} from 'types'
import {NavLink} from 'react-router-dom'
import {useTranslation, Trans, Translation} from 'react-i18next'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import {formatBalanceToDisplay} from 'utils/parseBignumber'
import {MUSD} from '../../constants'
import {timestampToTime} from 'utils'

type BidsProps = {
  loading: boolean
  data: bidsResponse[]
}

export default function Bids ({loading, data}: BidsProps) {
  let {t, i18n} = useTranslation()

  return (
    <List>
      {
        data.map((item, i) => {
          const token = mapToken(item.targetToken)
          return (
            <ListItem alignItems="flex-start" key={i}>
              <ListItemAvatar>
                <Avatar alt="" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <span>{t('Product.bidPlaced')} </span>
                    <span style={{fontWeight: 900}}>{formatBalanceToDisplay(item.price, token.decimals)} {token.symbol}</span>
                  </>
                }
                secondary={
                  <span>
                    <span style={{
                      color: '#999',
                      marginRight: '6px'
                    }}>by</span>
                    <NavLink to={'/profile/onsale/' + item.bider}>{shortenAddress(item.bider)}</NavLink>
                    <span> at {timestampToTime(item.timeStamp)}</span>
                  </span>
                }
              />
            </ListItem>
          )
        })
      }
    </List>
  )
}
