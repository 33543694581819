import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { Grid, Button, TextField, CircularProgress } from '@material-ui/core'
import Tokeninput from '../TokenInput'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment from 'moment'
import {formatToMiniUnit} from 'utils/parseBignumber'
import BigNumber from 'bignumber.js'
import { createAuctionAPI } from 'apis'
import {NFTItemType} from 'types'
import {MUSD} from '../../constants'
import {useActiveWeb3React} from 'hooks/index'

type AddAuctionModalProps = {
  modalTitle: string
  onConfirm: () => void
  onDismiss?: () => void
  product: NFTItemType
}

const TODAY = moment(Date.now())
const TOMORROW = moment(Date.now() + 24 * 3600 * 1000)

function AddAuctionModal({ product, modalTitle, onDismiss = () => {}, onConfirm }: AddAuctionModalProps) {
  const {account, chainId, library} = useActiveWeb3React()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const [val, setVal] = useState('')
  const [tokenKey, setTokenKey] = useState('')
  const [selectedDateStr, setSelectedDateStr] = useState(TOMORROW.format('YYYY/MM/DD HH:mm'))
  const [selectedDate, setSelectedDate] = useState(TOMORROW)

  const valBigNumber = new BigNumber(val)
  const dateValid = selectedDate.isAfter(TODAY)

  function handleChange (value: string, tokenkey: string) {
    setVal(value)
    setTokenKey(tokenkey)
  }

  // 日期选择
  function handleDateChange (date: string | moment.Moment) {
    if (typeof date !== 'string') {
      setSelectedDate(date)
      setSelectedDateStr(date.format('YYYY/MM/DD HH:mm'))
    }
  }
  // 日期输入框
  function handleDateInput (e: React.ChangeEvent<HTMLInputElement>) {
    const m = moment(e.target.value)
    setSelectedDate(m)
    setSelectedDateStr(m.format('YYYY/MM/DD HH:mm'))
  }

  function validDate (currentDate: moment.Moment) {
    return currentDate.isAfter(TODAY)
  }

  // 提交数据
  async function submit () {
    if (!account) {
      return Promise.reject(t('Missing account'))
    }
    const formData = new FormData()
    formData.append('NFTContract', product.NFTContract)
    formData.append('seller', account)
    formData.append('tokenId', product.tokenId)
    formData.append('targetToken', tokenKey)
    formData.append('price', formatToMiniUnit(val, MUSD.decimals))
    formData.append('expiration', String(selectedDate.valueOf()))

    await createAuctionAPI(formData)
    await onConfirm()
    onDismiss()
    return Promise.resolve(1)
  }

  return (
    <Dialog open={true} onClose={onDismiss} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
      <DialogContent>
        {/* 信息 start */}
        <StyledItemContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <DialogContentText>{t("Publish.UploadedImage")}:</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DialogContentText>
                <div style={{
                  display: product.resource.image ? 'block' : 'none'
                }}>
                  <img id="publish_preview_img" src={product.resource.image} style={{ width: '250px', height: 'auto' }} />
                </div>
              </DialogContentText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DialogContentText>{t("Publish.Category")}:</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DialogContentText>{product.resource.category}</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DialogContentText>{t("Publish.Title")}:</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DialogContentText>{product.resource.name}</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DialogContentText>{t("Publish.JATitle")}:</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DialogContentText>{product.resource.jaName}</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DialogContentText>{t("Publish.ZHTitle")}:</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DialogContentText>{product.resource.zhName}</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DialogContentText>{t("Publish.Description")}:</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DialogContentText><div style={{
                whiteSpace: 'pre-wrap'
              }}>{product.resource.description}</div></DialogContentText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DialogContentText>{t("Publish.JADescription")}:</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DialogContentText><div style={{
                whiteSpace: 'pre-wrap'
              }}>{product.resource.jaDescription}</div></DialogContentText>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DialogContentText>{t("Publish.ZHDescription")}:</DialogContentText>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DialogContentText><div style={{
                whiteSpace: 'pre-wrap'
              }}>{product.resource.zhDescription}</div></DialogContentText>
            </Grid>
          </Grid>
        </StyledItemContent>
        {/* 信息 end */}
        {/* 填写内容 start */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Tokeninput
              onChange={handleChange}
              value={val}
              tokenkey={tokenKey}
              inputTitle={t('Publish.Price')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              margin="dense"
              label={t('Publish.Date')}
              fullWidth
              value={selectedDateStr}
              onChange={handleDateInput}
              placeholder={t('helper.afterToday')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledDatetimeBox>
              <Datetime
                value={selectedDate}
                onChange={handleDateChange}
                dateFormat="YYYY/MM/DD"
                timeFormat="HH:mm"
                isValidDate={validDate}
                open={true}
                inputProps={{
                  style: {
                    display: 'none',
                  }
                }}
                className="date-time"
              />
            </StyledDatetimeBox>
          </Grid>
        </Grid>
        {/* 填写内容 end */}

      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="primary" size="large" disabled={isLoading}>
          {t("Publish.Close")}
        </Button>
        <Button
          disabled={isLoading || !valBigNumber.isFinite() || valBigNumber.eq(0) || !dateValid}
          variant="contained"
          onClick={async () => {
            setIsLoading(true)
            await submit().catch(err => err)
            setIsLoading(false)
          }}
          color="primary"
          size="large"
        >
          {isLoading && <CircularProgress size={24} />}{t('Publish.Submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const StyledItemContent = styled.div`
`
const StyledDatetimeBox = styled.div`
  .date-time {
    .rdtPicker {
      background: transparent;
    }

    .rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover {
      background: rgb(104, 104, 104);
    }
    .rdtPicker thead tr:first-of-type th:hover {
      background: rgb(104, 104, 104);
    }
    .rdtCounter .rdtBtn:hover {
      background: rgb(104, 104, 104);
    }
  }
`

export default AddAuctionModal
