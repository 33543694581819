import { CurrencyAmount, JSBI, Token, Trade } from '@uniswap/sdk'
import React, { useCallback, useContext, useEffect, useMemo,useRef, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween,RowFixed } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'
// import SwapHeader from '../../components/swap/SwapHeader'
import { useTranslation } from 'react-i18next'
import { INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { getTradeVersion } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency, useDefaultTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance, useUserSingleHopOnly } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { isTradeBetter } from 'utils/trades'
import swapBackground from '../../assets/images/Swap-background.jpg'
import auditIcon from '../../assets/images/Certik-icon.svg'
import walletCircle from 'assets/svg/shell.svg'
import walletIcon from 'assets/svg/circle-green.svg'
import Pro_img from 'assets/svg/shell.svg'
import circle1 from "assets/svg/circle-green.svg"
import downloadImg from "assets/img/audit-by-certik.svg"

// swap header
//import Settings from 'components/Settings'
import circleImg from '../../assets/svg/circle-animation.svg'
import blueMulanImg from '../../assets/svg/blue-mulan.svg'
import swich_icn from 'assets/svg/exchange-icon.svg'

// swap settings

import { Settings, X } from 'react-feather'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen } from '../../state/application/hooks'
import { useUserTransactionTTL } from '../../state/user/hooks'
 import Modal from '../../components/Alerts'
import QuestionHelper from '../../components/QuestionHelper'
import Toggle from '../../components/Toggle'
import TransactionSettings from '../../components/TransactionSettings'
import settingImg from '../../assets/svg/Setting-icon.svg'
import popupicn from '../../assets/svg/close_icon.svg'
import Dfplogo from 'components/Dfplogo'


export default function Swap() {
  const loadedUrlParams = useDefaultsFromURLSearch()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in default list
  const defaultTokens = useDefaultTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !Boolean(token.address in defaultTokens)
    }).length > 0

  const { account } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }
  const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
  const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]

  const betterTradeLinkV2: Version | undefined =
    toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade) ? Version.v2 : undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  //const [singleHopOnly] = useUserSingleHopOnly()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: t('swap'),
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade)
          ].join('/')
        })

        ReactGA.event({
          category: 'Routing',
          action: singleHopOnly ? 'Swap with multihop disabled' : 'Swap with multihop enabled'
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [
    priceImpactWithoutFee,
    swapCallback,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  // settings code :

const node = useRef<HTMLDivElement>()
const open = useModalOpen(ApplicationModal.SETTINGS)
const toggle = useToggleSettingsMenu()

//const theme = useContext(ThemeContext)
const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

const [ttl, setTtl] = useUserTransactionTTL()

const [expertMode, toggleExpertMode] = useExpertModeManager()

//const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()

// show confirmation view before turning on
const [showConfirmation, setShowConfirmation] = useState(false)

useOnClickOutside(node, open ? toggle : undefined)

  const { t } = useTranslation()

  // css for swap


  return (
    <SwapFull id="swap-full">
    <SwapFull2 id="swapnew">
      {/* <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning && importTokensNotInDefault}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      /> */}
      <SwapPoolTabs active={'swap'} />
      <AppBodySwap id="swapnew3">
        {/* <SwapHeader /> */}
        <StyledSwapHeader>
      {/* <RowBetween> */}
        <SwapHead>{t('swap')}</SwapHead>
        <SwapText>Choose your tokens</SwapText>
        {/* <Settings /> */}

    <StyledMenuSwap ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper id="heyyyaa">
          <>
            <HeaderExpert>
              <AlertHeader>
              <AlertText>
                {t('Are you sure?')}
              </AlertText>
              {/* <StyledCloseIcon  /> */}

              <PopupCloseIcon onClick={() => setShowConfirmation(false)}>
                <PopupCloseImg src={popupicn} alt="" />
              </PopupCloseIcon>
              </AlertHeader>
            </HeaderExpert>
            {/* <Break /> */}
            <AlertBody>
              <AlertText>
                {t('Expert')}
              </AlertText>
              <AlertText>
                {t('ONLY')}
              </AlertText>
            </AlertBody>
            <AlertFooter>
            <ButtonError
                error={true}
                onClick={() => {
                  if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === 'confirm') {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}>
                <div id="confirm-expert-mode">
                  {t('turn')}
                </div>
              </ButtonError>
            </AlertFooter>
          </>
        </ModalContentWrapper>
      </Modal>
      <StyledMenuButtonSwap onClick={toggle} id="open-settings-dialog-button">
        {/* <StyledMenuIcon /> */}
        <SettingPera className="settings-text">Settings</SettingPera>
        <SettingImg src={settingImg} loading="lazy" alt="" className="settings-img"></SettingImg>
        {expertMode ? (
          <>
            {/* <span role="img" aria-label="wizard-icon">
              🧙
            </span> */}
          </>
        ) : null}
      </StyledMenuButtonSwap>

    </StyledMenuSwap>

        <SwapMulanContainer className="img-container-mulan">
            <CircleContainer className="circle-container">
              <CircleButtn src={circleImg} loading="lazy" alt="" className="circle-button" />
              <BlueMulanImg src={blueMulanImg} loading="lazy" alt="" className="img-absolute-mulan-blue" />
              <CircleButtn2 src={circleImg} loading="lazy" alt="" className="circle-button-inside" />
            </CircleContainer>
        </SwapMulanContainer>
      {/* </RowBetween> */}
    </StyledSwapHeader>
        {/* <Separator /> */}
        <SwapWrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <SwapAutoColumn>

            <CurrencyInputPanel
              label={independentField === Field.OUTPUT && !showWrap && trade ? t('From (estimated)') : t('From')}
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
              showCommonBases
            />

            <>
              <AutoRow justify={isExpertMode ? 'space-between' : 'center'}>
                <ExchangeIcon onClick={() => {
                      setApprovalSubmitted(false) // reset 2 step UI for approvals
                      onSwitchTokens()
                    }}>
                  <Switcher src={swich_icn} alt="" />
                </ExchangeIcon>
                  {/* <ArrowDown
                    size="16"
                    onClick={() => {
                      setApprovalSubmitted(false) // reset 2 step UI for approvals
                      onSwitchTokens()
                    }}
                    color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.text2}
                  />
                </ArrowWrapper> */}
                {recipient === null && !showWrap && isExpertMode ? (
                  <SwapHead id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                    + Add a send (optional)
                  </SwapHead>
                ) : null}
              </AutoRow>
            </>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={independentField === Field.INPUT && !showWrap && trade ? t('To (estimated)') : t('To')}
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
              showCommonBases
            />

            {recipient !== null && !showWrap ? (
              <>
              <AddExtra>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.text2} />
                  </ArrowWrapper>
                  <SwapHead id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                    - Remove send
                  </SwapHead>
                </AutoRow>

              </AddExtra>
              <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
              </>
            ) : null}

            {showWrap ? null : (
              <Card padding={showWrap ? '.25rem 1rem 0 1rem' : '0px'} borderRadius={'20px'}>
                <AutoColumn gap="8px" style={{ padding: '0 16px' }}>
                  {Boolean(trade) && (
                    <RowBetween align="center">
                      <Text fontWeight={500} fontSize={14} color={theme.text2}>
                        {t('Price')}
                      </Text>
                      <TradePrice
                        price={trade?.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                    </RowBetween>
                  )}
                  {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                    <SlippageText>
                      <SwapText onClick={toggleSettings}>
                        Slippage Tolerance
                      </SwapText>
                      <SwapText onClick={toggleSettings}>
                        {allowedSlippage / 100}%
                      </SwapText>
                    </SlippageText>
                  )}
                </AutoColumn>
              </Card>
            )}
          </SwapAutoColumn>
          <ButtonGroupSwap id="btngroup">
          <ButtonRowList>
            {swapIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
              </ButtonPrimary>
            ) : !account ? (

          <ConnectWallet>
            <WalletIcon src={walletCircle} loading="lazy" alt="" className="nav-link-icon" />
            <WalletIconCircle src={walletIcon} loading="lazy" alt="" className="circle-button-connect" />
            <TextConnect onClick={toggleWalletModal}>{t('Connect to a wallet')}</TextConnect>
          </ConnectWallet>

              // <ButtonLight ></ButtonLight>

              ) : showWrap ? (
              <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                {wrapInputError ??
                  (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
              </ButtonPrimary>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <GreyCard style={{ textAlign: 'center' }}>
                <TYPE.main mb="4px">{t('Insufficient liquidity for this trade')}</TYPE.main>
                {singleHopOnly && <TYPE.main mb="4px">{t('Try enabling multi-hop trades')}</TYPE.main>}
              </GreyCard>
            ) : showApproveFlow ? (
              <RowBetween>
                <ButtonConfirmed
                  onClick={approveCallback}
                  disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                  width="48%"
                  altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                  confirmed={approval === ApprovalState.APPROVED}
                >
                  {approval === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      {t('Approving')} <Loader stroke="white" />
                    </AutoRow>
                  ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                    t('Approved')
                  ) : (
                      currencies[Field.INPUT]?.symbol + t('Approve')
                  )}
                </ButtonConfirmed>
                {/* <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap()
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined
                      })
                    }
                  }}
                  width="48%"
                  id="swap-button"
                  disabled={
                    !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                  }
                  error={isValid && priceImpactSeverity > 2}>
                  <Text fontSize={16} fontWeight={500}>
                    {priceImpactSeverity > 3 && !isExpertMode
                      ?t(`Price Impact Too High`)
                      :t(`swap${priceImpactSeverity > 2 ? 'Anyway' : ''}`)}
                  </Text>
                </ButtonError> */}
              </RowBetween>
            ) : (
              <ButtonError
                onClick={() => {
                  if (isExpertMode) {
                    handleSwap()
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined
                    })
                  }
                }}
                id="swap-button"
                disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                <Text fontSize={20} fontWeight={500}>
                  {swapInputError
                    ? swapInputError
                    : priceImpactSeverity > 3 && !isExpertMode
                    ? t(`Price Impact Too High`)
                    : t(`swap${priceImpactSeverity > 2 ? 'Anyway' : ''}`)}
                </Text>
              </ButtonError>
            )}
            {showApproveFlow && (
              <Column style={{ marginTop: '1rem' }}>
                <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
              </Column>
            )}
            {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
            {betterTradeLinkV2 && !swapIsUnsupported && toggledVersion === Version.v1 ? (
              <BetterTradeLink version={betterTradeLinkV2} />
            ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
              <DefaultVersionLink />
            ) : null}

            <AuditImg src={auditIcon} alt=""></AuditImg>

            </ButtonRowList>
            <SwapFooterText>Secure Connection audit by certik.org</SwapFooterText>
          </ButtonGroupSwap>
        </SwapWrapper>
      </AppBodySwap>

    {open && (
      <MenuFlyout id="swapnew4">
        {/* <AutoColumn gap="md" style={{ padding: '1rem' }}> */}

        <StyledSwapHeader>
            <SwapHead>{t('Transaction')}</SwapHead>
        </StyledSwapHeader>

          <TransactionSettings
            rawSlippage={userSlippageTolerance}
            setRawSlippage={setUserslippageTolerance}
            deadline={ttl}
            setDeadline={setTtl}
          />

          <SwapAutoColumnInterface id="new2">
            <SlipTitle>
            {t('Interface')}
            </SlipTitle>

            <InterfaceCombine>
            <InterfaceH1>
            {t('Toggle')}
            </InterfaceH1>
            {/* <QuestionHelper text={t('Bypasses')} /> */}
            <Toggle
              id="toggle-expert-mode-button"
              isActive={expertMode}
              toggle={
                expertMode
                  ? () => {
                      toggleExpertMode()
                      setShowConfirmation(false)
                    }
                  : () => {
                      toggle()
                      setShowConfirmation(true)
                    }
              }
            />
            </InterfaceCombine>
            <InterfaceCombine>
            <InterfaceH1>
            {t('Disable')}
            </InterfaceH1>
            {/* <QuestionHelper text={t('Restricts')} /> */}
            <Toggle
              id="toggle-disable-multihop-button"
              isActive={singleHopOnly}
              toggle={() => (singleHopOnly ? setSingleHopOnly(false) : setSingleHopOnly(true))}
            />

            </InterfaceCombine>

          </SwapAutoColumnInterface>

        {/* </AutoColumn> */}
      </MenuFlyout>
    )}
    </SwapFull2>
    {!swapIsUnsupported ? (
        <AdvancedSwapDetailsDropdown trade={trade} />
      ) : (
        <UnsupportedCurrencyFooter show={swapIsUnsupported} currencies={[currencies.INPUT, currencies.OUTPUT]} />
      )}
    <SwapBg src={swapBackground} alt=""></SwapBg>
    <Certik>
        <Protection>
          <Protectionimg src={Pro_img} loading="lazy" alt="" />
          <CircleButtonInside src={circle1} loading="lazy" alt="" className="circle-button-inside"/>
          <CircleButtonInside2 src={circle1} loading="lazy" alt="" className="circle-button"/>
        </Protection>
        <Dfplogo style={{display: 'inline-flex', justifyContent: 'flex-end'}} />
        <DownloadLink style={{display: 'inline-flex', justifyContent: 'flex-end'}} href="https://www.certik.org/projects/mulanfinance" target="_blank">
          <DownloadImg src={downloadImg} loading="lazy" alt="" />
        </DownloadLink>
      </Certik>
    </SwapFull>
  )
}


const SwapFull = styled.div`
  position: relative;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    padding-top: 130px;
    padding-bottom: 130px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #212121;
    -o-object-fit: cover;
    object-fit: cover;
    @media screen and (max-width: 991px){
    margin-right: 10px;
    padding-left: 10px;
    border-radius: 0px;
}
@media screen and (max-width: 767px){
    overflow: hidden;
    min-height: auto;
    margin-right: 0px;
    padding-top: 150px;
    padding-right: 10px;
    padding-bottom: 150px;
}
@media screen and (max-width: 479px){
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -o-object-fit: cover;
    object-fit: cover;
}
  `
const SwapFull2 = styled.div`
  position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    // max-width: 900px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.34);
    text-align: left;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    @media screen and (max-width: 991px){
      width: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }
@media screen and (max-width: 767px){
    width: 100%;
    min-height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
@media screen and (max-width: 479px){
    width: 100%;
    margin-top: 0vh;
    margin-bottom: auto;
    padding: 0px;
}
    `

const SwapBg = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    display: block;
    max-height: 100%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -o-object-fit: cover;
    object-fit: cover;
    opacity: 1;
    `
const SwapWrapper = styled.div`
    width:100%;
    `
const SwapAutoColumn = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 479px){
      padding-right: 20px;
      padding-left: 20px;
    }
    `
const ButtonGroupSwap = styled.div`
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
const ButtonRowList = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @media screen and (max-width: 479px){
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
    `

const AuditImg = styled.img`
    width: 100px;
    opacity: 0.41;
    @media screen and (max-width: 479px){
    display: none;
}
    `
const ConnectWallet = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    margin-right: 0px;
    margin-left: 5px;
    padding-right: 20px;
    padding-left: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50px;
    background-color: #305cfa;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    text-decoration: none;
    cursor: pointer;
    :hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
  }
    `
const WalletIcon = styled.img`
    position: absolute;
    margin-right: 0px;
    opacity: 1;
    left: 11px;
    width: 20px;
`
const WalletIconCircle = styled.img`
position: absolute;
left: -2px;
width: 45px;
max-width: none;
`
const TextConnect = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 45px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    font-size: 13px;
    line-height: 13px;
    text-decoration: none;
`
const SwapFooterText = styled.p`
    color: hsla(0, 0%, 100%, 0.41);
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    @media screen and (max-width: 479px){
    display: none;
}
`

// swap header css

const StyledSwapHeader = styled.div`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
width: 100%;
height: auto;
padding: 30px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-align: start;
-webkit-align-items: flex-start;
-ms-flex-align: start;
align-items: flex-start;
@media screen and (max-width: 479px){
    padding-right: 20px;
    padding-left: 20px;
}
`
const SwapHead = styled.p`
margin-bottom: 5px;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    margin-top: 0px;
    `

const SwapText = styled.p`
    color: #fff;
    line-height: 14px;
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 14px;
    @media screen and (max-width: 479px){
      font-size: 12px;
      line-height: 12px;
    }
    `
const SwapMulanContainer = styled.div`
    position: absolute;
    left: 0px;
    top: -35px;
    right: 0px;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    width: 70px;
    height: 70px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.3);
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    `
const CircleContainer = styled.div`
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
const CircleButtn = styled.img`
    position: absolute;
    z-index: 3;
    width: 140%;
    max-width: none;
    `
const CircleButtn2 = styled.img`
    position: absolute;
    width: 120%;
    max-width: none;
    `
const BlueMulanImg = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    display: block;
    max-height: 50%;
    max-width: none;
    min-height: 50%;
    min-width: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -o-object-fit: cover;
    object-fit: cover;
    `

// swap settingss css

const StyledMenuIcon = styled(Settings)`
  height: 20px;
  width: 20px;

  > * {
    stroke: ${({ theme }) => theme.text3};
  }
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  /* background-color: ${({ theme }) => theme.bg3}; */

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    /* background-color: ${({ theme }) => theme.bg4}; */
  }

  svg {
    margin-top: 2px;
  }
`
const StyledMenuButtonSwap = styled.div`
    position: absolute;
    top: 30px;
    right: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    background:transparent;
    `

const SettingPera = styled.p`
    margin-right: 10px;
    margin-bottom: 0px;
    color: #fff;
    line-height: 14px;
    margin-top: 0px;
    font-size: 14px;
    `

const SettingImg = styled.img`
    width: 25px;
    cursor: pointer;
    `

const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`
const StyledMenuSwap = styled.div`

`

const MenuFlyout = styled.div`
position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 500px;
    height: auto;
    min-height: 100%;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 30px;
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    @media screen and (max-width: 767px){
      width:100%;
    }
    @media screen and (max-width: 479px){
      width:100%;
    }
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

const ModalContentWrapper = styled.div`
position: relative;
    overflow: hidden;
    width: 400px;
    height: auto;
    padding-bottom: 51px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.39);
`

const SwapAutoColumnInterface = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 95%;
    margin-bottom: 10px;
    padding: 5px 30px 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.17);
`
const SlipTitle = styled.p`
position: relative;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
    color: #fff;
    font-size: 13px;
    line-height: 30px;
    font-weight: 400;
`
const InterfaceH1 = styled.h1`
margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
`
const InterfaceCombine = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    padding-top: 5px;
    padding-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
`

const Certik = styled.div`
position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    @media screen and (max-width: 479px){
    right: 20px;
    bottom: 50px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}
`
const Protection = styled.div`
position: absolute;
    top: -20px;
    right: -10px;
    z-index: 3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 0px;
    perspective: 0px;
`
const Protectionimg = styled.img`
  width: 20px;
  -webkit-perspective: 0px;
  perspective: 0px;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);

`
const CircleButtonInside = styled.img`
  position: absolute;
  width: 120%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 100%;
}
`
const CircleButtonInside2 = styled.img`
  position: absolute;
  width: 140%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 120%;
    max-width: none;
}
`
const DownloadLink = styled.a`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 50px;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  @media screen and (max-width: 479px){
    height: 42px;
}
`
const DownloadImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
  max-width: none;
`
const SlippageText = styled.div`
margin-top: 20px;
    display: flex;
    justify-content: space-between;
`
const HeaderExpert = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding: 15px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
`
const AlertHeader = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
const AlertBody = styled.div`
    position: relative;
    z-index: 0;
    display: block;
    overflow: auto;
    height: auto;
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -o-object-fit: fill;
    object-fit: fill;
    `
const AlertText = styled.p`
    color: #fff;
    margin-top: 0;
    margin-bottom: 15px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    `
const AlertFooter = styled.div`
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 6vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    `
const PopupCloseIcon = styled.div`
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 0.5px;
    border-color: hsla(0, 0%, 96.1%, 0.12);
    border-radius: 35px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;
    `
const PopupCloseImg = styled.img`
      border-radius: 35px;
    `
const AddExtra = styled.div`
   width: 100%;
   padding: 10px 0px;
   cursor: pointer;
   `
const AppBodySwap = styled.div`
   position: relative;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   width: 500px;
   height: auto;
   min-height: 100%;
   padding: 0px;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-align-self: auto;
   -ms-flex-item-align: auto;
   align-self: auto;
   -webkit-box-flex: 0;
   -webkit-flex: 0 auto;
   -ms-flex: 0 auto;
   flex: 0 auto;
   border-radius: 30px;
   padding-bottom: 130px;
   -webkit-box-pack: start;
   -webkit-justify-content: flex-start;
   -ms-flex-pack: start;
   justify-content: flex-start;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-flex: 1;
   -webkit-flex: 1;
   -ms-flex: 1;
   flex: 1;
   background-color: rgba(0, 0, 0, 0.3);
   @media screen and (max-width: 767px){
     width: 100%;
   }
   `
const ExchangeIcon = styled.a`
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   width: 80px;
   height: 50px;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   `
const Switcher = styled.img`
   width: 30%;
   `
const StyledDepLogo = styled.img`
  height: 90%;
  margin-right: 10px;
  display: block;
`
