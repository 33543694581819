import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button, TextField, CircularProgress } from '@material-ui/core'
import NumberInput from 'components/NumberInput'
import BigNumber from 'bignumber.js'
import { mapToken, computeMinimumPrice } from 'utils'
import {formatToMiniUnit, formatBalanceToDisplay, multipliedAndToBigNumber} from 'utils/parseBignumber'
import useModal from 'hooks/useModal'
import {NFTItemType} from 'types'
import { depositandBidAPI, createbidAPI } from 'apis'
import useSignForBiding from 'hooks/useSignForBiding'
import notify from 'components/Toast'
import StakeModal from 'components/StakeModal'
import {useActiveWeb3React} from 'hooks/index'
import Modal from '@material-ui/core/Modal'
import UnstakeModal from 'components/UnstakeModal'

type BidModalProps = {
  modalTitle: string
  onConfirm: () => void
  onDismiss?: () => void
  // 使用里面的价格作为起拍价，计算最低加价数值
  item: NFTItemType
  // 出价历史，获取最后一次的价格
  bidList: any[]
}

function BidModal ({ bidList, item, modalTitle, onDismiss = () => undefined, onConfirm }: BidModalProps) {
  const {account, chainId, library} = useActiveWeb3React()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const [val, setVal] = useState('')
  const signFunc = useSignForBiding()
  const [minPrice, setMinPrice] = useState(new BigNumber(0))
  // 可用余额
  const [balance, setBalance] = useState(new BigNumber(0))

  const token = mapToken(item.targetToken)

  // 查询个人的质押以及个人的bid历史
  useEffect(() => {
    depositandBidAPI((account as string), item.targetToken).then(res => {
      const stakeAmount = new BigNumber(res[0])
      const bidAmount = new BigNumber(res[1])

      const stakeBalance = stakeAmount.minus(bidAmount)
      setBalance(stakeBalance)
    })
  }, [bidList])

  useEffect(() => {
    // 最后的出价
    let lastPrice = '0'
    if (bidList[0] && bidList[0].price) {
      lastPrice = bidList[0].price
    }
    const mp = computeMinimumPrice(item.price, '0.01', lastPrice)
    setVal(formatBalanceToDisplay(mp.toString(), token.decimals))
    setMinPrice(mp)
  }, [bidList])


  // 输入框的bignumber类型的值
  const valNumber = multipliedAndToBigNumber(val, token.decimals)

  // 输入的数值需要大于历史最高价

  const isLessThan = valNumber.lt(minPrice)

  // 转为字符串
  const balanceString = useMemo(() => {
    return formatBalanceToDisplay(balance, token.decimals)
  }, [balance])

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.validity.valid) {
      setVal(e.currentTarget.value.replace(/,/g, '.'))
    }
  }, [setVal])

  const handleSelectMax = useCallback(() => {
    setVal(balanceString)
  }, [balanceString, setVal])

  async function bidAction () {
    // 获取签名
    const sign = await signFunc(item.id, item.NFTContract, item.tokenId, item.targetToken, valNumber.toString(), Math.floor(Number(item.expiration) / 1000))
    const formdata = new FormData()
    formdata.append('auctionId', item.id)
    formdata.append('bider', (account as string))
    formdata.append('price', valNumber.toString())
    formdata.append('signature', sign)

    return await createbidAPI(formdata)
  }

  // stake页面
  // const [stakeOpen, setStakeOpen] = useState(false)
  const [openStake] = useModal(<StakeModal />)
  const [openUnStake] = useModal(<UnstakeModal />)


  return (
    <Modal open={true} onClose={onDismiss} container={document.getElementById('root')}>
      <Dialog open={true} onClose={onDismiss}>
        <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent>
          <Button
            color="primary"
            title={t('If you not enough TT to place bid').replace('TT', token.symbol as string)}
            onClick={
              () => {
                openStake()
              }
            }
          >
            <div>{t('Btn.Stake')}</div>
          </Button>
          <Button
            style={{marginLeft: '1rem'}}
            color="primary"
            onClick={
              () => {
                openUnStake()
              }
            }
          >
            <div>{t('Btn.Unstake')}</div>
          </Button>
        </DialogContent>
        <DialogContent>
          <NumberInput
            max={balanceString}
            symbol={token.symbol as string}
            onChange={handleChange}
            onSelectMax={handleSelectMax}
            value={val}
            inputTitle={t('Publish.Price')}
            helpText={isLessThan ? t('Offer must be at least N-price T-symbol').replace('T-symbol', token.symbol as string).replace('N-price', formatBalanceToDisplay(minPrice.toString(), token.decimals)) : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onDismiss} disabled={isLoading}>
            {t('Btn.Cancel')}
          </Button>

          <Button
            disabled={isLoading || !valNumber.isFinite() || valNumber.eq(0) || valNumber.gt(balance) || isLessThan}
            variant="contained"
            onClick={async () => {
              setIsLoading(true)
              await bidAction().then((res: any) => {
                if (res && res[0] === false) {
                  // 失败
                  notify(t('unknow error'))
                  setIsLoading(false)
                  return Promise.reject(res[1])
                } else {
                  notify(t('Submit success'))
                  return Promise.resolve(res)
                }
              }, err => {
                console.error(err)
                setIsLoading(false)
                notify(t('unknow error'))
                return Promise.reject(err)
              })
              await onConfirm()
              setIsLoading(false)
              onDismiss()
            }}
            color="primary"
          >
            {isLoading && <CircularProgress color="inherit" size={16} />}{t('Btn.Bid')}
          </Button>
        </DialogActions>
      </Dialog>
    </Modal>
  )
}

export default BidModal

