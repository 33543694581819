import React, {useCallback, useState, useMemo, useEffect} from 'react'
import {OrderStatus, NFTItemType} from 'types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {NavLink, useParams, useHistory} from 'react-router-dom'

import BigNumber from 'bignumber.js'
import useAllowance from 'hooks/useAllowance'
import useApprove from 'hooks/useApprove'
import {Button, CircularProgress} from '@material-ui/core'
import {useActiveWeb3React} from 'hooks/index'
import notify from 'components/Toast'
import {refreshPageAfter1s} from 'utils'
import useBuyFromOrder from 'hooks/useBuyFromOrder'
import {mapToken} from 'utils'
import {ApprovalState, useApproveCallback} from 'hooks/useApproveCallback'
import {tryParseAmount} from 'state/swap/hooks'
import {formatBalanceToDisplay} from 'utils/parseBignumber'
import {ERC721_SELL_MARKET_TAXED_ADDRESS, AUCTION_DEPOSIT_ADDRESS} from '../../constants'

type TradeProps = {
  item: NFTItemType
  status: OrderStatus
  targetToken: string
  owner: string
  price: string
  acceptEvent: () => void
  bidEvent: () => void
}

export default function Trade ({
  item,
  status,
  targetToken,
  owner,
  price,
  acceptEvent = () => undefined,
  bidEvent = () => undefined,
}: TradeProps) {
  const {t} = useTranslation()
  const {account, chainId, library} = useActiveWeb3React()
  const token = mapToken(targetToken)
  const {buyAction, hash, isPending, confirmed} = useBuyFromOrder(token)
  const [attempting, setAttempting] = useState(false)
  const history = useHistory()

  // const [hash, setHash] = useState<string | undefined>()
  // let isPending = useIsTransactionPending(hash ?? '')
  function setIsPending (a: boolean) {
    // isPending = a
  }
  // const confirmed = hash && !isPending
  // const [isPending, setIsPending] = useState(false)

  // 是否是拥有者
  const isOwner = account ? (owner.toLowerCase() === account.toLowerCase()) : false

  const {tokenAllowanceOfMarket, tokenAllowanceOfAuction} = useAllowance(token)

  // 是否授权
  const [requestedApprovalMarket, setRequestedApprovalMatket] = useState(true)
  const [requestedApprovalAuction, setRequestedApprovalAuction] = useState(true)

  useEffect(() => {
    tokenAllowanceOfAuction().then(res => {
      setRequestedApprovalAuction(!res)
    })
  }, [tokenAllowanceOfAuction])

  useEffect(() => {
    tokenAllowanceOfMarket().then(res => {
      setRequestedApprovalMatket(!res)
    })
  }, [tokenAllowanceOfMarket])

  // 授权函数
  const {musdApproveToMarket, musdApproveToAuction, hash: hashApprove, isPending: isPengdingApproce, confirmed: confirmedApprove} = useApprove(token)

  function handleApproveMatket () {
    musdApproveToMarket().catch(err => {
      console.error(err)
      notify(err)
    })
  }

  // 进行授权
  function handleApproveAuction () {
    musdApproveToAuction().catch(err => {
      console.error(err)
      notify(err)
    })
  }

  function buyBtnEvent () {
    if (isOwner) {
      return
    }
    // 授权成功后，或者已经授权，则进行购买
    setAttempting(true)
    buyAction(item).catch(err => {
      setAttempting(false)
      notify(err)
      console.error(err)
    })
  }
  useEffect(() => {
    // 购买成功后跳转
    if (confirmed) {
      history.push('/profile/collectible')
    }
  }, [confirmed])

  let btn = null
  switch (status) {
    case OrderStatus.AUCTION:
      // 判断授权状态，提供授权按钮
      if (requestedApprovalAuction && !confirmedApprove) {
        btn = (<StyledButton
          onClick={handleApproveAuction}
          disabled={isPengdingApproce}
          fullWidth
          variant="contained"
          color="primary"
          disableElevation
        >{isPengdingApproce && <CircularProgress color="inherit" size={16} />}{t('Btn.Approve')}</StyledButton>)
      } else if (isOwner) {
        btn = (<StyledButton
          disabled={requestedApprovalAuction && !confirmedApprove}
          onClick={() => {acceptEvent()}}
          fullWidth
          color="primary"
          variant="contained"
          disableElevation
        >{t('Btn.Accept')}</StyledButton>)
      } else {
        btn = (<StyledButton
          disabled={requestedApprovalAuction && !confirmedApprove}
          onClick={() => {bidEvent()}}
          fullWidth
          variant="contained"
          disableElevation
          color="primary"
        >{t('Btn.Bid')}</StyledButton>)
      }
      break
    case OrderStatus.SELL:
      // 未授权则需要授权
      if (requestedApprovalMarket && !confirmedApprove) {
        btn = (<StyledButton
          onClick={handleApproveMatket}
          disabled={isPengdingApproce}
          fullWidth
          variant="contained"
          color="primary"
          disableElevation
        >{isPengdingApproce && <CircularProgress color="inherit" size={16} />}{t('Btn.Approve')}</StyledButton>)
      } else {
        btn = (<StyledButton
        disabled={isOwner || (attempting && !confirmed) || confirmed}
        onClick={buyBtnEvent}
        variant="contained"
        fullWidth
        color="primary"
        disableElevation>
          {(attempting && !confirmed) && <CircularProgress color="primary" size={24} />}{t('Btn.Buy')}
        </StyledButton>)
      }
      break
    default:
      btn = (<StyledButton disabled variant="contained" fullWidth color="primary" disableElevation>{t('Btn.Buy')}</StyledButton>)
      break
  }

  return (btn)
}

function refreshPage () {
  window.location.reload();
}

const StyledButton = styled(Button)`
  border-radius: 100rem !important;
`
