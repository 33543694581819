import React from 'react'
import ReactDOM from 'react-dom'
// import React from 'react'
import toastComponent from './toast'

let flag = 0

function toast (message?: string | {message: string}) {
  if (!document) {
    return
  }
  let toastBox = document.getElementById('toast-box')
  if (!toastBox) {
    toastBox = document.createElement('div')
    toastBox.id = 'toast-box'
    const root = (document.getElementById('root') as Element)
    root.appendChild(toastBox)
  }

  flag = flag + 1

  let text = ''
  if (typeof message === 'string') {
    text = message
  } else if (message && message.message) {
    text = message.message
  }


  ReactDOM.render(React.createElement(toastComponent, {flag, message: text}), toastBox)
}


export default toast
