import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js/bignumber'
import { useTranslation, Trans, Translation } from 'react-i18next'
import { useMusdBalance } from 'hooks/useTokenBalance'
import { mapToken } from '../../utils'
import notify from 'components/Toast'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, MenuItem, TextField, CircularProgress } from '@material-ui/core'
import NumberInput from 'components/NumberInput'
import {formatToMiniUnit, formatBalanceToDisplay, multipliedAndToBigNumber} from 'utils/parseBignumber'
import { userDepositsAllAPI } from 'apis'
import {UserDepositsAPIType} from 'types'
import {useActiveWeb3React} from 'hooks/index'
import useAuctionDeposit from 'hooks/useDeposit'
import {useIsTransactionPending} from 'state/transactions/hooks'
import {TransactionResponse} from '@ethersproject/providers'
import {useTransactionAdder} from 'state/transactions/hooks'
import {MUSD, SUPPORT_TOKEN_LIST} from '../../constants'

interface StakeModalProps {
  onDismiss?: () => void,
  onConfirm?: () => void
}

const tokenList = SUPPORT_TOKEN_LIST.filter(el => {
  return el.address.toLowerCase() === MUSD.address.toLowerCase()
})

function StakeModal ({ onDismiss = () => undefined, onConfirm = () => undefined }: StakeModalProps) {
  let { t, i18n } = useTranslation()
  const {account, chainId, library} = useActiveWeb3React()
  const [tokenkey, setTokenkey] = useState(tokenList[0].address)
  const [selectTokenName, setSelectTokenName] = useState(tokenList[0].symbol as string)
  const [val, setVal] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [stakeAmount, setStakeAmount] = useState<UserDepositsAPIType[]>([])
  const auctionDeposit = useAuctionDeposit()
  const tokenAmount = useMusdBalance()
  const tokenBalance = tokenAmount ? multipliedAndToBigNumber(tokenAmount.toSignificant(), tokenAmount.token.decimals) : new BigNumber(0)
  const [hash, setHash] = useState<string | undefined>()
  const isPending = useIsTransactionPending(hash ?? '')
  const confirmed = Boolean(hash) && !isPending
  const addTransaction = useTransactionAdder()
  const token = mapToken(tokenkey)

  function handleSelectToken (e: any) {
    const selected = mapToken(e.target.value)
    if (selected) {
      setTokenkey(selected.address)
      setSelectTokenName(selected.symbol as string)
    }
  }

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.validity.valid) {
      setVal(e.currentTarget.value.replace(/,/g, '.'))
    }
  }, [setVal])

  const handleSelectMax = useCallback(() => {
    setVal(formatBalanceToDisplay(tokenBalance, token.decimals))
  }, [tokenBalance, setVal])

  // 输入框的bignumber类型的值
  const valNumber = multipliedAndToBigNumber(val, token.decimals)

  function stakeAction () {
    if (!account) {
      return Promise.reject(t('Missing account'))
    }
    const amount = multipliedAndToBigNumber(val, token.decimals).toString()
    return auctionDeposit(tokenkey, amount).then((res: TransactionResponse) => {
      addTransaction(res, {
        summary: t('Deposit NN MUSD').replace('NN', val)
      })
      setHash(res.hash)
    }, err => {
      setIsLoading(false)
      console.error(err)
      notify(err)
    })
  }

  useEffect(() => {
    if (confirmed) {
      onConfirm()
      onDismiss()
    }
  }, [confirmed])

  useEffect(() => {
    userDepositsAllAPI((account as string), tokenList.map(el => ({key: el.address, name: el.symbol as string}))).then((res: UserDepositsAPIType[]) => {
      setStakeAmount(res)
    })
  }, [])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={true}
      onClose={onDismiss}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{t('Stake.stake')}</DialogTitle>
      <DialogContent>
        {stakeAmount.map(el => {
          return (
            <DialogContentText key={el.targetToken}>
              {formatBalanceToDisplay(new BigNumber(el.amount).minus(new BigNumber(el.used)), mapToken(el.targetToken).decimals)} {mapToken(el.targetToken).symbol}
            </DialogContentText>
          )
        })}
        <DialogContentText>
          {/* <TextField
            margin="dense"
            variant="outlined"
            select
            value={tokenkey}
            onChange={handleSelectToken}
            >
            {tokenList.map((token) =>
              <MenuItem key={token.key} value={token.key}>{token.name}</MenuItem>
            )}
          </TextField> */}
        </DialogContentText>
        <DialogContentText>
          <NumberInput
            max={formatBalanceToDisplay(tokenBalance, token.decimals)}
            symbol={selectTokenName}
            onChange={handleChange}
            onSelectMax={handleSelectMax}
            value={val}
            inputTitle={t('Stake.stake')}
          />
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} disabled={isLoading}>
          {t('Btn.Cancel')}
        </Button>
        <Button
          disabled={isLoading || !valNumber.isFinite() || valNumber.eq(0) || valNumber.gt(tokenBalance)}
          variant="contained"
          onClick={async () => {
            setIsLoading(true)
            await stakeAction()
          }}
          color="primary"
        >
          {isLoading && <CircularProgress color="inherit" size={16} />}{t('Publish.Submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StakeModal
