import {useCallback, useEffect, useState} from 'react'
import {useActiveWeb3React} from 'hooks/index'
import {MUSD} from '../constants'
import {addOrder} from '../logicUtils'
import {useMarketContract} from 'hooks/useContract'
import {useTranslation} from 'react-i18next'
import {useIsTransactionPending} from 'state/transactions/hooks'
import {TransactionResponse} from '@ethersproject/providers'
import {useTransactionAdder} from 'state/transactions/hooks'

function useAddOrder () {
  const {t} = useTranslation()
  const {account, library} = useActiveWeb3React()
  const marketContract = useMarketContract()
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const isPending = useIsTransactionPending(hash ?? '')
  const confirmed = Boolean(hash) && !isPending

  const cb = useCallback(async (ntf_address: string, tokenId: string, price: string, tokenAddress: string) => {
    if (account && library && marketContract) {

      const signer = library.getSigner(account)
      const tx: TransactionResponse = await addOrder(marketContract, ntf_address, tokenId, tokenAddress, price, signer)
      addTransaction(tx, {
        summary: t('Resell #TokenId').replace('TokenId', tokenId)
      })
      setHash(tx.hash)
      return tx
    } else {
      return Promise.reject(t('Missing account'))
    }
  }, [account, library, marketContract])

  return {addOrder: cb, hash, isPending, confirmed}
}

export default useAddOrder
