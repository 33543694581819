import {useCallback} from 'react'
import {useActiveWeb3React} from 'hooks/index'
import {provider} from 'web3-core'
import Web3 from 'web3'
import {useTranslation} from 'react-i18next'


function useSignForBiding () {
  const ethereum = window.ethereum
  const {t} = useTranslation()
  const {account} = useActiveWeb3React()
  const web3 = new Web3(ethereum as provider)

  const cb = useCallback(async (auctionId: string | number, NFTContract: string, tokenId: string | number, targetToken: string, price: string, expiration: number): Promise<string> => {
    if (account) {
      let message = web3.utils.soliditySha3(auctionId, NFTContract, tokenId, targetToken, price, expiration)
      const sign = await web3.eth.personal.sign((message as string), account, (null as any))
      return sign
    } else {
      return Promise.reject(t('Missing account'))
    }
  }, [account])

  return cb
}

export default useSignForBiding
