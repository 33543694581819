const users = [
  '0xfb0358dfd89163670521b2acc004a0a1e60ca48e',
  '0x457a592f066fd66a76c485053360dd3d88489769',
  '0x7b98cd70497ce6ea3ae01f55809016c1ec8df5c3',
  '0x8f0e2028ab47435eea14bba3d2123c9697cd3bd6',
  '0xB809CA3D508994249DE416D8c36b2F0482BF9cC6'
]

const list = users.map(el => {
  return el.toLowerCase()
})

export default list
