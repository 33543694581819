import React, { useState, useRef, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import QuestionHelper from '../QuestionHelper'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'

import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh'
}

enum DeadlineError {
  InvalidInput = 'InvalidInput'
}

const FancyButton = styled.button`
 
  
`

const SlipPageButtons = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    @media screen and (max-width: 479px){
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
`

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
  background-color: rgba(0, 0, 0, 0.19);
  background-color: ${({ active, theme }) => active && theme.primary1};
  box-shadow: ${({ active, theme }) => (active ? '0 16px 26px -8px #000' : null)};
  color: ${({ active, theme }) => (active ? theme.white : theme.text1)};
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 5px;
    margin-left: 5px;
    padding: 12px 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 100%, 0);
    border-radius: 10px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    white-space: nowrap;
    @media screen and (max-width: 479px){
    margin-bottom: 10px;
    }
`
const Input = styled.input`
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-left: 5px;
    padding: 12px 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 100%, 0.2);
    border-radius: 10px;
    background-color: transparent;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    height:38px;
    font-size: 14px;
    vertical-align: middle;
    :focus {
      border-color: #3898EC;
      outline: 0;
    }
    @media screen and (max-width: 479px){
    margin-left: 0px;
    }
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
background: transparent;
border:0;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 0px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-flex: 0;
-webkit-flex: 0 0 auto;
-ms-flex: 0 0 auto;
flex: 0 0 auto;
`

const SlippageEmojiContainer = styled.span`
  color: #f3841e;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;  
  `}
`
const SlipTitle = styled.p`
position: relative;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
    color: #fff;
    font-size: 13px;
    line-height: 30px;
    font-weight: 400;
`
const ErrorMessage =  styled.p`
position: relative;
    z-index: 1;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #e2c000;
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;
`
const SwapAutoColumn = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 95%;
    margin-bottom: 10px;
    padding: 5px 30px 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.17);
`
const Minute_text = styled.p`
    position: relative;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
    color: #fff;
    font-size: 13px;
    line-height: 30px;
    font-weight: 400;

`

export interface SlippageTabsProps {
  rawSlippage: number
  setRawSlippage: (rawSlippage: number) => void
  deadline: number
  setDeadline: (deadline: number) => void
}

export default function SlippageTabs({ rawSlippage, setRawSlippage, deadline, setDeadline }: SlippageTabsProps) {
  const theme = useContext(ThemeContext)

  const inputRef = useRef<HTMLInputElement>()

  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')
  const { t } = useTranslation()

  const slippageInputIsValid =
    slippageInput === '' || (rawSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (deadline / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && rawSlippage < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && rawSlippage > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  function parseCustomSlippage(value: string) {
    setSlippageInput(value)

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
      if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
        setRawSlippage(valueAsIntFromRoundedFloat)
      }
    } catch {}
  }

  function parseCustomDeadline(value: string) {
    setDeadlineInput(value)

    try {
      const valueAsInt: number = Number.parseInt(value) * 60
      if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
        setDeadline(valueAsInt)
      }
    } catch {}
  }

  return (
    <>
    <SwapAutoColumn>
      {/* <AutoColumn gap="sm"> */}
        <RowFixed>
          {/* <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
            {t('Slippage')}
          </TYPE.black> */}
          <SlipTitle>
            {t('Slippage')}
          </SlipTitle>
          {/* <QuestionHelper text={t('Your transaction unfavorably')} /> */}
        </RowFixed>
        <SlipPageButtons>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(10)
            }}
            active={rawSlippage === 10}
          >
            0.1%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(50)
            }}
            active={rawSlippage === 50}
          >
            0.5%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(100)
            }}
            active={rawSlippage === 100}
          >
            1%
          </Option>
          <OptionCustom active={![10, 50, 100].includes(rawSlippage)} warning={!slippageInputIsValid} tabIndex={-1}>
            {/* <RowBetween> */}
              {!!slippageInput &&
              (slippageError === SlippageError.RiskyLow || slippageError === SlippageError.RiskyHigh) ? (
                <SlippageEmojiContainer>
                  <span role="img" aria-label="warning">
                    ⚠️
                  </span>
                </SlippageEmojiContainer>
              ) : null}
              {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
              <Input
                ref={inputRef as any}
                placeholder="_ _ %"
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((rawSlippage / 100).toFixed(2))
                }}
                onChange={e => parseCustomSlippage(e.target.value)}
                color={!slippageInputIsValid ? 'red' : ''}
              />
              
            {/* </RowBetween> */}
          </OptionCustom>
          </SlipPageButtons>
        {!!slippageError && (
          // <RowBetween
          //   style={{
          //     fontSize: '14px',
          //     paddingTop: '7px',
          //     color: slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E'
          //   }}
          // >
          <ErrorMessage>
            {slippageError === SlippageError.InvalidInput
              ? 'Enter a valid slippage percentage'
              : slippageError === SlippageError.RiskyLow
              ? 'Your transaction may fail'
              : 'Your transaction may be frontrun'}
          </ErrorMessage>
          //  </RowBetween> 
        )}
      {/* </AutoColumn> */}

      </SwapAutoColumn>

<SwapAutoColumn>
      {/* <AutoColumn gap="sm"> */}
        <RowFixed>
            <SlipTitle>
            {t('Transaction deadline')}
            </SlipTitle>
          {/* <QuestionHelper text={t('Your transaction')} /> */}
        </RowFixed>
        <SlipPageButtons>
        <RowFixed>
          <OptionCustom style={{ width: '80px' }} tabIndex={-1}>
            <Input
              color={!!deadlineError ? 'red' : undefined}
              onBlur={() => {
                parseCustomDeadline((deadline / 60).toString())
              }}
              placeholder={(deadline / 60).toString()}
              value={deadlineInput}
              onChange={e => parseCustomDeadline(e.target.value)}
            />
          </OptionCustom>
          <Minute_text>
            {t('minutes')}
          </Minute_text>
        </RowFixed>
      {/* </AutoColumn> */}
      </SlipPageButtons>
    </SwapAutoColumn>
    </>
  )
}
