import { Percent } from '@uniswap/sdk'
import { ALLOWED_PRICE_IMPACT_HIGH, PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN } from '../../constants'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';

/**
 * Given the price impact, get user confirmation.
 *
 * @param priceImpactWithoutFee price impact of the trade without the fee.
 */
export default function confirmPriceImpactWithoutFee(priceImpactWithoutFee: Percent): boolean {
  let t1 = 'This swap has a price impact of at least',
    t2 = 'Please type the word "confirm" to continue with this swap',
    t3 = 'Please confirm that you would like to continue with this swap';
    if(i18next.language=='jap'){
      t1 = 'このスワップは、少なくとも価格への影響があります';
      t2 = 'このスワップを続行するには、"confirm"という単語を入力してください。';
      t3 = 'このスワップを続行することを確認してください';
    }
    if(i18next.language=='zh-CN'){
      t1 = 't1t1t1t1t1t1中文中文';
      t2 = 't2t2t2t2中文中文中文中文';
      t3 = 't3t3t3t3t3t3中文中文中文中文';
    }
  if (!priceImpactWithoutFee.lessThan(PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN)) {
    return (
      window.prompt(
        `${t1} ${PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN.toFixed(0)}%. ${t2}.`
      ) === 'confirm'
    )
  } else if (!priceImpactWithoutFee.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) {
    return window.confirm(
      `${t1} ${ALLOWED_PRICE_IMPACT_HIGH.toFixed(0)}%. ${t3}.`
    )
  }
  return true
}
