import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, TextField, InputAdornment } from '@material-ui/core'

type NumberInputProps = {
  max: string
  symbol: string
  onSelectMax?: () => void
  onChange: (e: any) => void
  placeholder?: string
  value: string
  inputTitle?: string,
  autofill?: boolean,
  disabled?: boolean,
  helpText?: string
}

function NumberInput ({
  max,
  symbol,
  onChange,
  onSelectMax = () => undefined,
  value,
  inputTitle,
  autofill,
  disabled,
  helpText
}: NumberInputProps) {
  const { t } = useTranslation()
  const isBalanceZero = max === '0' || !max

  const displayBalance = (balance: string) => {
    if (isBalanceZero) {
      return '0'
    }
    // const balanceNumber = Number(balance)
    // if (balanceNumber > 0 && balanceNumber < 0.000001) {
    //   return balanceNumber.toLocaleString(undefined, { maximumFractionDigits: 20 })
    // }
    // return balanceNumber.toLocaleString()
    return balance;
  }
  if (autofill) {
    onSelectMax()
  }

  return (
    <div>
      <StyledBalanceText>Balance: {displayBalance(max)} {symbol}</StyledBalanceText>
      <TextField
        autoFocus
        variant="outlined"
        margin="dense"
        label={inputTitle}
        fullWidth
        value={value}
        onChange={onChange}
        placeholder="0"
        helperText={helpText}
        InputProps={{
          startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>,
          endAdornment: <InputAdornment position="end"><Button size="small" onClick={onSelectMax}>{t('Btn.Max')}</Button></InputAdornment>,
        }}
        inputProps={{
          step: "any",
          min: "0",
          inputMode: "decimal",
          pattern: "^[0-9]*[.,]?[0-9]*$"
        }}
      />
    </div>
  )
}

const StyledBalanceText = styled.div`
  text-align: right;
  font-size: 0.8rem;
`

export default NumberInput
