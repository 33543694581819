import {createReducer} from '@reduxjs/toolkit'
import {setAuctionAvaliable} from './actions'

export interface AuctionsState {
  auction_avaliable: boolean
}

const initialState: AuctionsState = {
  auction_avaliable: true
}

export default createReducer<AuctionsState>(initialState, builder =>
  builder
    .addCase(
      setAuctionAvaliable,
      (state, {payload: isAvaliable}) => {
        return {
          auction_avaliable: isAvaliable
        }
      }
    )
)
