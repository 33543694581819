import React from 'react'
import styled, {ThemeContext} from 'styled-components'
import {NavLink, useHistory} from 'react-router-dom'
import ProductTag from 'components/ProductTag'
import {NFTItemType} from 'types'
import {feedbackText, getLanguage, mapToken, rareProduct, shortenAddress} from 'utils'
import {useTranslation} from 'react-i18next'
import StatusTag from 'components/StatusTag'
import {formatBalanceToDisplay} from 'utils/parseBignumber'
import CategoryTag from 'pages/Profile/CategoryTag'

type ItemCard = {
  product: NFTItemType
}

export default function ItemCard ({product}: ItemCard) {
  const {t, i18n} = useTranslation()

  const [language, setLanguage] = React.useState(getLanguage((i18n as any).language))


  const number = Math.random()

  const [realIsNew, setRealIsNew] = React.useState(false)
  // const [realIsNew, setRealIsNew] = React.useState(() => {
  //   return product.currentState == "ListPrice" && number > 0.9
  // })
  // const [realIsRare, setRealIsRare] = React.useState(number < 0.1)
  const [realIsRare, setRealIsRare] = React.useState(() => {
    return rareProduct(product.resource.name, product.tokenId)
  })

  const token = mapToken(product.targetToken)

  return (
    <StyledCardWrapper>
      <StyledCardBody>
        <StyledTagPosition>
          {
            realIsRare && <ProductTag type={"rare"}></ProductTag>
          }
          {
            realIsNew && <ProductTag type={"new"}></ProductTag>
          }
        </StyledTagPosition>
        <StyledCardImgBox>
          <StyledCardImgLink to={linkObject(product)}>
            <StyledCardImg style={{
              backgroundImage: 'url(' + product.resource.image + ')'
            }}>
            </StyledCardImg>
            <StyledCardBackDrop></StyledCardBackDrop>
          </StyledCardImgLink>
        </StyledCardImgBox>
        <StyledTitleBox>
          <StyledTitle to={linkObject(product)}>
            {feedbackText(language, {ja: product.resource.jaName, zh: product.resource.zhName}, product.resource.name)}
          </StyledTitle>
        </StyledTitleBox>
        <StyledStatusBox>
          <StatusTag status={product.currentState}></StatusTag>
          <CategoryTag name={product.resource.category} />
        </StyledStatusBox>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '6px'
        }}>
          <StyledPriceBox>
            <StyledPriceTip>{t("Publish.Price")}</StyledPriceTip>
            <StyledPrice>
              {formatBalanceToDisplay(product.price, token.decimals)}
              {" "}
              <StyledSmallPrice>
                {token.symbol}
              </StyledSmallPrice>
            </StyledPrice>
          </StyledPriceBox>
          <StyledUserLink to={`/profile/onsale/${product.seller}`}>
            <StyledUserImgBox>
              <StyledUserImg style={{
                background: 'url(' + product.avatar + ') rgba(0, 0, 0, .4)',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}></StyledUserImg>
              <StyledAddress>{shortenAddress(product.seller)}</StyledAddress>
            </StyledUserImgBox>
          </StyledUserLink>
        </div>
      </StyledCardBody>
    </StyledCardWrapper >
  )
}

// 根据商品状态决定链接地址
function linkObject (el: NFTItemType) {
  let pathname = '#'

  switch (el.currentState) {
    case 'ListPrice':
      pathname = `/product/${el.NFTContract}/${el.tokenId}`
      break
    case 'ONGOING':
    case 'FINISHED':
    case 'INVALID':
      pathname = `/auction/${el.id}`
      break
    default:
      pathname = `/product/${el.NFTContract}/${el.tokenId}`
      break
  }

  return {
    pathname
  }
}

const imgWidth = '259px'

const StyledCardWrapper = styled.div`
  box-sizing: border-box;
  // min-width: 300px;
  width: 100%;
`

const StyledCardBody = styled.div`
  inset: 0px;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: relative;
  // padding: 18px;
  // border-radius: 16px;
  // border: 1px solid rgba(4, 4, 5, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
`

const StyledCardImgBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  height: 256px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1;
`

const StyledCardImg = styled.div`
  // width: ${imgWidth};
  // height: ${imgWidth};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`
const StyledCardBackDrop = styled.div`
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  transition: all 0.2s ease-in;

  &:hover {
    opacity: 0.1;
  }
`

const StyledCardImgLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledTitleBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
`

const StyledTitle = styled(NavLink)`
  width: 100%;
  text-decoration: none;
  font-size: 20px;
  font-family: inherit;
  font-weight: 900;
  color: #fff;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`


const StyledStatusBox = styled.div`
  margin-top: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`


const StyledPriceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 4px;
`

const StyledPriceTip = styled.div`
  color: rgb(183, 183, 183);
  font-size: 12px;
`

const StyledPrice = styled.div`
  font-size: 17px;
  font-weight: bold;
  white-space: nowrap;
`

const StyledSmallPrice = styled.div`
  font-size: 12px;
  display: inline;
`

const StyledTagPosition = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
`
const StyledUserLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
`
const StyledUserImgBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 4px;
`

const StyledUserImg = styled.div`
  border-radius: 100%;
  width: 16px;
  height: 16px;
  // margin-right: 10px;
`
const StyledAddress = styled.div`
  font-size: 14px;
  line-height: 16.56px;
  font-family: inherit;
  font-weight: inherit;
  color: #999;
  margin-top: 4px;
`
