import {useCallback, useState} from 'react'
import {getContract} from 'utils'
import {useActiveWeb3React} from 'hooks/index'
import {ERC721_SELL_MARKET_TAXED_ADDRESS} from '../constants'
import ERC721_SELL_MARKET_TAXED_ABI from 'constants/abis/erc721-sell-market-taxed.json'
import {withdraw} from 'logicUtils'
import {useTransactionAdder} from 'state/transactions/hooks'
import {TransactionResponse} from '@ethersproject/providers'
import {useIsTransactionPending} from 'state/transactions/hooks'
import {useTranslation} from 'react-i18next'

function useWithdraw () {
  const {t} = useTranslation()
  const {account, library} = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const isPending = useIsTransactionPending(hash ?? '')
  const confirmed = Boolean(hash) && !isPending

  const cb = useCallback(async (orderId: string, tokenId: string) => {
    if (account && library) {
      const contract = getContract(ERC721_SELL_MARKET_TAXED_ADDRESS, ERC721_SELL_MARKET_TAXED_ABI, library, account)
      const signer = library.getSigner(account)
      const tx: TransactionResponse = await withdraw(contract, signer, orderId)
      addTransaction(tx, {
        summary: t('Withdraw #TokenId').replace('TokenId', tokenId)
      })
      setHash(tx.hash)
      return tx
    } else {
      return Promise.reject(t('Missing account'))
    }
  }, [account, library])

  return {withdraw: cb, hash, isPending, confirmed}
}

export default useWithdraw
