import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import {useEffect, useState} from 'react'
import MuiThemeProvider from 'theme/MuiThemeProvider'

type ToastProps = {
  // open: boolean
  // handleClose: () => void
  flag: number
  message?: string
}

export default function Toast ({flag, message}: ToastProps) {

  const [open, setOpen] = useState(false)

  function handleClose () {
    setOpen(false)
  }
  function handleOpen () {
    setOpen(true)
  }

  useEffect(() => {
    if (flag > 0) {
      handleOpen()
    }
  }, [flag])

  return (
    <MuiThemeProvider>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          message={message}
        />
    </MuiThemeProvider>
  )
}
