import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { ROUTER_ADDRESS, MUSD, MULAN, MULANV2, SUPPORT_TOKEN_LIST } from '../constants'
import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@uniswap/sdk'
import { TokenAddressMap } from '../state/lists/hooks'
import BigNumberjs from 'bignumber.js'
import users from 'pages/Publish/users'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  1: '',
  3: 'ropsten.',
  4: 'rinkeby.',
  5: 'goerli.',
  42: 'kovan.'
}

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block'
): string {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]}etherscan.io`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  if (users.includes(address.toLowerCase())) {
    return 'MULAN NFT Official'
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

/**
 * 返回en或者jp，如果是其他语言的话，那就默认返回en
 * @param language
 */
export const getLanguage = function (language: string) {
  if (language.toLocaleLowerCase().indexOf('en') != -1) {
    return 'en'
  }
  if (language.toLocaleLowerCase().indexOf('jp') != -1 || language.toLocaleLowerCase().indexOf('ja') != -1) {
    return 'ja'
  }
  if (language.toLocaleLowerCase().indexOf('zh') != -1) {
    return 'zh'
  }
  return 'en'
}

export function feedbackText (lang: string, langMathText: {[lang: string]: string}, feedbackText: string) {
  let result = langMathText[lang]
  if (!result) {
    result = feedbackText
  }
  if (!result) {
    result = Object.values(langMathText)[0]
  }
  return result
}

export function refreshPageAfter1s () {
  setTimeout(() => {
    window.location.reload(false)
  }, 1000)
}

export function mapToken (address: string): Token {
  if (!address) {
    return null as any
  }
  const r = SUPPORT_TOKEN_LIST.find(token => {
    return token.address.toLowerCase() === address.toLowerCase()
  })
  return (r as Token)
}


export function delay (ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function computeMinimumPrice (basePrice: string, upUnit: string, lastPrice: string): BigNumberjs {
  const bp = new BigNumberjs(basePrice)
  const uu = new BigNumberjs(upUnit)
  const lp = new BigNumberjs(lastPrice)

  let r = bp.times(uu).plus(lp)
  if (lp.eq(0)) {
    r = r.plus(bp)
  }
  return r
}

export function paddingZero (num: number) {
  if (num < 10) {
    return String("0" + num)
  } else {
    return String(num)
  }
}

export function timestampToTime (timestamp: string) {
  let date = new Date(Number(timestamp) * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '/';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
  return Y + M + paddingZero(date.getDate()) + ' ' + paddingZero(date.getHours()) + ':' + paddingZero(date.getMinutes());
}

export function rareProduct (title: string, tokenId: string | number): boolean {
  // A-Z
  const rareMiniCode = 65
  const rareMaxCode = 71
  // 对title进行编码
  const str = decodeURIComponent(title)
  // 取base64
  const base64 = btoa(str)
  // 将tokenid作为偏移量
  let index = title.length + Number(tokenId)
  if (index > base64.length) {
    index = index % (base64.length)
  }
  // 取单个字母的code
  const code = base64.charCodeAt(index)

  // 如果字母code在64-71之间（包含），则返回true
  if (code >= 65 && code <= 71) {
    return true
  }
  return false
}
