import React, {useEffect, useState} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {useTranslation} from 'react-i18next'
import dataImg from './1.png'
import {shortenAddress} from 'utils'
import {NFTItemType} from 'types'
import Skeleton from '@material-ui/lab/Skeleton'
import {index} from 'apis'
import {feedbackText, getLanguage} from 'utils'
import {NavLink, useHistory} from 'react-router-dom'

export default function Recommend () {
  const {t, i18n} = useTranslation()
  const [language, setLanguage] = useState(getLanguage((i18n as any).language))
  const [data, setData] = useState<NFTItemType[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    index(0, 10, 0, 0, 'new_order', '').then(res => {
      setData(res.recommand)
      setLoading(false)
    }, err => {
      console.error(err)
      setLoading(false)
    })
  }, [])

  return (
    <StyledScroll>
      <StyledBox>
        {loading && <SkeletonContent />}
        {data.map((el) => {
          return (
            <StyledItem key={el.id}>
              <StyledTextBoard to={`/product/${el.NFTContract}/${el.tokenId}`}>
                <StyledName>
                  {feedbackText(language, {ja: el.resource.jaName, zh: el.resource.zhName}, el.resource.name)}
                </StyledName>
                <StyledOwner>By {shortenAddress(el.seller)}</StyledOwner>
              </StyledTextBoard>
              <StyledImgBox>
                <StyledImg src={el.resource.image} />
              </StyledImgBox>
            </StyledItem>
          )
        })}
      </StyledBox>
    </StyledScroll>
  )
}

function SkeletonContent () {
  return (
    <>
      {[1, 2, 3, 4].map(el => {
        return (
          <StyledItem key={el}>
            <Skeleton variant="rect" width="100%" height="100%" />
          </StyledItem>
        )
      })}
    </>
  )
}
const StyledScroll = styled.div`
  width: 100%;
  overflow-x: auto;
`
const StyledBox = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem 0;
  list-style: none;
`
const StyledItem = styled.li`
  width: 240px;
  height: 240px;
  margin-right: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
`
const StyledTextBoard = styled(NavLink)`
  display: block;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;
  padding: 1rem;
  transition: all 0.2s;
  text-decoration: none;

  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`
const StyledImgBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 2;
`
const StyledImg = styled.img`
  display: inline-block;
  object-fit: contain;
  width: 100%;
  height: 100%;
`
const StyledName = styled.span`
  display: block;
  font-weight: bold;
  font-size: 18px;
`
const StyledOwner = styled.span`
  display: block;
`
