import React, {useContext, useMemo, useState, useEffect} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {useTranslation} from 'react-i18next'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import pageWrapImg from '../../assets/images/market-bg.png'
import searchIcon from '../../assets/images/magnifying-glass.svg'
import eventimg from '../../assets/images/event-img.png'
import Recommend from './Recommend'
import Auctions from './Auctions'
import ProductionList from './List'
import SectionTitle from 'components/SectionTitle'
import Skeleton from '@material-ui/lab/Skeleton'
import {EventType} from 'types'
import {AppState} from 'state'
import {useSelector} from 'react-redux'

export default function NftMarket () {
  const {t, i18n} = useTranslation()
  const AUCTION_AVALIABLE = useSelector((state: AppState) => state.auctions.auction_avaliable)
  const theme = useContext(ThemeContext)

  // Slider settings
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  }


  // event数据
  const [data, setData] = useState<EventType[]>([])
  const [loadingEvent, setLoadingEvent] = useState(true)

  // 获取event数据
  useEffect(() => {
    fetchData().then((res: EventType[]) => {
      setData(res)
      setLoadingEvent(false)
    }, err => {
      console.error(err)
      setLoadingEvent(false)
    })
  }, [])

  return (
    <>
      <PageWrapper>
        <StyledBgBox>
          <PageWrapperImg src={pageWrapImg} alt="" />
        </StyledBgBox>
        <StyledSection>
          {/* 头部 start */}
          <StyledHeader>
            <SectionTitle title={t('Home.featured')} subTitle={t('Home.featuredCollections')} />
          </StyledHeader>
          {/* 头部 end */}
          {/* 轮播 start */}
          {/* <StyledSliderBox>
            {loadingEvent ? (
              <SkeletonEvent />
            ) : (
              <Slider {...settings}>
                {data.map((el:EventType) => {
                  return (
                    <StyledEventItemBox key={el.title}>
                      <StyledEventItem>
                        <StyledEventImg src={el.image_url} alt={el.title} />
                        <StyledEventTextBox>
                          <StyledEventinfo>Event Information</StyledEventinfo>
                          <StyledSubtitle>{el.subtitle}</StyledSubtitle>
                          <StyledTitle>{el.title}</StyledTitle>
                          <StyledMore>See More</StyledMore>
                        </StyledEventTextBox>
                      </StyledEventItem>
                    </StyledEventItemBox>
                  )
                })}
              </Slider>
            )}
          </StyledSliderBox> */}
          {/* 轮播 end */}
          <Recommend />
          {AUCTION_AVALIABLE && (
            <>
              <StyledHeader style={{marginTop: '4rem'}}>
                <SectionTitle title={t('Home.auctions')} />
              </StyledHeader>
              <Auctions />
            </>
          )}
        </StyledSection>
        <StyledSection>
          <ProductionList />
        </StyledSection>
      </PageWrapper>
    </>
  )
}

function SkeletonEvent () {
  return (
    <StyledEventItemBox>
      <Skeleton variant="rect" width="100%" height="100%" />
    </StyledEventItemBox>
  )
}

function fetchData (): Promise<any> {
  return new Promise((r, j) => {
    setTimeout(() => {
      const data = [
        {
          title: 'event',
          subtitle: 'subtitle',
          image_url: eventimg,
          link: '#'
        },
        {
          title: '2 event 2',
          subtitle: '2 subtitle 2',
          image_url: eventimg,
          link: '#'
        },
        {
          title: '3 event 3',
          subtitle: '3 subtitle 3',
          image_url: eventimg,
          link: '#'
        }
      ]
      r(data)
    }, 1000)
  })
}

const PageWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-top: 130px;
  padding-bottom: 130px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: #212121;
  object-fit: cover;
  @media screen and (max-width: 991px){
    margin-right: 0px;
    padding-left: 0px;
    border-radius: 0px;
  }
  @media screen and (max-width: 767px){
      overflow: hidden;
      min-height: auto;
      margin-right: 0px;
      padding-top: 150px;
      padding-right: 0px;
      padding-bottom: 150px;
  }
  @media screen and (max-width: 479px){
    overflow: hidden;
    padding-right: 0px;
    flex-direction: column;
    justify-content: flex-end;
    object-fit: cover;
  }
`
const PageWrapperImg = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  display: block;
  max-height: 100%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`
const StyledBgBox = styled.div`
  position: fixed;
  inset: 0% 50px;
  z-index: 0;
  display: flex;
  overflow: hidden;
  width: auto;
  min-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 33, 33);
  object-fit: cover;
  border-radius: 40px;

  @media screen and (max-width: 991px){
    inset: 0;
    border-radius: 0;
  }
`
const StyledSection = styled.section`
  background: rgba(0,0,0,0.3);
  padding: 2rem;
  border-radius: 1.5rem;
  margin: 2rem auto;
  max-width: 1600px;
  width: 100%;
  position: relative;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const StyledEventItemBox = styled.div`
  height: 300px;
`
const StyledEventItem = styled.div`
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
`
const StyledEventImg = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 1rem;
`
const StyledEventTextBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 3rem;
`
const StyledEventinfo = styled.span`
  display: block;
  text-align: right;
  padding-top: 1rem;
`
const StyledSubtitle = styled.h4`
  font-size: 1.5rem;
`
const StyledTitle = styled.h3`
  font-size: 2rem;
`
const StyledMore = styled.span`
  position: absolute;
  bottom: 2rem;
  right: 3rem;
  display: block;
  text-align: right;
  text-decoration: underline;
`
const StyledSliderBox = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`
