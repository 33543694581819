import React from 'react'
import styled, {ThemeContext} from 'styled-components'

type SectionTitleProps = {
  title: string
  subTitle?: string
  className?: string
}

export default function SectionTitle ({title, subTitle, className = '', ...props}: SectionTitleProps) {

  return (
    <StyledTitleBox className={className}>
      <StyledH2>{title}</StyledH2>
      <StyledSpan>{subTitle}</StyledSpan>
    </StyledTitleBox>
  )
}


const StyledTitleBox = styled.div`
  display: flex;
  align-items: flex-end;
  line-height: 1;
`

const StyledH2 = styled.h2`
  color: inherit;
  font-size: 1.6rem;
  margin: 0;
  margin-right: 1rem;
`
const StyledSpan = styled.span`
  color: inherit;
  font-size: 1rem;
`
