import React, {useState, useEffect} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {NavLink, useHistory, useParams} from 'react-router-dom'
import ProductTag from 'components/ProductTag'
import {NFTItemType} from 'types'
import {feedbackText, getLanguage} from 'utils'
import {useTranslation} from 'react-i18next'
import CategoryTag from './CategoryTag'
import {formatBalanceToDisplay} from 'utils/parseBignumber'
import {shortenAddress, mapToken, delay, refreshPageAfter1s} from 'utils'
import {ContentTab} from './index'
import {useActiveWeb3React} from 'hooks/index'
import {Button, CircularProgress} from '@material-ui/core'
import useAllowance from 'hooks/useAllowance'
import useApprove from 'hooks/useApprove'
import useWithdraw from 'hooks/useWithdraw'
import notify from 'components/Toast'
import ReCreateModal from 'components/ReCreateModal'
import useModal from 'hooks/useModal'
import {MUSD} from '../../constants'
import AddAuctionModal from 'components/AddAuctionModal'
import StatusTag from 'components/StatusTag'
import {AppState} from 'state'
import {useSelector} from 'react-redux'

type ItemCard = {
  product: NFTItemType
  type: ContentTab
}

export default function ItemCard ({product, type}: ItemCard) {
  const {t, i18n} = useTranslation()
  const {account, chainId, library} = useActiveWeb3React()
  const {user}: {user: string|undefined} = useParams()
  const history = useHistory()
  const [language, setLanguage] = React.useState(getLanguage((i18n as any).language))
  const isSameUser = user === undefined ? true : (account === user)
  const AUCTION_AVALIABLE = useSelector((state: AppState) => state.auctions.auction_avaliable)
  const [open, setOpen] = useState(false)
  const {withdraw, hash, isPending, confirmed} = useWithdraw()
  const [withdrawLoading, setWithDrawLoading] = useState(false)
  const number = Math.random()
  const token = mapToken(product.targetToken)
  // 获取授权状态
  const {nftAllowanceOfMarket, nftAllowanceOfAuction} = useAllowance(token)
  // 授权
  const {nftApproveToMarket, nftApproveToAuction} = useApprove(token)
  const [realIsNew, setRealIsNew] = React.useState(true)
  // const [realIsNew, setRealIsNew] = React.useState(() => {
  //   return product.currentState == "ListPrice" && number > 0.9
  // })
  // const [realIsRare, setRealIsRare] = React.useState(number < 0.1)
  const [realIsRare, setRealIsRare] = React.useState(true)

  const [resellApproveLoading, setResellApproveLoading] = useState(false)
  // 重新上架
  async function resellBtnEvent () {
    const allowanceNFT = await nftAllowanceOfMarket()
    if (allowanceNFT) {
      openReCreteModal()
    } else {
      // 如果未授权，则请求授权
      setResellApproveLoading(true)
      nftApproveToMarket().then(res => {
        if (!res) {
          setResellApproveLoading(false)
          notify(t('unknow error'))
          // 授权失败
        } else {
          // 授权成功后，打开模态框
          openReCreteModal()
          setResellApproveLoading(false)
        }
      }, err => {
        console.error(err)
        setResellApproveLoading(false)
        notify(t('unknow error'))
      })
    }
  }

  const [auctionApproveLoading, setAuctionApproveLoading] = useState(false)
  // 拍卖
  async function auctionBtnEvent () {
    const allowanceNFTAuction = await nftAllowanceOfAuction()
    if (allowanceNFTAuction) {
      openAddAuctionModal()
    } else {
      // 如果未授权，则请求授权
      setAuctionApproveLoading(true)
      nftApproveToAuction().then(res => {
        if (!res) {
          setAuctionApproveLoading(false)
          notify(t('unknow error'))
          // 授权失败
        } else {
          // 授权成功后，打开模态框
          openAddAuctionModal()
          setAuctionApproveLoading(false)
          notify(t('Submit success'))
          // TODO reload
        }
      }, err => {
        console.error(err)
        setAuctionApproveLoading(false)
        notify(t('unknow error'))
      })
    }
  }

  useEffect(() => {
    if (confirmed) {
      // notify(t('Stake.withdraw'))
      refreshPageAfter1s()
    }
  }, [confirmed])

  // 底部按钮
  function btnGroup () {
    if (!isSameUser) {
      return (<div></div>)
    }

    const resellBtn = (
      <Button
        variant="contained"
        size="large"
        color="primary"
        style={{width: '100%', padding: '0.5rem 0'}}
        disabled={resellApproveLoading}
        onClick={resellBtnEvent}
      >
        {t("Btn.Resell")}
        {resellApproveLoading && <CircularProgress size={16} style={{position: 'absolute'}} />}
      </Button>
    )

    const auctionBtn = (
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={auctionBtnEvent}
        disabled={auctionApproveLoading}
        style={{
          width: '100%',
          marginLeft: '1rem',
          padding: '0.5rem 0'
        }}
      >
        {t('Btn.Auction')}
        {auctionApproveLoading && <CircularProgress size={16} style={{position: 'absolute'}} />}
      </Button>
    )

    const acceptBtn = (
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={() => {
          history.push(linkObject(product))
        }}
        style={{
          width: '100%',
          padding: '0.5rem 0'
        }}
      >
        {t('Btn.Accept')}
      </Button>
    )

    const withdrawBtn = (
      <Button variant="contained" size="large" color="primary" disabled={withdrawLoading} style={{
        width: '100%',
        padding: '0.5rem 0'
      }} onClick={async () => {
        setWithDrawLoading(true)
        withdraw(product.id, product.tokenId).catch(err => {
          setWithDrawLoading(false)
        })
        }}>{withdrawLoading && <CircularProgress color="inherit" size={16} />} {t("Btn.Withdraw")}</Button>
    )

    switch (product.currentState) {
      case 'ListPrice':
        return (<StyledBtnBox>{withdrawBtn}</StyledBtnBox>)
        break
      case 'ONGOING':
        return (<StyledBtnBox>{acceptBtn}</StyledBtnBox>)
        break
      case undefined:
        return (<StyledBtnBox>{resellBtn}{AUCTION_AVALIABLE && auctionBtn}</StyledBtnBox>)
        // return (<StyledBtnBox>{resellBtn}</StyledBtnBox>)
        break
      default:
        return (<div></div>)
        break
    }
  }

  // 价格信息
  function priceInfo () {
    if (type === ContentTab.collection) {
      // without price
      return <div></div>
    }

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '6px'
      }}>
        <StyledPriceBox>
          <StyledPriceTip>{t("Publish.Price")}</StyledPriceTip>
          <StyledPrice>
            {formatBalanceToDisplay(product.price, token.decimals)}
            {" "}
            <StyledSmallPrice>
              {token.symbol}
            </StyledSmallPrice>
          </StyledPrice>
        </StyledPriceBox>
        <StyledUserLink to={`/profile/onsale/${product.seller}`}>
          <StyledUserImgBox>
            <StyledUserImg style={{
              background: 'url(' + product.avatar + ') rgba(0, 0, 0, .4)',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}></StyledUserImg>
            <StyledAddress>{shortenAddress(product.seller)}</StyledAddress>
          </StyledUserImgBox>
        </StyledUserLink>
      </div>
    )
  }

  // 模态框
  const [openReCreteModal] = useModal(<ReCreateModal product={product} />)
  const [openAddAuctionModal] = useModal(<AddAuctionModal modalTitle={t('Live auctions')} onConfirm={() => {notify(t('Submit success')); refreshPageAfter1s()}} product={product} />)

  return (
    <StyledCardWrapper>
      <StyledCardBody>
        <StyledCardImgBox>
          <StyledCardImgLink to={linkObject(product)}>
            <StyledCardImg style={{
              backgroundImage: 'url(' + product.resource.image + ')'
            }}>
            </StyledCardImg>
            <StyledCardBackDrop></StyledCardBackDrop>
          </StyledCardImgLink>
        </StyledCardImgBox>
        <StyledTitleBox>
          <StyledTitle to={linkObject(product)}>
            {feedbackText(language, {ja: product.resource.jaName, zh: product.resource.zhName}, product.resource.name)}
          </StyledTitle>
        </StyledTitleBox>
        <StyledStatusBox>
          <StatusTag status={product.currentState}></StatusTag>
          <CategoryTag name={product.resource.category} />
        </StyledStatusBox>
        {priceInfo()}
        {btnGroup()}
      </StyledCardBody>
    </StyledCardWrapper >
  )
}

// 根据商品状态决定链接地址
function linkObject (el: NFTItemType) {
  let pathname = '#'

  switch (el.currentState) {
    case 'ListPrice':
      pathname = `/product/${el.NFTContract}/${el.tokenId}`
      break
    case 'ONGOING':
    case 'FINISHED':
    case 'INVALID':
      pathname = `/auction/${el.id}`
      break
    default:
      pathname = `/product/${el.NFTContract}/${el.tokenId}`
      break
  }

  return {
    pathname
  }
}

const imgWidth = '259px'

const StyledCardWrapper = styled.div`
  box-sizing: border-box;
  // min-width: 300px;
  width: 100%;
`

const StyledCardBody = styled.div`
  inset: 0px;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: relative;
  // padding: 18px;
  // border-radius: 16px;
  // border: 1px solid rgba(4, 4, 5, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
`

const StyledCardImgBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  height: 256px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1;
`

const StyledCardImg = styled.div`
  // width: ${imgWidth};
  // height: ${imgWidth};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`
const StyledCardBackDrop = styled.div`
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  transition: all 0.2s ease-in;

  &:hover {
    opacity: 0.2;
  }
`

const StyledCardImgLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledTitleBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
`

const StyledTitle = styled(NavLink)`
  width: 100%;
  text-decoration: none;
  font-size: 20px;
  font-family: inherit;
  font-weight: 900;
  color: #fff;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`


const StyledStatusBox = styled.div`
  margin-top: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`


const StyledPriceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 4px;
`

const StyledPriceTip = styled.div`
  color: rgb(183, 183, 183);
  font-size: 12px;
`

const StyledPrice = styled.div`
  font-size: 17px;
  font-weight: bold;
  white-space: nowrap;
`

const StyledSmallPrice = styled.div`
  font-size: 12px;
  display: inline;
`

const StyledTagPosition = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
`
const StyledUserLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
`
const StyledUserImgBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 4px;
`

const StyledUserImg = styled.div`
  border-radius: 100%;
  width: 16px;
  height: 16px;
  // margin-right: 10px;
`
const StyledAddress = styled.div`
  font-size: 14px;
  line-height: 16.56px;
  font-family: inherit;
  font-weight: inherit;
  color: #999;
  margin-top: 4px;
`
const ButtonOutline = styled.button`
  display: flex;
  margin-right: 5px;
  margin-left: 0px;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: #305cfa;
  transition: all 200ms ease;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
  border: none;
  box-shadow: none;
  cursor: pointer;
  :hover {
    transform: translate(0px, -10px);
  }
`
const StyledBtnBox = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
`
