import React, { useState, useCallback } from 'react'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { TokenAmount } from '@uniswap/sdk'
import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useMulanStakeContract, useMulanContract } from '../../hooks/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'

import { useDerivedStakeInfo } from '../../state/stake/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { MULAN_STAKE_ADDRESS, MULAN } from '../../constants/index'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { usePlanData, Plan } from '../../state/mulanstake/hooks'
import { useTranslation } from 'react-i18next'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  userLiquidityUnstaked: TokenAmount | undefined
  selectId: string | undefined
}

export default function StakingModal({ isOpen, onDismiss, userLiquidityUnstaked, selectId }: StakingModalProps) {
  const { account, library } = useActiveWeb3React()
  const { t } = useTranslation()
  // track and parse user input
  const addTransaction = useTransactionAdder()
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedStakeInfo(typedValue, MULAN, userLiquidityUnstaked)
  const plan: Plan | undefined = usePlanData(selectId)
  // console.log('selectIdxxxx', selectId, userLiquidityUnstaked)
  // state for pending and submitted txn views
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  // console.log('stakingInfo.tokens[0]', stakingInfo.tokens[0], stakingInfo.tokens[1])

  const mulanContract = useMulanContract()
  // approval data for stake
  const deadline = useTransactionDeadline()

  // check with stake
  const [approval, approveCallback] = useApproveCallback(parsedAmount, MULAN_STAKE_ADDRESS)
  const isArgentWallet = useIsArgentWallet()
  const stakingContract = useMulanStakeContract()
  async function onStake() {
    setAttempting(true)
    if (stakingContract && mulanContract && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        await stakingContract
          .deposit(selectId, `0x${parsedAmount.raw.toString(16)}`, { gasLimit: 350000 })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: 'Transfer ' + parsedAmount.currency.symbol
            })
            setHash(response.hash)
          })
          .catch((error: Error) => {
            console.debug('Failed to approve token', error)
            throw error
          })
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  // console.log('userLiquidityUnstaked', userLiquidityUnstaked)
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  async function onAttemptToApprove() {
    if (!mulanContract || !account || !library) throw new Error('missing dependencies')
    if (isArgentWallet) {
      return approveCallback()
    }
    return approveCallback()
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>{t('Stake.stake' + selectId).replace('TT', plan?.apy)}</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={MULAN}
            // pair={dummyPair}
            label={' '}
            disableCurrencySelect={true}
            customBalanceText={t('Stake.aviable')}
            id="stake-liquidity-token"
          />

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={approval === ApprovalState.APPROVED}
              disabled={approval !== ApprovalState.NOT_APPROVED}
            >
              {t('Stake.approve')}
            </ButtonConfirmed>
            <ButtonError
              disabled={!!error || approval !== ApprovalState.APPROVED}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? t('Stake.stake')}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[approval === ApprovalState.APPROVED]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>{t('Stake.depositing')}</TYPE.largeHeader>
            <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} MULAN</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>{t('Stake.success')}</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} MULAN</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
