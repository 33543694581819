import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import Banner from './Banner'
import img1 from 'assets/images/about-part-1.jpg'
import img2 from 'assets/images/about-part-2.jpg'
import img3 from 'assets/images/about-part-3.jpg'
import img4 from 'assets/images/about-part-4.jpg'
import img5 from 'assets/images/about-part-5.jpg'
import img6 from 'assets/images/about-part-6.jpg'
import img7 from 'assets/images/about-part-7.jpg'
import eg1 from 'assets/images/about-eg-1.jpg'
import eg2 from 'assets/images/about-eg-2.jpg'


const Home = () => {
    const {t} = useTranslation()
    return (
        <>
            <Banner />
            <SecondHalf id="learnmore">
                <BoxHalf>
                    <TextLeft>
                        <Header>{t('About.h1')}</Header>
                        <Text>{t('About.d1-1')}</Text>
                        <Text>{t('About.d1-2')}</Text>
                        <Text>{t('About.d1-3')}</Text>
                        <Text>{t('About.d1-4')}</Text>
                        <Text>{t('About.d1-5')}</Text>
                        <Text>{t('About.d1-6')}</Text>
                        <Text>{t('About.d1-7')}</Text>
                        <Text>{t('About.d1-8')}</Text>
                    </TextLeft>
                </BoxHalf>
                <BoxHalf2>
                    <Images src={img1} alt="" />
                </BoxHalf2>
            </SecondHalf>

            <SecondHalf id="learnmore" className="oposite">
                <BoxHalf2>
                    <Images src={img2} alt="" />
                </BoxHalf2>
                <BoxHalf>
                    <TextLeft>
                        <Header>{t('About.h2')}</Header>
                        <Text>{t('About.d2-1')}</Text>
                        <Text>{t('About.d2-2')}</Text>
                        <Text>{t('About.d2-3')}</Text>
                        <Text>{t('About.d2-4')}</Text>
                        <Text>{t('About.d2-5')}</Text>
                    </TextLeft>
                </BoxHalf>
            </SecondHalf>

            <SecondHalf id="learnmore">
                <BoxHalf>
                    <TextLeft>
                        <Header>{t('About.h3')}</Header>
                        <Text>{t('About.d3-1')}</Text>
                        <Text>{t('About.d3-2')}</Text>
                        <Text>{t('About.d3-3')}</Text>
                        <Text>{t('About.d3-4')}</Text>
                    </TextLeft>
                </BoxHalf>
                <BoxHalf2>
                    <Images src={img3} alt="" />
                </BoxHalf2>
            </SecondHalf>

            <SecondHalf id="learnmore" className="oposite">
                <BoxHalf2>
                    <Images src={img4} alt="" />
                </BoxHalf2>
                <BoxHalf>
                    <TextLeft>
                        <Header>{t('About.h4')}</Header>
                        <Text>{t('About.d4-1')}</Text>
                        <Text>{t('About.d4-2')}</Text>
                        <Text>{t('About.d4-3')}</Text>
                        <Text>{t('About.d4-4')}</Text>
                        <Text>{t('About.d4-5')}</Text>
                        <Text color="#fff">
                            <b>{t('About.d4-6')}</b><br/>
                            {t('About.d4-7')}
                        </Text>
                        <Text color="#fff">
                            <b>{t('About.d4-8')}</b><br />
                            {t('About.d4-9')}
                        </Text>
                        <Text color="#fff">
                            <b>{t('About.d4-10')}</b><br />
                            {t('About.d4-11')}
                        </Text>
                    </TextLeft>
                </BoxHalf>
            </SecondHalf>

            <SecondHalf id="learnmore">
                <BoxHalf>
                    <TextLeft>
                        <Header>{t('About.h5')}</Header>
                        <Text>{t('About.d5-1')}</Text>
                        <Text>{t('About.d5-2')}</Text>
                        <Text color="#fff">
                        {t('About.d5-3')}<br/>
                        {t('About.d5-4')}<br/>
                        {t('About.d5-5')}
                        </Text>
                        <Text>{t('About.d5-6')}</Text>
                    </TextLeft>
                </BoxHalf>
                <BoxHalf2>
                    <Images src={img5} alt="" />
                </BoxHalf2>
            </SecondHalf>

            <HeroSection id="hero">
                <StyledRContainer>
                    <GeneralH1 color="#fff">{t('About.r')}</GeneralH1>
                    <StyledRItemBox>
                        <StyledRItem>
                            <Header2>{t('About.r1')}</Header2>
                            <Text>{t('About.r1-1')}</Text>
                            <Text>{t('About.r1-2')}</Text>
                            <Text>{t('About.r1-3')}</Text>
                        </StyledRItem>
                        <StyledRItem>
                            <Header2>{t('About.r2')}</Header2>
                            <Text>{t('About.r2-1')}</Text>
                            <Text>{t('About.r2-2')}</Text>
                            <Text>{t('About.r2-3')}</Text>
                            <Text>{t('About.r2-4')}</Text>
                        </StyledRItem>
                        <StyledRItem>
                            <Header2>{t('About.r3')}</Header2>
                            <Text>{t('About.r3-1')}</Text>
                            <Text>{t('About.r3-2')}</Text>
                            <Text>{t('About.r3-3')}</Text>
                            <Text>{t('About.r3-4')}</Text>
                            <Text>{t('About.r3-5')}</Text>
                            <Text>{t('About.r3-6')}</Text>
                        </StyledRItem>
                    </StyledRItemBox>
                </StyledRContainer>
            </HeroSection>

            <HeroSection id="hero">
                <StyledEgContainer>
                    <GeneralH1 color="#fff">{t('About.eg')}</GeneralH1>
                    <StyledEgItem>
                        <SecondHalf>
                            <BoxHalf>
                                <TextLeft>
                                    <Header2 color="#0099f9">{t('About.eg.h1')}</Header2>
                                    <Text>{t('About.eg.d1-1')}</Text>
                                    <Text>{t('About.eg.d1-2')}</Text>
                                    <Text>{t('About.eg.d1-3')}</Text>
                                    <Text>{t('About.eg.d1-4')}</Text>
                                </TextLeft>
                            </BoxHalf>
                            <BoxHalf2>
                                <Images src={eg1} alt="" />
                            </BoxHalf2>
                        </SecondHalf>
                    </StyledEgItem>
                    <StyledEgItem>
                        <SecondHalf className="oposite">
                            <BoxHalf2>
                                <Images src={eg2} alt="" />
                            </BoxHalf2>
                            <BoxHalf>
                                <TextLeft>
                                    <Header2 color="#0099f9">{t('About.eg.h2')}</Header2>
                                    <Text>{t('About.eg.d2-1')}</Text>
                                    <Text>{t('About.eg.d2-2')}</Text>
                                    <Text>{t('About.eg.d2-3')}</Text>
                                </TextLeft>
                            </BoxHalf>

                        </SecondHalf>
                    </StyledEgItem>
                </StyledEgContainer>
            </HeroSection>

        </>
    )
}


const SecondHalf = styled.div`
position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-height: 45vw;
    margin-bottom: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: transparent;
    @media screen and (max-width: 991px){
        min-height: auto;
        padding-right: 10px;
        padding-left: 10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
`
const BoxHalf = styled.div`
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 49.5vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -o-object-fit: cover;
    object-fit: cover;
    @media screen and (max-width: 991px){
    width: 100%;
    height: 80vw;
}
    @media screen and (max-width: 479px){
    width: 100%;
    height: auto;
    min-height: 100vw;
    margin-bottom: 10px;
}
`
const BoxHalf2 = styled.div`
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 49.5vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 42px;
    @media screen and (max-width: 991px){
      width: 100%;
      height: 80vw;
  }
    @media screen and (max-width: 479px){
      width: 100%;
      height: 100vw;
      min-height: 100vw;
      margin-bottom: 10px;
  }
`
const TextLeft = styled.div`
padding: 50px;
@media screen and (max-width: 479px){
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}
`
const Header = styled.h1`
position: relative;
z-index: 1;
margin-top: 0px;
margin-bottom: 0px;
font-family: acumin-pro-extra-condensed, sans-serif;
color: #fff;
font-size: 36px;
line-height: 50px;
font-weight: 900;
@media screen and (max-width: 479px){
    color: #fff;
    font-size: 30px;
    line-height: 40px;
}
`
const Header2 = styled.h2`
position: relative;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: 40px;
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    color: ${props => (props.color ? props.color : '#fff')};
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;
`
const Text = styled.p`
position: relative;
z-index: 1;
margin-top: 20px;
color: ${props => {
    return props.color ? props.color : '#969595'
}};
font-size: 13px;
line-height: 30px;
font-weight: 400;
@media screen and (max-width: 479px){
    color: hsla(0, 0%, 100%, 0.63);
    line-height: 30px;
    letter-spacing: 1.1px;
}
`
const Images = styled.img`
width: 100%;
height: 100%;
`

const HeroSection = styled.div`
position: relative;
z-index: 0;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
overflow: hidden;
width: 100%;
min-height: 100vh;
padding-top: 0px;
padding-bottom: 0px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
background-color: #212121;
-o-object-fit: cover;
object-fit: cover;
margin-bottom: 30px;
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
border-radius: 42px;
`
const HeroImg = styled.img`
position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    display: block;
    max-height: 100%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -o-object-fit: cover;
    object-fit: cover;
    opacity:1;
`
const BoxContainer = styled.div`
position: absolute;
    left: 0%;
    top: 30%;
    right: 0%;
    bottom: 30%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    backdrop-filter: blur(20px);
`
const GeneralH1 = styled.h1`
position: relative;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-size: 36px;
    line-height: 50px;
    font-weight: 900;
    color: ${props => (props.color ? props.color : '#0099f9')};
    text-align: center;
    text-transform: uppercase;
`

const StyledRContainer = styled.div`
    background-image: url(${img6});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 0;
`
const StyledRItemBox = styled.div`
    display: flex;
    ${({theme}) => theme.mediaWidth.upToMedium`
        flex-direction: column;
  `};
`
const StyledRItem = styled.div`
    background: rgba(0, 0, 0, 0.4);
    padding: 2rem;
    border-radius: 2rem;
    margin: 2rem;
`
const StyledEgContainer = styled.div`
    background-image: url(${img7});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem;
        background-attachment: fixed;
`
const StyledEgItem = styled.div`
    border-radius: 2rem;
    overflow: hidden;
    margin: 1rem 0;
    padding: 1rem 0;
    background-color: rgba(0, 0, 0, 0.4);

${SecondHalf} {
    min-height: auto;
    align-items: center;
}

${BoxHalf} {
    width: auto;
}
${BoxHalf2} {
    width: 30%;
    flex-shrink: 0;
    margin: 0 2rem;
    min-height: auto !important;
    height: auto !important;

    @media screen and (max-width: 991px){
      width: 100%;
  }
    @media screen and (max-width: 479px){
      width: 100%;
  }
}
`

export default Home
