import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, currencyEquals, ETHER, TokenAmount, WETH } from '@uniswap/sdk'
import React, { useCallback, useContext,useRef,useState } from 'react'
import { Plus } from 'react-feather'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { BlueCard, LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFlat } from '../../components/Row'
import { ROUTER_ADDRESS } from '../../constants'
import { PairState } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency, useInputCurrency , useOutputCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'

import { useTransactionAdder } from '../../state/transactions/hooks'

import { TYPE } from '../../theme'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { currencyId } from '../../utils/currencyId'
import { PoolPriceBar } from './PoolPriceBar'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { useTranslation } from 'react-i18next'
import swapBackground from '../../assets/images/Liquidity-background.jpg'
import Pro_img from 'assets/svg/shell.svg'
import circle1 from "assets/svg/circle-green.svg"
import downloadImg from "assets/img/audit-by-certik.svg"
import circleImg from '../../assets/svg/circle-animation.svg'
import blueMulanImg from '../../assets/svg/blue-mulan.svg'
import auditIcon from '../../assets/images/Certik-icon.svg'
import walletCircle from 'assets/svg/shell.svg'
import walletIcon from 'assets/svg/circle-green.svg'
import swich_icn from 'assets/svg/exchange-icon.svg'
import Dfplogo from 'components/Dfplogo'
//import Settings from '../../components/Settings'

// liqidity settings

import { Settings, X } from 'react-feather'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen } from '../../state/application/hooks'
import { useUserTransactionTTL } from '../../state/user/hooks'
import {useIsExpertMode, useExpertModeManager, useUserSlippageTolerance, useUserSingleHopOnly } from '../../state/user/hooks'
 import Modal from '../../components/Alerts'
import QuestionHelper from '../../components/QuestionHelper'
import Toggle from '../../components/Toggle'
import TransactionSettings from '../../components/TransactionSettings'
import settingImg from '../../assets/svg/Setting-icon.svg'
import popupicn from '../../assets/svg/close_icon.svg'

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  // 0x7dFB72A2AAd08C937706f21421B15bFC34Cba9ca/0xdAC17F958D2ee523a2206206994597C13D831ec7
  const currencyA = useInputCurrency(currencyIdA)
  const currencyB = useOutputCurrency(currencyIdB)

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
        (currencyB && currencyEquals(currencyB, WETH[chainId])))
  )

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  //const expertMode = useExpertModeManager()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
      }
    },
    {}
  )

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ROUTER_ADDRESS)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ROUTER_ADDRESS)

  const addTransaction = useTransactionAdder()

  async function onAdd() {
    if (!chainId || !library || !account) return
    const router = getRouterContract(chainId, library, account)

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0]
    }

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsETH = currencyB === ETHER
      estimate = router.estimateGas.addLiquidityETH
      method = router.addLiquidityETH
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline.toHexString()
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
    } else {
      estimate = router.estimateGas.addLiquidity
      method = router.addLiquidity
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString()
      ]
      value = null
    }

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Add ' +
              parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
              ' ' +
              currencies[Field.CURRENCY_A]?.symbol +
              ' and ' +
              parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
              ' ' +
              currencies[Field.CURRENCY_B]?.symbol
          })

          setTxHash(response.hash)

          ReactGA.event({
            category: 'Liquidity',
            action: 'Add',
            label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/')
          })
        })
      )
      .catch(error => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

   // settings code :

    // settings code :

const node = useRef<HTMLDivElement>()
const open = useModalOpen(ApplicationModal.SETTINGS)
const toggle = useToggleSettingsMenu()

//const theme = useContext(ThemeContext)
const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

const [ttl, setTtl] = useUserTransactionTTL()

//const [toggleExpertMode] = useExpertModeManager()

 // for expert mode
 //const [expertMode] = useExpertModeManager()

 // for expert mode
 //const toggleSettings = useToggleSettingsMenu()
 const [expertMode, toggleExpertMode ] = useExpertModeManager()

const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()

// show confirmation view before turning on
const [showConfirmation, setShowConfirmation] = useState(false)

useOnClickOutside(node, open ? toggle : undefined)

 // const { t } = useTranslation()



  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
              {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <Text fontSize="31px" fontWeight={500} lineHeight="42px" marginRight={10}>
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <Text fontSize="24px">
            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol + t('Pool Tokens')}
          </Text>
        </Row>
        <TYPE.italic fontSize={12} textAlign="left" padding={'8px 0 0 0 '}>
          {t('Output is estimated. If the price changes by more than')} {allowedSlippage /
            100}% {t('your transaction will revert')}
        </TYPE.italic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = `${t('Supplying')} ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } ${t('and')} ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA ? currencyIdA : 'ETH'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const isCreate = history.location.pathname.includes('/create')

  const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  return (
    <SwapFull id="swap-full">
    <SwapFull2 id="swapnew">
    <AppBodySwap id="swapnew3">

    <StyledSwapHeader>
        <AddRemoveTabs creating={isCreate} adding={true} />
            {/* <Settings /> */}

    <StyledMenuSwap ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper id="heyyyaa">
          <>
            <HeaderExpert>
              <AlertHeader>
              <AlertText>
                {t('Are you sure?')}
              </AlertText>
              {/* <StyledCloseIcon  /> */}

              <PopupCloseIcon onClick={() => setShowConfirmation(false)}>
                <PopupCloseImg src={popupicn} alt="" />
              </PopupCloseIcon>
              </AlertHeader>
            </HeaderExpert>
            {/* <Break /> */}
            <AlertBody>
              <AlertText>
                {t('Expert')}
              </AlertText>
              <AlertText>
                {t('ONLY')}
              </AlertText>
            </AlertBody>
            <AlertFooter>
            <ButtonError
                error={true}
                onClick={() => {
                  if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === 'confirm') {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}>
                <div id="confirm-expert-mode">
                  {t('turn')}
                </div>
              </ButtonError>
            </AlertFooter>
          </>
        </ModalContentWrapper>
      </Modal>
      <StyledMenuButtonSwap onClick={toggle} id="open-settings-dialog-button">
        {/* <StyledMenuIcon /> */}
        <SettingPera className="settings-text">Settings</SettingPera>
        <SettingImg src={settingImg} loading="lazy" alt="" className="settings-img"></SettingImg>
        {expertMode ? (
          <>
            {/* <span role="img" aria-label="wizard-icon">
              🧙
            </span> */}
          </>
        ) : null}
      </StyledMenuButtonSwap>

    </StyledMenuSwap>

            {/* end settings code */}

            {noLiquidity ||
              (isCreate ? (

                    <>
                      <TipText>
                        {t('firstLiquidity')}
                      </TipText>
                      <TipText>
                        {t('The ratio')}
                      </TipText>
                      <TipText>
                       {t('supply to review')}
                      </TipText>
                    </>

              ) : (
                    <TipText>
                      Tip: {t('When you add liquidity')}
                    </TipText>
              ))}

        <SwapMulanContainer className="img-container-mulan">
            <CircleContainer className="circle-container">
              <CircleButtn src={circleImg} loading="lazy" alt="" className="circle-button" />
              <BlueMulanImg src={blueMulanImg} loading="lazy" alt="" className="img-absolute-mulan-blue" />
              <CircleButtn2 src={circleImg} loading="lazy" alt="" className="circle-button-inside" />
            </CircleContainer>
        </SwapMulanContainer>
        </StyledSwapHeader>

        <SwapWrapper id="swap-page">
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            content={() => (
              <ConfirmationModalContent
                title={noLiquidity ? t('You are creating a pool') : t('You will receive')}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
          />
          <SwapAutoColumn>

            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              showCommonBases
            />
            <ExchangeIcon>
              {/* <Plus size="16" color={theme.text2} /> */}
              <Switcher src={swich_icn} alt="" />
            </ExchangeIcon>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              showCommonBases
            />
            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <PriceHeader>
                <PriceHead>
                      {noLiquidity ? t('Initial prices') : t('Prices')} {t('and pool share')}
                </PriceHead>
                {' '}
                <PriceBody>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                </PriceBody>
              </PriceHeader>

            )}

        <ButtonGroupSwap id="btngroup">
          <ButtonRowList>

            {addIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
              </ButtonPrimary>
            ) : !account ? (
            <ConnectWallet>
              <WalletIcon src={walletCircle} loading="lazy" alt="" className="nav-link-icon" />
              <WalletIconCircle src={walletIcon} loading="lazy" alt="" className="circle-button-connect" />
              <TextConnect onClick={toggleWalletModal}>{t('Connect Wallet')}</TextConnect>
            </ConnectWallet>

            ) : (
              <AutoColumn gap={'sm'} >
                {(approvalA === ApprovalState.NOT_APPROVED ||
                  approvalA === ApprovalState.PENDING ||
                  approvalB === ApprovalState.NOT_APPROVED ||
                  approvalB === ApprovalState.PENDING) &&
                  isValid ? (
                    <RowBetween>
                      {approvalA !== ApprovalState.APPROVED && (
                        <ButtonPrimary
                          onClick={approveACallback}
                          disabled={approvalA === ApprovalState.PENDING}
                          width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
                        >
                          {approvalA === ApprovalState.PENDING ? (
                            <Dots>Approving {currencies[Field.CURRENCY_A]?.symbol}</Dots>
                          ) : (
                              currencies[Field.CURRENCY_A]?.symbol + t('Approve')
                          )}
                        </ButtonPrimary>
                      )}
                      {approvalB !== ApprovalState.APPROVED && (
                        <ButtonPrimary
                          onClick={approveBCallback}
                          disabled={approvalB === ApprovalState.PENDING}
                          width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
                        >
                          {approvalB === ApprovalState.PENDING ? (
                            <Dots>Approving {currencies[Field.CURRENCY_B]?.symbol}</Dots>
                          ) : (
                              currencies[Field.CURRENCY_B]?.symbol + t('Approve')
                          )}
                        </ButtonPrimary>
                      )}
                    </RowBetween>
                  ) : (
                    <ButtonError id="jnoemooh"
                  onClick={() => {
                    expertMode ? onAdd() : setShowConfirm(true)
                  }}
                  disabled={!isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
                  error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {error ?? t('Supply')}
                  </Text>
                </ButtonError>
                  )}

              </AutoColumn>
            )}
              <AuditImg src={auditIcon} alt=""></AuditImg>

              </ButtonRowList>
              <SwapFooterText>Secure Connection audit by certik.org</SwapFooterText>
              </ButtonGroupSwap>
          </SwapAutoColumn>
          </SwapWrapper>
      </AppBodySwap>

      {open && (
      <MenuFlyout id="swapnew4">
        {/* <AutoColumn gap="md" style={{ padding: '1rem' }}> */}

        <StyledSwapHeader>
            <SwapHead>{t('Transaction')}</SwapHead>
        </StyledSwapHeader>

          <TransactionSettings
            rawSlippage={userSlippageTolerance}
            setRawSlippage={setUserslippageTolerance}
            deadline={ttl}
            setDeadline={setTtl}
          />

          <SwapAutoColumnInterface id="new2">
            <SlipTitle>
            {t('Interface')}
            </SlipTitle>

            <InterfaceCombine>
            <InterfaceH1>
            {t('Toggle')}
            </InterfaceH1>
            {/* <QuestionHelper text={t('Bypasses')} /> */}
            <Toggle
              id="toggle-expert-mode-button"
              isActive={expertMode}
              toggle={
                expertMode
                  ? () => {
                      toggleExpertMode()
                      setShowConfirmation(false)
                    }
                  : () => {
                      toggle()
                      setShowConfirmation(true)
                    }
              }
            />
            </InterfaceCombine>
            <InterfaceCombine>
            <InterfaceH1>
            {t('Disable')}
            </InterfaceH1>
            {/* <QuestionHelper text={t('Restricts')} /> */}
            <Toggle
              id="toggle-disable-multihop-button"
              isActive={singleHopOnly}
              toggle={() => (singleHopOnly ? setSingleHopOnly(false) : setSingleHopOnly(true))}
            />

            </InterfaceCombine>

          </SwapAutoColumnInterface>

        {/* </AutoColumn> */}
      </MenuFlyout>
    )}
  </SwapFull2>
      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
            <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
          </AutoColumn>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter
          show={addIsUnsupported}
          currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
        />
      )}

    <SwapBg src={swapBackground} alt=""></SwapBg>
      <Certik>
        <Protection>
          <Protectionimg src={Pro_img} loading="lazy" alt="" />
          <CircleButtonInside src={circle1} loading="lazy" alt="" className="circle-button-inside"/>
          <CircleButtonInside2 src={circle1} loading="lazy" alt="" className="circle-button"/>
        </Protection>
        <Dfplogo style={{display: 'inline-flex', justifyContent: 'flex-end'}} />
        <DownloadLink style={{display: 'inline-flex', justifyContent: 'flex-end'}} href="https://www.certik.org/projects/mulanfinance" target="_blank">
          <DownloadImg src={downloadImg} loading="lazy" alt="" />
        </DownloadLink>
      </Certik>
    </SwapFull>


  )
}


const SwapFull = styled.div`
    position: relative;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    padding-top: 130px;
    padding-bottom: 130px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 40px;
    background-color: #212121;
    -o-object-fit: cover;
    object-fit: cover;
    @media screen and (max-width: 991px){
    margin-right: 10px;
    padding-left: 10px;
    border-radius: 0px;
}
@media screen and (max-width: 767px){
    overflow: hidden;
    min-height: auto;
    margin-right: 0px;
    padding-top: 150px;
    padding-right: 10px;
    padding-bottom: 150px;
}
@media screen and (max-width: 479px){
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -o-object-fit: cover;
    object-fit: cover;
}
  `
const SwapFull2 = styled.div`
  position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    // max-width: 900px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.34);
    text-align: left;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    @media screen and (max-width: 991px){
      width: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }
@media screen and (max-width: 767px){
    width: 100%;
    min-height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
@media screen and (max-width: 479px){
    width: 100%;
    margin-top: 0vh;
    margin-bottom: auto;
    padding: 0px;
}
    `
const AppBodySwap = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 500px;
    height: auto;
    min-height: 100%;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 30px;
    padding-bottom: 130px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: rgba(0, 0, 0, 0.3);
    @media screen and (max-width: 767px){
      width: 100%;
    }
    `
const SwapBg = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    display: block;
    max-height: 100%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -o-object-fit: cover;
    object-fit: cover;
    opacity: 1;
    @media screen and (max-width: 991px){
      -webkit-filter: blur(20px);
      filter: blur(20px);
    }
    `
const Certik = styled.div`
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    text-align: right;
    @media screen and (max-width: 479px){
    right: 20px;
    bottom: 50px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;}
`
const Protection = styled.div`
    position: absolute;
    top: -20px;
    right: -10px;
    z-index: 3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective: 0px;
    perspective: 0px;
`
const Protectionimg = styled.img`
  width: 20px;
  -webkit-perspective: 0px;
  perspective: 0px;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);

`
const CircleButtonInside = styled.img`
  position: absolute;
  width: 120%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 100%;
}
`
const CircleButtonInside2 = styled.img`
  position: absolute;
  width: 140%;
  max-width: none;
  @media screen and (max-width: 479px){
    position: absolute;
    width: 120%;
    max-width: none;
}
`
const DownloadLink = styled.a`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 50px;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  @media screen and (max-width: 479px){
    height: 42px;
}
`
const DownloadImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
  max-width: none;
`
const StyledSwapHeader = styled.div`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
width: 100%;
height: auto;
padding: 30px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-align: start;
-webkit-align-items: flex-start;
-ms-flex-align: start;
align-items: flex-start;
@media screen and (max-width: 479px){
    padding-right: 20px;
    padding-left: 20px;
}
`
const SwapHead = styled.p`
margin-bottom: 5px;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    margin-top: 0px;
    `

const TipText = styled.p`
margin-bottom: 0px;
    color: #fff;
    font-size: 13px;
    line-height: 14px;
    margin-top: 0px;
    @media screen and (max-width: 479px){
    font-size: 12px;
    line-height: 12px;
}
`
const SwapMulanContainer = styled.div`
position: absolute;
left: 0px;
top: -35px;
right: 0px;
z-index: 2;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
overflow: visible;
width: 70px;
height: 70px;
margin-right: auto;
margin-left: auto;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
border-radius: 100px;
background-color: rgba(0, 0, 0, 0.3);
-o-object-fit: cover;
object-fit: cover;
-webkit-backdrop-filter: blur(20px);
backdrop-filter: blur(20px);
`
const CircleContainer = styled.div`
position: relative;
z-index: 2;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
width: 100%;
height: 100%;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
`
const CircleButtn = styled.img`
position: absolute;
z-index: 3;
width: 140%;
max-width: none;
@media screen and (max-width: 479px){
    position: absolute;
    width: 120%;
    max-width: none;
}
`
const CircleButtn2 = styled.img`
position: absolute;
width: 120%;
max-width: none;
@media screen and (max-width: 479px){
    position: absolute;
    width: 100%;
}
`
const BlueMulanImg = styled.img`
position: absolute;
left: 50%;
top: 50%;
z-index: 0;
display: block;
max-height: 50%;
max-width: none;
min-height: 50%;
min-width: 50%;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
-o-object-fit: cover;
object-fit: cover;
`
const SwapWrapper = styled.div`
    width:100%;
    `
const SwapAutoColumn = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 479px){
      padding-right: 20px;
      padding-left: 20px;
    }
    `
const ConnectWallet = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    margin-right: 0px;
    margin-left: 5px;
    padding-right: 20px;
    padding-left: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50px;
    background-color: #305cfa;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    text-decoration: none;
    cursor: pointer;
    :hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
  }
    `
const WalletIcon = styled.img`
    position: absolute;
    margin-right: 0px;
    opacity: 1;
    left: 11px;
    width: 20px;
`
const WalletIconCircle = styled.img`
position: absolute;
left: -2px;
width: 45px;
max-width: none;
`
const ButtonGroupSwap = styled.div`
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
const ButtonRowList = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @media screen and (max-width: 479px){
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
    `
const SwapFooterText = styled.p`
    color: hsla(0, 0%, 100%, 0.41);
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    @media screen and (max-width: 479px){
    display: none;
}
`
const TextConnect = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 45px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    font-size: 13px;
    line-height: 13px;
    text-decoration: none;
`
const AuditImg = styled.img`
    width: 100px;
    opacity: 0.41;
    @media screen and (max-width: 479px){
    display: none;
}
    `
const ExchangeIcon = styled.a`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    height: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
const Switcher = styled.img`
    width: 30%;
    `
const StyledMenuSwap = styled.div`
    `
const ModalContentWrapper = styled.div`
position: relative;
    overflow: hidden;
    width: 400px;
    height: auto;
    padding-bottom: 51px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.39);
`
const HeaderExpert = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding: 15px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
`
const AlertHeader = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    `
const AlertBody = styled.div`
    position: relative;
    z-index: 0;
    display: block;
    overflow: auto;
    height: auto;
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -o-object-fit: fill;
    object-fit: fill;
    `
const AlertText = styled.p`
    color: #fff;
    margin-top: 0;
    margin-bottom: 15px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    `
const AlertFooter = styled.div`
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 6vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    `
const PopupCloseIcon = styled.div`
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 0.5px;
    border-color: hsla(0, 0%, 96.1%, 0.12);
    border-radius: 35px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;
    `
const PopupCloseImg = styled.img`
      border-radius: 35px;
    `
const StyledMenuButtonSwap = styled.div`
    position: absolute;
    top: 30px;
    right: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    background:transparent;
    `

const SettingPera = styled.p`
    margin-right: 10px;
    margin-bottom: 0px;
    color: #fff;
    line-height: 14px;
    margin-top: 0px;
    font-size: 14px;
    `

const SettingImg = styled.img`
    width: 25px;
    cursor: pointer;
    `
const MenuFlyout = styled.div`
position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 500px;
    height: auto;
    min-height: 100%;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 30px;
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    @media screen and (max-width: 767px){
      width:100%;
    }
    @media screen and (max-width: 479px){
      width:100%;
    }
`
const SwapAutoColumnInterface = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 95%;
    margin-bottom: 10px;
    padding: 5px 30px 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.17);
`
const SlipTitle = styled.p`
position: relative;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
    color: #fff;
    font-size: 13px;
    line-height: 30px;
    font-weight: 400;
`
const InterfaceH1 = styled.h1`
margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
`
const InterfaceCombine = styled.div`
display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    padding-top: 5px;
    padding-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
`
const PriceHead = styled.p`
color: #fff;
line-height: 14px;
margin: 20px 0px;
font-size: 14px;
`
const PriceHeader = styled.div`
width: 100%;
`
const PriceBody = styled.div`
width: 100%;
border-radius: 20px;
background-color: rgba(0,0,0,0.45);
padding: 15px 0px;
`
const StyledDepLogo = styled.img`
  height: 90%;
  margin-right: 10px;
  display: block;
`
