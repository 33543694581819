import React from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {Card, CardContent, Checkbox, CircularProgress, createStyles, Fade, FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, Modal, Select, Theme, Button} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import notify from 'components/Toast'
import {useTranslation, Trans, Translation} from 'react-i18next'
import {NFTItemType} from 'types'
import {delay, mapToken, refreshPageAfter1s} from 'utils'
import {SUPPORT_TOKEN_LIST} from '../../constants'
import {formatToMiniUnit} from 'utils/parseBignumber'
import useAddOrder from 'hooks/useAddOrder'
import BigNumber from 'bignumber.js'
import {useEffect} from 'react';

interface ReCreateProps {
  product: NFTItemType,
  onConfirm?: () => void,
  onDismiss?: () => void
}

const ReCreate: React.FC<ReCreateProps> = ({
  product,
  onConfirm = () => undefined,
  onDismiss = () => undefined
}) => {
  let {t, i18n} = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [price, setPrice] = React.useState('')
  const {addOrder, hash, isPending, confirmed} = useAddOrder()
  const [selectToken, setSelectToken] = React.useState(SUPPORT_TOKEN_LIST[0].address);

  const token = mapToken(selectToken)
  // 执行submit的逻辑
  async function handleSubmit () {
    console.log('price', price)
    // console.log('selectToken', selectToken)
    console.log('nftcontract', product.NFTContract)
    console.log('tokenId', product.tokenId)
    if (new BigNumber(price).lt(0)) {
      notify(t('Publish.ConfirmYourInput'))
      return
    }

    setLoading(true)

    const total = formatToMiniUnit(price, token.decimals)
    await addOrder(product.NFTContract, product.tokenId, total, token.address)
  }
  function handleSelect (e: any) {
    setSelectToken(e.target.value);
  }
  useEffect(() => {
    if (confirmed) {
      notify(t('Submit success'))
      onConfirm()
      onDismiss()
      refreshPageAfter1s()
    }
  }, [confirmed])
  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={true}
      onClose={onDismiss}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{t("Publish.ConfirmYourInput")}</DialogTitle>
      <DialogContent style={{
        minWidth: '300px'
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <DialogContentText>{t("Publish.UploadedImage")}:</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DialogContentText>
              <div style={{
                display: product.resource.image ? 'block' : 'none'
              }}>
                <img id="publish_preview_img" src={product.resource.image} style={{width: '250px', height: 'auto'}} />
              </div>
            </DialogContentText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DialogContentText>{t("Publish.Category")}:</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DialogContentText>{product.resource.category}</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DialogContentText>{t("Publish.Title")}:</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DialogContentText>{product.resource.name}</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DialogContentText>{t("Publish.JATitle")}:</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DialogContentText>{product.resource.jaName}</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DialogContentText>{t("Publish.ZHTitle")}:</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DialogContentText>{product.resource.zhName}</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DialogContentText>{t("Publish.Description")}:</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DialogContentText><div style={{
              whiteSpace: 'pre-wrap'
            }}>{product.resource.description}</div></DialogContentText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DialogContentText>{t("Publish.JADescription")}:</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DialogContentText><div style={{
              whiteSpace: 'pre-wrap'
            }}>{product.resource.jaDescription}</div></DialogContentText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DialogContentText>{t("Publish.ZHDescription")}:</DialogContentText>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DialogContentText><div style={{
              whiteSpace: 'pre-wrap'
            }}>{product.resource.zhDescription}</div></DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <TextField required margin="dense" name="price" type="number" label={t("Publish.Price")} variant="outlined" style={{width: 100, marginRight: 20}} onChange={(e: any) => {
                // e.target.value
                setPrice(e.target.value)
              }} />
              <FormControl style={{
                width: 100
              }}>
                <InputLabel id="select-outlined-label" style={{
                  width: 100
                }}>{t("Publish.Token")}</InputLabel>
                <Select value={selectToken} labelId="select-outlined-label" name="token" onChange={handleSelect}>
                  {SUPPORT_TOKEN_LIST.map((token) =>
                    <MenuItem key={token.address} value={token.address}>{token.symbol}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="primary" size="large" >
          {t("Publish.Close")}
        </Button>
        <div style={{position: 'relative'}}>
          <Button onClick={async () => {
            setLoading(true)
            await handleSubmit().catch(err => {
              console.error(err)
              notify(err)
              setLoading(false)
            })
          }} color="primary" variant="contained" size="large" disabled={loading}>
            {loading && <CircularProgress size={24} />}{t("Publish.Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ReCreate
