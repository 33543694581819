import React, {useState, useEffect} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {NavLink, useHistory, useParams} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Item from './Item'
import {useActiveWeb3React} from 'hooks/index'
import {NFTItemType} from 'types'
import Skeleton from '@material-ui/lab/Skeleton'
import {ContentTab} from './index'
import {meOnSale, meCollectibles, userAuctions} from 'apis'
import {Button, CircularProgress} from '@material-ui/core'

type ListProps = {
  type: ContentTab
}

let isFirstRender = true
const defaultPageParams = {
  offset: 0,
  limit: 20
}

export default function List ({type}: ListProps) {
  const {account, chainId, library} = useActiveWeb3React()
  const {user}: {user: string | undefined} = useParams()
  const {t, i18n} = useTranslation()

  const [list, setList] = useState<NFTItemType[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [isNoMore, setIsNoMore] = useState(false)

  const [pageParams, setPageParams] = useState(defaultPageParams)

  useEffect(() => {
    async function fetchDataA (queryAddress: string) {
      if (pageParams.offset === 0) {
        // 如果offset === 0，则视为加载初始数据
        setLoading(true)
      } else {
        // 如果offset > 0，则视为加载更多数据
        setLoadingMore(true)
      }

      const result = await meOnSale(queryAddress, pageParams.offset, pageParams.limit)

      if (pageParams.offset === 0) {
        // 如果offset === 0，则视为加载初始数据
        // 直接覆盖
        setList(result)
        setLoading(false)
      } else {
        // 如果offset > 0，则视为加载更多数据
        // 追加
        setList(oldList => oldList.concat(result))
        setLoadingMore(false)
      }

      if (result.length === 0) {
        setIsNoMore(true)
      } else {
        setIsNoMore(false)
      }
    }

    function fetchDataB (queryAddress: string) {
      setLoading(true)
      meCollectibles(queryAddress).then(res => {
        let array = []
        for (let pro in res) {
          for (let i = 0; i < res[pro].length; i++) {
            array.push(res[pro][i])
          }
        }
        array.sort((a: any, b: any) => {
          return b.tokenId - a.tokenId
        })
        setList(array)
        setLoading(false)
      })
    }

    function fetchDataC (queryAddress: string) {
      setLoading(true)
      userAuctions(queryAddress).then(res => {
        setList(res)
        setLoading(false)
      })
    }

    const address = user || account
    if (address) {
      switch (type) {
        case ContentTab.sale:
          fetchDataA(address)
          break
        case ContentTab.collection:
          fetchDataB(address)
          break
        case ContentTab.auction:
          fetchDataC(address)
          break
      }
    }
  }, [pageParams])

  // reset pageParams
  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false
    } else {
      setPageParams({...defaultPageParams})
    }
  }, [type, account, user])

  // 加载更多数据事件
  function loadingMoreEvent () {
    const offset = pageParams.offset + pageParams.limit
    setPageParams({
      offset, limit: pageParams.limit
    })
  }

  return (
    <div>
      <Grid container spacing={3}>
        {loading && <SkeletonContent />}
        {!loading && list.map(el => {
          return (
            <Grid item xs={12} sm={6} lg={3} key={el.tokenId}>
              <Item type={type} product={(el as any)} />
            </Grid>
          )
        })}
        {!loading && list.length === 0 &&
          <Grid item xs={12}>
          <StyledLoadMore><span>{t('Loading.nodata')}</span></StyledLoadMore>
          </Grid>
        }
      </Grid>

      {/* loadMore Btn start */}
      {
        ContentTab.sale === type &&
        <StyledLoadMore style={{display: (loading || isNoMore) ? 'none' : 'block'}}>
          <Button
            disabled={loadingMore}
            size="large"
            color="primary"
            onClick={loadingMoreEvent}
          >
            {loadingMore && <CircularProgress size={24} />} MORE
          </Button>
        </StyledLoadMore>
      }
      {/* loadMore Btn end */}
    </div>
  )
}

function SkeletonContent () {
  return (
    <>
      {[1, 2, 3, 4].map(el => {
        return (
          <Grid item xs={12} sm={6} lg={3} key={el}>
            <Skeleton variant="rect" width="100%" height="300px" />
          </Grid>
        )
      })}
    </>
  )
}

const StyledLoadMore = styled.div`
  padding: 1rem;
  text-align: center;
`
