import React, { useEffect, useState } from 'react'
import {NavLink} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button, TextField, CircularProgress, Typography, ListItemText, ListItemAvatar, Avatar, ListItem } from '@material-ui/core'
import {NFTItemType, bidsResponse} from 'types'
import {auctionAcceptAPI } from 'apis'
import { mapToken, shortenAddress, timestampToTime, delay } from 'utils'
import { acceptOffer721 } from 'logicUtils/index'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import notify from 'components/Toast'
import {useAuctionContract} from 'hooks/useContract'
import {useActiveWeb3React} from 'hooks/index'
import {formatToMiniUnit, formatBalanceToDisplay, multipliedAndToBigNumber} from 'utils/parseBignumber'
import useAcceptAuction from 'hooks/useAcceptAuction'

type AcceptModalProps = {
  modalTitle: string
  onConfirm: () => void
  onDismiss?: () => void
  item: NFTItemType
  bidList: bidsResponse[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '36ch',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
    font: {
      color: '#040405',
      fontSize: '14px',
      fontWeight: 900,
      width: '100%'
    },
    secondFont: {
      color: 'rgba(4, 4, 5, 0.5)',
      fontSize: '14px',
      fontWeight: 500,
      marginRight: '4px'
    },
  }),
)

function AcceptModal ({ bidList, item, modalTitle, onDismiss = () => undefined, onConfirm }: AcceptModalProps) {
  const {account, chainId, library} = useActiveWeb3React()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const {acceptAuction, hash, isPending, confirmed} = useAcceptAuction()
  const [fetchConfirm, setFetchConfirm] = useState(false)

  const [lastBid, setLastBid] = useState<bidsResponse>(bidList[0])

  const token = mapToken(lastBid.targetToken)

  useEffect(() => {
    if (bidList[0]) {
      setLastBid(bidList[0])
    }
  }, [bidList])

  async function acceptAction () {
    if (account) {
      await acceptAuction(item.id, item.NFTContract, item.tokenId, lastBid.bider, lastBid.targetToken, lastBid.price, Math.floor(Number(item.expiration) / 1000), lastBid.signature)
      // await auctionAcceptAPI(item.id, lastBid.bider)
    } else {
      Promise.reject(t('Missing account'))
    }
  }

  useEffect(() => {
    async function fetchData () {
      await delay(2000)
      await auctionAcceptAPI(item.id, lastBid.bider)
      setFetchConfirm(true)
    }
    // 等待链上结果返回后，再调用后端接口
    if (confirmed) {
      fetchData().catch(err => {
        console.error(err)
        notify(err)
      })
    }
  }, [confirmed])

  useEffect(() => {
    if (fetchConfirm) {
      onConfirm()
      onDismiss()
    }
  }, [fetchConfirm])

  return (
    <Dialog open={true} fullWidth={true} onClose={onDismiss} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
      {
        lastBid ? (
          <div>
            <DialogContent style={{ padding: '2rem' }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={lastBid.bider} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <span>{t('Product.bidPlaced')} </span>
                      <span style={{fontWeight: 900}}>{formatBalanceToDisplay(lastBid.price, token.decimals)} {token.symbol}</span>
                    </>
                  }
                  secondary={
                    <span>
                      <span style={{
                        color: '#999',
                        marginRight: '6px'
                      }}>by</span>
                      <NavLink to={'/profile/onsale/' + lastBid.bider}>{shortenAddress(lastBid.bider)}</NavLink>
                      <span> at {timestampToTime(lastBid.timeStamp)}</span>
                    </span>
                  }
                />
              </ListItem>
            </DialogContent>
            <DialogActions>
              <Button onClick={onDismiss} disabled={isLoading && (!confirmed || !fetchConfirm)}>
                {t('Btn.Cancel')}
              </Button>
              <Button
                disabled={isLoading && (!confirmed || !fetchConfirm)}
                variant="contained"
                onClick={async () => {
                  setIsLoading(true)
                  await acceptAction().catch(err => {
                    console.error(err)
                    notify(err)
                    setIsLoading(false)
                    return Promise.reject(err)
                  })
                }}
                color="primary"
              >
                {isLoading && (!confirmed || !fetchConfirm) && <CircularProgress color="inherit" size={16} />}{t('Btn.Accept')}
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div>
              <DialogContent style={{ padding: '2rem' }}>
                {t('Not any bids yet')}
              </DialogContent>
              <DialogActions>
                <Button onClick={onDismiss}>
                  {t('Btn.Cancel')}
                </Button>
              </DialogActions>
          </div>
        )
      }
    </Dialog>
  )
}


export default AcceptModal
