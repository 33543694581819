import {bidsResponse, UserDepositsAPIType, UserBidsType, MediumPublications} from 'types'
import BigNumber from 'bignumber.js'

const DOMAIN = 'https://mulandefi.finance'
// const DOMAIN = 'https://nfttest.dpay.fi'
// const DOMAIN = 'http://devhost:5000'

type UploadNft = {
  data: any
}

async function fetchData (url: string) {
  let response = await fetch(url)
  let data = await response.json()
  return data
}

export async function getData (uri: string) {
  return await fetchData(uri)
}

export async function uploadNft (params: {[key: string]: string | File}): Promise<UploadNft> {
  const api = `${DOMAIN}/api/upload`

  const formdata = new FormData()
  Object.keys(params).forEach(key => {
    formdata.append(key, params[key])
  })

  const response = await fetch(api, {
    method: 'post',
    body: formdata
  })
  const result = await response.json()
  return result
}

const excludeIds = ["8", "9"]
export const index = async (offset: number, limit: number, onsale: 1 | 0, star: 1 | 0, order: "price_asc" | "price_desc" | "new_order", q: string) => {
  const api = `${DOMAIN}/api/index?offset=${offset}&limit=${limit}&onsale=${onsale}&star=${star}&order=${order}&q=${q}`
  let result = await fetchData(api)
  const orders = result.orders.filter((el: any) => {
    return !excludeIds.includes(el.id)
  })
  const recommand = result.recommand && result.recommand.filter((el: any) => {
    return !excludeIds.includes(el.id)
  }).sort((a: any, b: any) => {
    return b.tokenId - a.tokenId
  })
  return {
    orders,
    recommand,
    banner: result.banner,
  }
}

export const detail = async (contract: string, tokenId: string) => {
  const api = `${DOMAIN}/api/nft/${contract}/${tokenId}`
  let result = await fetchData(api)
  result.sort((a: any, b: any) => {
    return Number(b.id) - Number(a.id)
  })

  return result[0]
}

export const meOnSale = async (account: string, offset: number, limit: number) => {
  const api = `${DOMAIN}/api/my/${account}/onsale?offset=${offset}&limit=${limit}`
  let result = await fetchData(api);
  result.sort((a: any, b: any) => {
    return b.tokenId - a.tokenId
  })
  return result;
}

export const meCollectibles = async (account: string) => {
  const api = `${DOMAIN}/api/my/${account}/collectibles`
  let result = await fetchData(api);

  // 请求auction的数据，将collection中，处于auction状态的过滤掉
  const list = await userAuctions(account)
  const auctions = list.filter((el: any) => {
    return el.currentState === 'ONGOING'
  })
  // XXX 数据量大后，再考虑使用service workers
  auctions.forEach((el: any) => {
    const ls = result[el.NFTContract]
    const newls = ls.filter((l: any) => {
      return l.tokenId !== el.tokenId
    })

    result[el.NFTContract] = newls
  })

  return result;
}

export async function userAuctions (account: string) {
  const api = `${DOMAIN}/api/auction/userauction?seller=${account}`
  let result = await fetchData(api)
  if (!Array.isArray(result)) {
    result = []
  }
  result.forEach((el: any) => {
    el.currentState = el.status
  })
  result.sort((a: any, b: any) => {
    return b.id - a.id
  })
  return result
}

export async function createAuctionAPI (params: FormData) {
  const api = `${DOMAIN}/api/auction/createauction`
  const response = await fetch(api, {
    method: 'post',
    body: params
  })
  const result = await response.json()
  return result
}

export async function bidsAPI (auctionId: string | number): Promise<bidsResponse[]> {
  const api = `${DOMAIN}/api/auction/bids/${auctionId}`
  const result = await fetchData(api)
  return result
}

export async function auctionAcceptAPI (auctionId: string | number, bider: string) {
  const api = `${DOMAIN}/api/auction/accepted?auctionId=${auctionId}&bider=${bider}`
  const result = await fetchData(api)
  return result
}

export async function createbidAPI (params: FormData): Promise<string[]> {
  const api = `${DOMAIN}/api/auction/createbid`
  const response = await fetch(api, {
    method: 'post',
    body: params
  })
  const result = await response.json()
  return result
}

export async function auctionAPI (auctionAPI: string) {
  const api = `${DOMAIN}/api/auction/id/${auctionAPI}`
  const result = await fetchData(api)
  result.currentState = result.status
  return result
}

export async function depositandBidAPI (bider: string, token: string): Promise<string[]> {
  const api = `${DOMAIN}/api/auction/depositandbid?bider=${bider}&token=${token}`
  const result = await fetchData(api)
  return result
}

export async function userDepositsAllAPI (account: string, tokenList: {key: string, name: string}[]): Promise<UserDepositsAPIType[]> {
  const fetchQueue: Promise<string[]>[] = []
  tokenList.forEach(t => {
    const res = depositandBidAPI(account, t.key)
    fetchQueue.push(res)
  })
  const resutl = await Promise.all(fetchQueue)
  return resutl.map((el, i): UserDepositsAPIType => {
    const usable = new BigNumber(el[0]).minus(new BigNumber(el[1]))
    return {
      owner: account,
      targetToken: tokenList[i].key,
      amount: el[0],
      used: el[1],
      usable: usable.toString()
    }
  })
}

export async function auctionAll () {
  const api = `${DOMAIN}/api/auction/all`
  const list = await fetchData(api)
  if (list && list.length) {
    // 只取出 'ONGOING' 的部分
    const result = list.filter((el: any) => {
      return el.status === 'ONGOING'
    })
    result.forEach((el: any) => {
      el.currentState = el.status
    })
    result.sort((a: any, b: any) => {
      return b.id - a.id
    })
    return result
  }
  return []
}

export async function userBids (account: string): Promise<UserBidsType[]> {
  const api = `${DOMAIN}/api/auction/userbids?bider=${account.toLowerCase()}`
  const result = await fetchData(api)
  result.reverse().forEach((el: UserBidsType) => {
    el.timeStamp = String(new Date(el.timeStamp).getTime()).substring(0, 10)
  })
  return result
}

export async function getMediumList (): Promise<MediumPublications[]> {
  const api = `${DOMAIN}/api/news`

  const response = await fetchData(api)

  let result: Array<MediumPublications> = []

  try {
    result = response[0].data.userResult.homepagePostsConnection.posts.map((el: any) => {
      return {
        id: el.id,
        title: el.title,
        subtitle: el.previewContent.subtitle,
        firstPublishedAt: el.firstPublishedAt,
        mediumUrl: el.mediumUrl
      }
    })
  } catch (e) {}

  return result
}
