import {useCallback, useState} from 'react'
import {useActiveWeb3React} from 'hooks/index'
import {buyFromOrder} from 'logicUtils'
import {NFTItemType} from 'types'
import BigNumber from 'bignumber.js'
import {useMarketContract} from 'hooks/useContract'
import {useTokenBalance} from 'state/wallet/hooks'
import {useTransactionAdder} from 'state/transactions/hooks'
import {useTranslation} from 'react-i18next'
import {TransactionResponse} from '@ethersproject/providers'
import {useIsTransactionPending} from 'state/transactions/hooks'
import {Token} from '@uniswap/sdk'


function useBuyFromOrder (token: Token) {
  const contract = useMarketContract()
  const {account, library} = useActiveWeb3React()
  const tokenAmount = useTokenBalance(account as string, token)
  const addTransaction = useTransactionAdder()
  const {t} = useTranslation()
  const [hash, setHash] = useState<string | undefined>()
  const isPending = useIsTransactionPending(hash ?? '')
  const confirmed = Boolean(hash) && !isPending

  const cb = useCallback(async (item: NFTItemType) => {
    if (account && library && contract && tokenAmount) {
      const bnPrice = new BigNumber(item.price)
      const bnBalance = new BigNumber(tokenAmount.toSignificant()).multipliedBy(new BigNumber(10).pow(tokenAmount.token.decimals))

      // 余额不足
      if (bnBalance.lt(bnPrice)) {
        return Promise.reject(t('Balance not enough'))
      }
      const signer = library.getSigner(account)
      const response: TransactionResponse = await buyFromOrder(contract, signer, item.id)
      addTransaction(response, {
        summary: t('Buy #TokenId').replace('TokenId', item.tokenId)
      })
      setHash(response.hash)
      return response
    } else {
      return Promise.reject(t('Missing account'))
    }
  }, [account, library, contract, tokenAmount])

  return {buyAction: cb, hash, isPending, confirmed}
}

export default useBuyFromOrder
