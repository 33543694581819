import React, {useContext, useMemo, useState, useCallback} from 'react'
import styled, {ThemeContext} from 'styled-components'
import {useTranslation} from 'react-i18next'
import pageWrapImg from '../../assets/images/market-bg.png'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import List from './List'
import {useActiveWeb3React} from 'hooks/index'
import {NavLink, useParams, useHistory} from 'react-router-dom'
import {shortenAddress, timestampToTime, mapToken} from 'utils'
import SectionTitle from 'components/SectionTitle'
import UnstakeModal from 'components/UnstakeModal'
import StakeModal from 'components/StakeModal'
import useModal from 'hooks/useModal'
import {formatToMiniUnit, formatBalanceToDisplay, multipliedAndToBigNumber} from 'utils/parseBignumber'
import {Button, TextField, CircularProgress} from '@material-ui/core'
import {UserBidsType} from 'types'
import {userBids} from 'apis'
import {useEffect} from 'react'

export default function Profile () {
  const {t, i18n} = useTranslation()
  const theme = useContext(ThemeContext)
  const {account} = useActiveWeb3React()
  const history = useHistory()
  const [data, setData] = useState<UserBidsType[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setData([])
    if (account) {
      userBids(account).then(res => {
        setData(res)
        setLoading(false)
      }, err => {
        console.error(err)
        setLoading(false)
      })
    }
  }, [account])

  const [openStake] = useModal(<StakeModal />)
  const [openUnStake] = useModal(<UnstakeModal />)

  return (
    <>
      <PageWrapper>
        <StyledBgBox>
          <PageWrapperImg src={pageWrapImg} alt="" />
        </StyledBgBox>
        <StyledSection>
          <StyledHeader>
            <SectionTitle title={t('My Bids')} />
            <div style={{padding: '1rem 0'}}>
              <Button
                color="primary"
                variant="contained"
                onClick={openStake}
              >
                {t('Btn.Stake')}
              </Button>
              <Button
                style={{marginLeft: '1rem'}}
                color="primary"
                variant="contained"
                onClick={openUnStake}
              >
                {t('Btn.Unstake')}
              </Button>
            </div>
          </StyledHeader>
          <div>
            <StyledList>
              {!loading && data.map(el => {
                const token = mapToken(el.targetToken)
                return (
                  <StyledItem key={el.timeStamp}>
                    <StyledBidPrice>
                      <span style={{marginRight: '0.25rem'}}>
                        <span>{t('Product.bidPlaced')} </span>
                        <StyledPrice>{formatBalanceToDisplay(el.price, token.decimals)} {token.symbol} </StyledPrice>
                      </span>
                      <StyledTime>
                        <StyledAt> at </StyledAt>
                        <span>{timestampToTime(el.timeStamp)}</span>
                      </StyledTime>
                    </StyledBidPrice>
                    <div>
                      <NavLink style={{color: '#999'}} to={`/auction/${el.auctionId}`}>
                        <StyledToken>#{el.auction.tokenId}</StyledToken>
                        <span style={{wordWrap: 'break-word'}}>{el.auction.NFTContract}</span>
                      </NavLink>
                    </div>
                  </StyledItem>
                )
              })}
            </StyledList>
            <StyledMore>
              {!loading && (data.length === 0) && <span>{t('Loading.nodata')}</span>}
              {loading && <CircularProgress color="primary" size={24} />}
            </StyledMore>
          </div>
        </StyledSection>
      </PageWrapper>
    </>
  )
}


const PageWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-top: 130px;
  padding-bottom: 130px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 40px;
  background-color: #212121;
  -o-object-fit: cover;
  object-fit: cover;
  @media screen and (max-width: 991px){
      margin-right: 0px;
      padding-left: 0px;
      border-radius: 0px;
  }
  @media screen and (max-width: 767px){
      overflow: hidden;
      min-height: auto;
      margin-right: 0px;
      padding-top: 150px;
      padding-right: 0px;
      padding-bottom: 150px;
  }
  @media screen and (max-width: 479px){
  overflow: hidden;
  padding-right: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -o-object-fit: cover;
  object-fit: cover;
}
`
const PageWrapperImg = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
  display: block;
  max-height: 100%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -o-object-fit: cover;
  object-fit: cover;
`
const StyledBgBox = styled.div`
  position: fixed;
  inset: 0% 50px;
  z-index: 0;
  display: flex;
  overflow: hidden;
  width: auto;
  min-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 33, 33);
  object-fit: cover;
  border-radius: 40px;

  @media screen and (max-width: 991px){
    inset: 0;
    border-radius: 0;
  }
`
const StyledSection = styled.section`
  background: rgba(0,0,0,0.3);
  padding: 2rem;
  border-radius: 1.5rem;
  margin: 2rem auto;
  max-width: 900px;
  width: 100%;
  position: relative;
`
const StyledHeader = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({theme}) => theme.mediaWidth.upToSmall`margin: 1rem;flex-direction: column;`};

  .pc {
    ${({theme}) => theme.mediaWidth.upToSmall`display: none;`};
  }
  .mobile {
    display: none;
    ${({theme}) => theme.mediaWidth.upToSmall`display: block;`};
  }
`
const StyledList = styled.ul`
  padding-left: 1rem;
`
const StyledItem = styled.li`
  padding: 0.5rem 0;
`
const StyledToken = styled.span`
  font-weight: bold;
  margin-right: 1rem;
`
const StyledPrice = styled.span`
  font-weight: bold;
`
const StyledAt = styled.span`
  font-size: 0.875rem;
`
const StyledTime = styled.span`
  color: #999;
`
const StyledMore = styled.div`
  padding: 1rem;
  text-align: center;
`
const StyledBidPrice = styled.div`
  display: flex;
  flex-wrap: wrap;
`
